import { createApp } from "vue"
import FunTipsDialog from "./signUp.vue"
import vue3GoogleLogin from "vue3-google-login"

export default function SignUp(options) {
  // 创建一个节点，并将组件挂载上去
  const mountNode = document.createElement("div")
  document.body.appendChild(mountNode)
  const app = createApp(FunTipsDialog, {
    ...options,
    visible: true,
    remove() {
      app.unmount(mountNode) //创建完后要进行销毁
      document.body.removeChild(mountNode)
    },
  })
  app.use(vue3GoogleLogin, {
    clientId: "1069642802272-0ktgtg6hn72ljht0bltd9h14qseflsvj.apps.googleusercontent.com",
    scope: "profile email",
  })
  return app.mount(mountNode)
}
