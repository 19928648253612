/*
 * @author: lihaifa
 * @LastEditTime: 2024-03-14 11:57:48
 * @Description: 工具类方法
 * @FilePath: /src/utils/tools.js
 */
import fingerprintjs from "@fingerprintjs/fingerprintjs";

import Copy from "./CopyClass";
// import * as clipboard from "clipboard-polyfill";
import useClipboard from "vue-clipboard3";

let visitorId = null;
export const getDeviceId = () => {
  return new Promise((resolve, reject) => {
    if (visitorId != null) {
      resolve(visitorId);
    }
    try {
      fingerprintjs
        .load()
        .then((fp) => fp.get())
        .then((result) => {
          // This is the visitor identifier:
          visitorId = result.visitorId;
          resolve(visitorId);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getUrlAllParams = (url) => {
  /*获取全部url参数,并转换成json对象*/
  url = url || decodeURIComponent(window.location.href);
  let pa = url.substring(url.indexOf("?") + 1),
    arrS = pa.split("&"),
    rs = {};
  for (let i = 0, len = arrS.length; i < len; i++) {
    let pos = arrS[i].indexOf("=");
    if (pos == -1) {
      continue;
    }
    let name = arrS[i].substring(0, pos),
      value = window.decodeURIComponent(arrS[i].substring(pos + 1));
    rs[name] = value;
  }
  return rs;
};

export const objToQueryUrlString = (obj) => {
  /*@description   对象序列化(对象转成url参数) @param  {Object} obj  @return {String}*/
  if (!obj) return "";
  let pairs = [];
  for (let key in obj) {
    let value = obj[key];
    if (value instanceof Array) {
      for (let i = 0; i < value.length; ++i) {
        pairs.push(
          encodeURIComponent(key + "[" + i + "]") +
            "=" +
            encodeURIComponent(value[i])
        );
      }
      continue;
    }
    pairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
  }
  return pairs.join("&");
};

export const formatNumber = (n) => {
  if (n >= 1000 && n < 10000) {
    return (n / 1000).toFixed(1) + "k";
  } else if (n >= 10000) {
    return (n / 10000).toFixed(1) + "w";
  } else {
    return n.toString();
  }
};

export const envType = import.meta.env.VITE_NODE_ENV;

export const isDev = () => {
  return envType === "development";
};

export const isProd = () => {
  return envType === "production";
};

export const getBasicDataType = (data) => typeof data; // 获取基础数据类型 Number、String、Boolean、Null、undefined、object、symbol、bigInt

export const getType = (data) =>
  Object.prototype.toString.call(data).slice(8, -1); // 获取精确数据类型

export const getUuid = () => {
  return "10000000100040008000100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const saveAs = ({ href, fileName, blob }) => {
  return new Promise((resolve, reject) => {
    try {
      if (href || blob) {
        const aLink = document.createElement("a");
        // aLink.setAttribute("target", "_blank");
        aLink.style.display = "none"; // 隐藏链接
        // aLink.setAttribute("target", "_blank");
        if (fileName) {
          aLink.setAttribute(
            "download",
            fileName
            // new TextDecoder().decode(
            //   Uint8Array.from(fileName, (char) => char.charCodeAt(0))
            // )
          );
        }
        aLink.href = href || URL.createObjectURL(blob);
        document.body.appendChild(aLink);
        setTimeout(() => {
          if ("dispatchEvent" in HTMLAnchorElement.prototype) {
            aLink.dispatchEvent(new MouseEvent("click"));
          } else {
            aLink.click();
          }
          setTimeout(() => {
            resolve();
          }, 1000);
          setTimeout(() => {
            URL.revokeObjectURL(aLink.href); // 释放对象URL
            document.body.removeChild(aLink);
          }, 60 * 1000 * 5);
        });
        // if (isMobileDevice()) {
        //   reject();
        // }
      } else {
        reject("href或blob为空");
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const downloadHttp = (url, fileName) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response], {
          type: this.response?.type || "text/plain",
        });
        saveAs({ blob, fileName }).then(resolve).catch(reject);
      } else {
        reject(this.statusText);
      }
    };
    xhr.onerror = function () {
      reject("下载失败");
    };
    xhr.send();
  });
};

export const downLoadAtag = ({ href, fileName, blob, useAlink = false }) => {
  return new Promise((resolve, reject) => {
    if (useAlink) {
      saveAs({ href, fileName }).then(resolve).catch(reject);
    } else if (href) {
      fileName = fileName || href.match(/[^\/]+$/)[0];
      downloadHttp(href, fileName).then(resolve).catch(reject);
    } else {
      saveAs({ fileName, blob }).then(resolve).catch(reject);
    }
  });
};

export const getParameterByName = (name) => {
  // 使用正则表达式来解析 URL 查询参数
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(window.location.href);

  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const debounce = (fn, delay) => {
  // 防抖函数 在规定时间内未触发第二次，则执行
  let timer = null;
  return function (...arg) {
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, arg);
    }, delay);
  };
};

function throttle(func, delay) {
  let throttled = false;

  return function (...args) {
    if (!throttled) {
      func.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, delay);
    }
  };
}

export const copyToClipboard = (text) => {
  // 使用 Clipboard API 复制内容到剪切板
  return new Promise((resolve, reject) => {
    const { toClipboard } = useClipboard();
    let copyTo = null;
    if (getDeviceType() == "ios") {
      copyTo = Copy;
    } else {
      copyTo = toClipboard;
    }
    copyTo(text)
      .then(
        () => {
          console.log("Copy to clipboard content:\n", text);
          resolve("Copy to clipboard successfully");
        },
        (e) => {
          console.log("copyToClipboard Failed", e);
          reject("Copy to clipboard unsuccessfully");
        }
      )
      .catch((e) => {
        console.log("copyToClipboard Failed", e);
        reject("Copy to clipboard unsuccessfully");
      });
  });
};

export const isFunction = (variable) => typeof variable === "function"; // 是否是函数

export const isObject = (target) => {
  // 判断是否为object类型
  return typeof target === "object" && target;
};

export const generateRandomColor = () => {
  /*生成随机颜色:rgb(r,g,b)*/
  let r = Math.floor(Math.random() * 256);
  let g = Math.floor(Math.random() * 256);
  let b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
};

export const registPolling = (callback, interval, immediately = true) => {
  // 轮询 ms
  // callback 轮询期间不间断回掉
  // interval 轮询间隔
  // 返回终止回掉
  if (immediately) {
    callback();
  }
  let timer = setInterval(
    () => {
      callback();
    },
    interval ? interval : 3000
  );
  return () => {
    clearInterval(timer);
    return null;
  };
};

const deepClone = (data, map = new WeakMap()) => {
  // WeakMap作为记录对象Hash表（用于防止循环引用）
  // 基础类型直接返回值
  if (!isObject(data) || !isFunction(data)) {
    return data;
  }
  // 日期或者正则对象则直接构造一个新的对象返回
  if ([Date, RegExp].includes(data.constructor)) {
    return new data.constructor(data);
  }
  // 处理函数对象
  if (typeof data === "function") {
    return new Function("return " + data.toString())();
  }
  // 如果该对象已存在，则直接返回该对象
  const exist = map.get(data);
  if (exist) {
    return exist;
  }
  // 处理Map对象
  if (data instanceof Map) {
    const result = new Map();
    map.set(data, result);
    data.forEach((val, key) => {
      // 注意：map中的值为object的话也得深拷贝
      if (isObject(val)) {
        result.set(key, deepClone(val, map));
      } else {
        result.set(key, val);
      }
    });
    return result;
  }
  // 处理Set对象
  if (data instanceof Set) {
    const result = new Set();
    map.set(data, result);
    data.forEach((val) => {
      // 注意：set中的值为object的话也得深拷贝
      if (isObject(val)) {
        result.add(deepClone(val, map));
      } else {
        result.add(val);
      }
    });
    return result;
  }
  // 收集键名（考虑了以Symbol作为key以及不可枚举的属性）
  const keys = Reflect.ownKeys(data);
  // 利用 Object 的 getOwnPropertyDescriptors 方法可以获得对象的所有属性以及对应的属性描述
  const allDesc = Object.getOwnPropertyDescriptors(data);
  // 结合 Object 的 create 方法创建一个新对象，并继承传入原对象的原型链， 这里得到的result是对data的浅拷贝
  const result = Object.create(Object.getPrototypeOf(data), allDesc);

  // 新对象加入到map中，进行记录
  map.set(data, result);
  // Object.create()是浅拷贝，所以要判断并递归执行深拷贝
  keys.forEach((key) => {
    const val = data[key];
    if (isObject(val)) {
      // 属性值为 对象类型 或 函数对象 的话也需要进行深拷贝
      result[key] = deepClone(val, map);
    } else {
      result[key] = val;
    }
  });
  return result;
};

// 获取文件音频时长
export const getMediaFileDuration = (file) => {
  // 获取文件音频时长
  return new Promise((resolve, reject) => {
    try {
      if (file) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        let reader = new FileReader();

        reader.onload = function (e) {
          const arrayBuffer = e.target.result;

          audioContext.decodeAudioData(
            arrayBuffer,
            function (buffer) {
              const duration = buffer.duration;
              resolve(duration);
              audioContext.close();
              reader = null;
            },
            function (error) {
              // 处理解码失败的情况
              reject(`Audio decoding failed: ${error?.message}`);
              audioContext.close();
              reader = null;
            }
          );
        };

        reader.readAsArrayBuffer(file);
      } else {
        resolve(0); // 如果文件为空，直接返回 0
      }
    } catch (e) {
      reject(`An error occurred: ${e?.message}`);
    }
  });
};

export const addLongPressAndClickHandler = (element, options) => {
  const {
    longPressDuration = 3000,
    longPressCallback = () => {},
    clickCallback = () => {},
  } = options;
  let pressTimer;
  let lastEevet;
  let startX;
  let startY;

  let time = 0;

  const eventmap = {
    handleMouseDown(e) {
      time = new Date().getTime();
      let eventC = e;
      pressTimer = setTimeout(() => {
        const { clientX, clientY } = lastEevet ?? e;
        if (eventC.clientX >= clientX && eventC.clientY === clientY) {
          longPressCallback(eventC);
        }
      }, longPressDuration);
    },
    handleMouseMove(e) {
      lastEevet = e;
    },
    handleMouseUp(e) {
      clearTimeout(pressTimer);
    },
    handleClick(e) {
      if (pressTimer) {
        clearTimeout(pressTimer);
        if (time == 0 || new Date().getTime() - time < 100) {
          clickCallback(e);
          time = 0;
        }
      }
    },
    handleTouchstart(e) {
      time = new Date().getTime();
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      pressTimer = setTimeout(function () {
        longPressCallback(e);
      }, longPressDuration);
    },
    handleTouchmove(e) {
      var moveX = e.touches[0].clientX - startX;
      var moveY = e.touches[0].clientY - startY;
      if (Math.abs(moveX) > 5 || Math.abs(moveY) > 5) {
        clearTimeout(pressTimer);
      }
    },

    handleTouchend(e) {
      clearTimeout(pressTimer);
      var moveX = e.changedTouches[0].clientX - startX;
      var moveY = e.changedTouches[0].clientY - startY;
      if (Math.abs(moveX) <= 5 && Math.abs(moveY) <= 5) {
        if (pressTimer) {
          clearTimeout(pressTimer);
          if (time == 0 || new Date().getTime() - time < 100) {
            clickCallback(e);
            time = 0;
          }
        }
      }
    },
  };
  element.addEventListener("touchstart", eventmap.handleTouchstart);
  element.addEventListener("touchmove", eventmap.handleTouchmove);
  element.addEventListener("touchend", eventmap.handleTouchend);
  element.addEventListener("mousedown", eventmap.handleMouseDown);
  element.addEventListener("mousemove", eventmap.handleMouseMove);
  element.addEventListener("mouseup", eventmap.handleMouseUp);
  element.addEventListener("click", eventmap.handleClick);
  return () => {
    element.removeEventListener("touchstart", eventmap.handleTouchstart);
    element.removeEventListener("touchmove", eventmap.handleTouchmove);
    element.removeEventListener("touchend", eventmap.handleTouchend);
    element.removeEventListener("mousedown", eventmap.handleMouseDown);
    element.removeEventListener("mousemove", eventmap.handleMouseMove);
    element.removeEventListener("mouseup", eventmap.handleMouseUp);
    element.removeEventListener("click", eventmap.handleClick);
  };
};

export const getFileExtension = (url) => {
  let fileName = url.substring(url.lastIndexOf("/") + 1);
  let fileExtension = fileName.split(".").pop();
  return fileExtension;
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone|Kindle|Silk/i.test(
    window.navigator.userAgent
  );
};

export const urlIncludeDomain = (path) => {
  const domainRegex = /^(?:https?:)?\/\/[^/]+/;
  return domainRegex.test(path);
};

export const getDeviceType = () => {
  const userAgent = window.navigator.userAgent;
  if (/Android|Adr/i.test(userAgent)) {
    return "android";
  } else if (
    /(iphone|ipad|ipod|ios)/i.test(userAgent.toLocaleLowerCase()) ||
    !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  ) {
    return "ios";
  } else {
    return "unknown";
  }
};

export const getSafeArea = () => {
  var ua = navigator.userAgent;
  var isIOS = /iPhone|iPad|iPod/i.test(ua);
  var isAndroid = /Android/i.test(ua);
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const aspectRatio = screenHeight / screenWidth;
  const top = aspectRatio >= 1.8 ? 40 : 25;
  const bottom = aspectRatio >= 1.8 ? 15 : 10;
  if (isIOS && window.safeAreaInsets) {
    return {
      top: window.safeAreaInsets.top || top,
      right: window.safeAreaInsets.right || 0,
      bottom: window.safeAreaInsets.bottom || bottom,
      left: window.safeAreaInsets.left || 0,
    };
  } else if (isAndroid && window.screen && window.screen.safeArea) {
    return {
      top: window.screen.safeArea.top || top,
      right: window.screen.safeArea.right || 0,
      bottom: window.screen.safeArea.bottom || bottom,
      left: window.screen.safeArea.left || 0,
    };
  } else {
    return {
      top: top,
      right: 0,
      bottom: bottom,
      left: 0,
    };
  }
};

export const replaceFileExtension = (filename) => {
  let extension = filename.replace(/\.[^.]+$/i, function (match) {
    return match.toLowerCase();
  });
  return extension;
};

export const formatNumberWithUnit = (num) => {
  // 将数字转换为字符串，保留一位小数
  //  let str = num.toFixed(1);
  let str = num;
  // 判断数字的大小，添加相应的后缀
  if (num >= 10000) {
    // 大于等于10000，添加“w”后缀
    str = roundDownToOneDecimalPlace(num / 10000) + "w";
  } else if (num >= 1000) {
    // 大于等于1000，添加“k”后缀
    str = roundDownToOneDecimalPlace(num / 1000) + "k";
  }
  return str;
};
const roundDownToOneDecimalPlace = (num) => {
  return (Math.floor(num * 10) / 10).toFixed(1);
};

export const addDragHandler = (element, options) => {
  const { stopPropagation = true, dragStart, dragMove, dragEnd } = options;

  let touchPosition = {};
  let isDrag = false;
  let lastEvent = null;
  let mouseDown = false;

  const eventmap = {
    handleMouseDown(e) {
      lastEvent = e;
      const { clientX, clientY } = e;
      touchPosition = {
        startX: clientX,
        startY: clientY,
        endX: clientX,
        endY: clientY,
        moveX: 0,
        moveY: 0,
        type: "start",
      };
      isDrag = false;
      mouseDown = true;
      if (stopPropagation) {
        e.stopPropagation();
      }
    },
    handleMouseMove(e) {
      if (!isDrag && mouseDown) {
        lastEvent = e;
        isDrag = true;
        dragStart && dragStart(e, touchPosition);
      }
      if (isDrag && mouseDown && dragMove) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        const { clientX, clientY } = e;
        const moveX = clientX - touchPosition.startX;
        const moveY = clientY - touchPosition.startY;
        Object.assign(touchPosition, {
          endX: clientX,
          endY: clientY,
          moveX: moveX,
          moveY: moveY,
          type: "move",
        });
        dragMove(e, touchPosition);
      }
    },
    handleMouseUp(e) {
      if (isDrag && mouseDown && dragEnd) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        const { clientX, clientY } = e;
        const moveX = clientX - touchPosition.startX;
        const moveY = clientY - touchPosition.startY;
        Object.assign(touchPosition, {
          endX: clientX,
          endY: clientY,
          moveX: moveX,
          moveY: moveY,
          type: "end",
        });
        lastEvent = e;
        dragEnd(e, touchPosition);
      }
      isDrag = false;
      mouseDown = false;
    },

    handleTouchstart(e) {
      lastEvent = e;
      touchPosition = {
        startX: e.touches[0].clientX,
        startY: e.touches[0].clientY,
        endX: e.touches[0].clientX,
        endY: e.touches[0].clientY,
        moveX: 0,
        moveY: 0,
        type: "start",
      };
      isDrag = false;
      mouseDown = true;
      if (stopPropagation) {
        e.stopPropagation();
      }
    },
    handleTouchmove(e) {
      lastEvent = e;
      if (!isDrag && mouseDown) {
        isDrag = true;
        dragStart && dragStart(e, touchPosition);
      }
      if (isDrag && mouseDown && dragMove) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        let moveX = e.touches[0].clientX - touchPosition.startX;
        let moveY = e.touches[0].clientY - touchPosition.startY;
        Object.assign(touchPosition, {
          endX: e.touches[0].clientX,
          endY: e.touches[0].clientY,
          moveX: moveX,
          moveY: moveY,
          type: "move",
        });
        dragMove(e, touchPosition);
      }
    },

    handleTouchend(e) {
      if (isDrag && mouseDown && dragEnd) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        Object.assign(touchPosition, {
          type: "end",
        });
        dragEnd(lastEvent, touchPosition);
        isDrag = false;
      }
      mouseDown = false;
    },
  };
  if ("ontouchstart" in window || navigator.maxTouchPoints) {
    element.addEventListener("touchstart", eventmap.handleTouchstart);
    document.body.addEventListener("touchmove", eventmap.handleTouchmove);
    element.addEventListener("touchend", eventmap.handleTouchend);
    return () => {
      element.removeEventListener("touchstart", eventmap.handleTouchstart);
      document.body.removeEventListener("touchmove", eventmap.handleTouchmove);
      element.removeEventListener("touchend", eventmap.handleTouchend);
    };
  } else {
    element.addEventListener("mousedown", eventmap.handleMouseDown);
    document.body.addEventListener("mousemove", eventmap.handleMouseMove);
    element.addEventListener("mouseup", eventmap.handleMouseUp);
    return () => {
      element.removeEventListener("mousedown", eventmap.handleMouseDown);
      document.body.removeEventListener("mousemove", eventmap.handleMouseMove);
      element.removeEventListener("mouseup", eventmap.handleMouseUp);
    };
  }
};

export const simulateLoading = (
  updateProgressCallback,
  expecteDduration = 30,
  updateFrequency = 10,
  completionDelayTime = 3
) => {
  // 模拟加载‘
  // updateProgressCallback  更新进度持续回掉，参数，进度
  // expecteDduration 加载预期时间 秒
  // updateFrequency 每一秒的更新频率
  // completionDelayTime 完成后调用延迟时间
  const timeStep = 1000 / updateFrequency;
  let progressStep = 1 / (expecteDduration * updateFrequency);
  let progressStepEnd = progressStep * progressStep;
  let progress = 0;
  let maxProgress = 0.9;
  updateProgressCallback(progress);
  let timer = setInterval(() => {
    let r = Math.random();
    if (progress > maxProgress) {
      if (progress <= 0.99) {
        if (r > 0.25) {
          progress = +progress + progressStepEnd;
        } else {
          progress = +progress + r * progressStep;
        }
      }
    } else {
      if (r > 0.5) {
        progress = +progress + progressStep;
      }
      if (progress > 0.75 && r > 0.75 && r < 0.9) {
        maxProgress = r;
      }
    }
    updateProgressCallback(Number(progress.toFixed(4)));
  }, timeStep);
  return (isClose) => {
    // 加载OK调用
    return new Promise((resolve, reject) => {
      if (isClose) {
        clearInterval(timer);
        resolve();
        return;
      }
      clearInterval(timer);
      const residueProgress = 1 - progress;
      let residueStep =
        residueProgress / (updateFrequency * completionDelayTime);
      if (residueStep < progressStep) {
        residueStep = progressStep;
      } else {
        residueStep = residueStep;
      }
      timer = setInterval(() => {
        let r = Math.random();
        if (r > 0.5) {
          progress = +progress + residueStep;
        } else {
          progress = +progress + residueStep + residueStep * r;
        }
        if (progress >= 1) {
          progress = 1;
          clearInterval(timer);
          resolve();
          updateProgressCallback(1);
        } else {
          updateProgressCallback(Number(progress.toFixed(4)));
        }
      }, timeStep);
    });
  };
};

export class HTMLParser {
  constructor() {
    this.init();
  }
  init() {
    this.nodeTagRegs = {
      startTag:
        /^<([-A-Za-z0-9_]+)((?:\s+[a-zA-Z_:][-a-zA-Z0-9_:.]*(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/,
      endTag: /^<\/([-A-Za-z0-9_]+)[^>]*>/,
      attr: /([a-zA-Z_:][-a-zA-Z0-9_:.]*)(?:\s*=\s*(?:(?:"((?:\\.|[^"])*)")|(?:'((?:\\.|[^'])*)')|([^>\s]+)))?/g,
      commentTag: /^<!--(.*?)-->/,
      comment: /<!--(.|[\r\n])*?-->/,
      tags: /^<(?<tag>[^\s>]+)[^>]*>/,
    };
    this.defaultHandlers = {
      parseStartTagCb(node, tag, attrs, isSelfCloseTag, source) {},
      parseEndTagCb(tag) {},
      parseTextCb(text) {},
      parseCommentCb(text) {},
    };
  }
  hTMLParser(html, handler) {
    // 匹配开始和结束以及其他节点，然后调用handler
    let { startTag, endTag, attrs, commentTag, tags } = this.nodeTagRegs;
    let ct, ce, cs;
    while (html) {
      html = html.replace(/^(\s+)?(\n+)?(\r+)?/, "");
      if ((ct = html.match(commentTag))) {
        handler.comments(ct[1]);
        html = html.replace(commentTag, "");
      } else if ((ce = html.match(endTag))) {
        handler.endTag(ce[1]);
        html = html.replace(endTag, "");
      } else if ((cs = html.match(startTag))) {
        handler.startTag(cs[1], cs[2], cs[3], cs[0]);
        html = html.replace(startTag, "");
      } else {
        let chars = "";
        let i = 0;
        while (!html.match(tags)) {
          chars += html[0];
          html = html.substring(1);
        }
        handler.chars(chars);
      }
    }
  }
  createNode(node, { tag, attrs, isSelfCloseTag, source }, results) {
    try {
      let { imagesNodes, imageUrls, hrefNodes, hrefUrls, srcNodes, srcUrls } =
        results;
      let { attr } = this.nodeTagRegs;
      (attrs.match(attr) || []).forEach((item) => {
        let attrArr = item.split("=");
        node.attributes[attrArr[0]] =
          !/"/g.test(attrArr[1]) && /^[0-9.]{1,}$/g.test(attrArr[1])
            ? Number(attrArr[1])
            : attrArr[1].replace(/"/g, "");
      });
      node.classList =
        (node.attributes.class &&
          node.attributes.class.split(" ").filter((item) => item)) ||
        [];
      (node.attributes.style || "")
        .replace(/(&quot;)/gi, "'")
        .replace(/\s+/g, "")
        .split(";")
        .forEach((item) => {
          if (!item) return;
          let attrArr = item.split(":");
          node.style[attrArr[0]] =
            !/"/g.test(attrArr[1]) && /^[0-9.]{1,}$/g.test(attrArr[1])
              ? Number(attrArr[1])
              : attrArr[1].replace(/"/g, "");
        });
      if (node.tagName === "IMG" && node.attributes.src) {
        let imgUrl = node.attributes.src;
        imagesNodes.push(node);
        imageUrls.push(imgUrl);
      } else if (node.attributes.href) {
        node.src = node.attributes.href;
        hrefNodes.push(node);
        hrefUrls.push(node.attributes.href);
      } else if (node.attributes.src) {
        node.src = node.attributes.src;
        srcNodes.push(node);
        srcUrls.push(node.attributes.src);
      } else {
      }
      return node;
    } catch (e) {
      return node;
    }
  }
  HTMLtoJSON(html, handlers) {
    let curHandlers = Object.assign({}, this.defaultHandlers, handlers || {});
    let { parseStartTagCb, parseEndTagCb, parseTextCb, parseCommentCb } =
      curHandlers;
    let nodes = [];
    let results = {
      // 结果
      imagesNodes: [],
      imageUrls: [],
      hrefNodes: [],
      hrefUrls: [],
      srcNodes: [],
      srcUrls: [],
      text: [],
      script: [],
      nodes,
    };
    let tagStack = []; // 记录开始和结束，开始入栈push，结束出栈pop
    let self = this;
    this.hTMLParser(html, {
      startTag(tag, attrs, isSelfCloseTag, source) {
        let node = {
          source: source,
          node: "element",
          tagName: tag.toUpperCase(),
          attributes: {},
          style: {},
          classList: [],
          attrs: attrs.replace(/"/g, ""),
        };
        let parent = tagStack[tagStack.length - 1];
        self.createNode(node, { tag, attrs, isSelfCloseTag, source }, results);
        if (!isSelfCloseTag) {
          tagStack.push(node);
        }
        if (parent) {
          (parent.children || (parent.children = [])).push(node);
        } else {
          nodes.push(node);
        }
        parseStartTagCb(node, tag, attrs, isSelfCloseTag, source);
      },
      endTag(tag) {
        parseEndTagCb(tagStack.pop(), tag);
      },
      chars(text) {
        let node = {
          node: "text",
          text: text,
        };
        let parent = tagStack[tagStack.length - 1] || nodes;
        if (parent.tagName === "SCRIPT") {
          results.script.push(text);
        } else {
          results.text.push(text);
        }
        if (parent) {
          (parent.children || (parent.children = [])).push(node);
        } else {
          nodes.push(node);
        }
        parseTextCb(node, text);
      },
      comments(text) {
        let node = {
          node: "comment",
          text: text,
        };
        let parent = tagStack[tagStack.length - 1] || nodes;
        if (parent) {
          (parent.children || (parent.children = [])).push(node);
        } else {
          nodes.push(node);
        }
        parseCommentCb(text);
      },
    });
    return results;
  }
  // new HTMLParser().HTMLtoJSON('<html lang="en"><head></head>  <!--s1111jjwwghwhwh-->   <body class="class" count=12 style="height:10px;width:30px;font-weight:400;"><text>hello world</text><img /></body></html>')
}

export const registTouchSwipe = (
  el,
  {
    direction = "vertical", // horizontal
    startCb = () => {},
    moveCb = () => {},
    endCb = () => {},
  }
) => {
  let isTouch = false;
  let isMouseDone = false;
  let touchPosition = {};

  const eventmap = {
    touchstart(e) {
      touchPosition = {
        startX: e.touches[0].pageX,
        startY: e.touches[0].pageY,
        endX: e.touches[0].pageX,
        endY: e.touches[0].pageY,
        type: "start",
      };
      isTouch = false;
    },
    touchmove(e) {
      if (!isTouch) {
        isTouch = true;
        startCb(e, touchPosition);
      }
      touchPosition.endX = e.touches[0].pageX;
      touchPosition.endY = e.touches[0].pageY;
      touchPosition.type = "move";
      const diffX = touchPosition.endX - touchPosition.startX;
      const diffY = touchPosition.endY - touchPosition.startY;

      if (direction == "vertical") {
        if (diffY < 0) {
          // touch down
          touchPosition.touchDirection = "up";
        } else if (diffY > 0) {
          // touch up
          touchPosition.touchDirection = "down";
        } else {
        }
      } else {
        if (diffX < 0) {
          // Scroll right
          touchPosition.touchDirection = "left";
        } else if (diffX > 0) {
          // touch left
          touchPosition.touchDirection = "right";
        } else {
        }
      }
      if (isTouch) {
        moveCb(e, touchPosition);
      }
    }, // First scroll event
    touchend(e) {
      touchPosition.type = "end";
      if (isTouch) {
        endCb(e, touchPosition);
      }
      isTouch = false;
    },
    mousestart(e) {
      touchPosition = {
        startX: e.clientX,
        startY: e.clientY,
        endX: e.clientX,
        endY: e.clientY,
        type: "start",
      };
      isMouseDone = true;
      isTouch = false;
    },
    mousemove(e) {
      if (!isMouseDone) {
        return;
      }
      if (!isTouch) {
        isTouch = true;
        startCb(e, touchPosition);
      }
      touchPosition.endX = e.clientX;
      touchPosition.endY = e.clientY;
      touchPosition.type = "move";
      const diffX = touchPosition.endX - touchPosition.startX;
      const diffY = touchPosition.endY - touchPosition.startY;

      if (direction == "vertical") {
        if (diffY < 0) {
          // touch down
          touchPosition.touchDirection = "up";
        } else if (diffY > 0) {
          // touch up
          touchPosition.touchDirection = "down";
        } else {
        }
      } else {
        if (diffX < 0) {
          // Scroll right
          touchPosition.touchDirection = "left";
        } else if (diffX > 0) {
          // touch left
          touchPosition.touchDirection = "right";
        } else {
        }
      }
      if (isTouch) {
        moveCb(e, touchPosition);
      }
    }, // First scroll event
    mouseup(e) {
      isMouseDone = false;
      if (isTouch) {
        touchPosition.type = "end";
        endCb(e, touchPosition);
      }
      isTouch = false;
    },
  };
  // ("ontouchstart" in window && navigator.maxTouchPoints)
  el.addEventListener("touchstart", eventmap.touchstart, false);
  el.addEventListener("touchmove", eventmap.touchmove, false);
  el.addEventListener("touchend", eventmap.touchend, false);
  el.addEventListener("mousedown", eventmap.mousestart, false);
  document.addEventListener("mousemove", eventmap.mousemove, false);
  document.addEventListener("mouseup", eventmap.mouseup, false);
  return () => {
    el.removeEventListener("touchstart", eventmap.touchstart, false);
    el.removeEventListener("touchmove", eventmap.touchmove, false);
    el.removeEventListener("touchend", eventmap.touchend, false);
    el.removeEventListener("mousedown", eventmap.mousestart, false);
    document.removeEventListener("mousemove", eventmap.mousemove, false);
    document.removeEventListener("mouseup", eventmap.mouseup, false);
  };
  // 调用
};

export const formatDate = (date, format) => {
  /* 通用化格式时间 */
  // example  formatDate(new Date('2023-6-10 00:00:000'), "当前日期为：YYYY-MM-DD，星期w，为第qq季度，时间为：hh:mm:ss:c")
  date = new Date(date);
  let o = {
    "Y+": date.getFullYear() + "",
    "M+": date.getMonth() + 1, //month  MM
    "D+": date.getDate(), //day  DD
    "h+": date.getHours(), //hour  hh
    "m+": date.getMinutes(), //minute mm
    "s+": date.getSeconds(), //second ss
    "Q+": Math.floor((date.getMonth() + 3) / 3), //quarter 季度 q
    "c+": date.getMilliseconds(), //millisecond 毫秒 c
    W: ["一", "二", "三", "四", "五", "六", "日"][date.getDay() - 1], //week 星期
  };
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? o[k]
          : ("00" + o[k]).substr(
              ("" + o[k]).length >= 2 ? 2 : ("" + o[k]).length
            )
      );
    }
  }
  return format;
};

export const getDataByFields = (data, dataExpression) => {
  // get数据内部对应路径数据
  let resData = data;
  let find = false;
  if (String(dataExpression)) {
    const dataFieldArr = String(dataExpression).split(".");
    find = dataFieldArr.every((item) => {
      try {
        resData = resData[item];
        return true;
      } catch (error) {
        return false;
      }
    });
  } else {
    return resData;
  }
  if (find) {
    return resData;
  }
  return null;
};
export const setDataByFields = (data, dataExpression, value) => {
  // set数据内部对应路径数据
  let find = false;
  let setData = data;
  if (String(dataExpression)) {
    const dataFieldArr = String(dataExpression).split(".");
    find = dataFieldArr.every((item) => {
      setData = resData[item];
      if (item === dataFieldArr[dataFieldArr.length - 1]) {
        resData[item] = value;
      }
    });
  }
  return find;
};
export default {
  formatNumberWithUnit,
  getUrlAllParams,
  objToQueryUrlString,
  envType,
  isDev,
  isProd,
  getBasicDataType,
  getType,
  getUuid,
  downLoadAtag,
  debounce,
  throttle,
  copyToClipboard,
  isFunction,
  isObject,
  generateRandomColor,
  registPolling,
  deepClone,
  getMediaFileDuration,
  addLongPressAndClickHandler,
  getFileExtension,
  isMobileDevice,
  urlIncludeDomain,
  getDeviceType,
  replaceFileExtension,
  addDragHandler,
  simulateLoading,
  // getDeviceId,
  HTMLParser,
  registTouchSwipe,
  formatDate,
  getDataByFields,
  setDataByFields,
  getParameterByName,
};
