<template>
  <div
    class="height-all custom-select"
    :style="renderStyle"
    :class="{
      'custom-select-multiple': props.multiple,
      'custom-select-canclearable':
        props.clearable &&
        (props.multiple ? renderData.value.length : renderData.value),
    }"
  >
    <el-select
      variant="outlined"
      ref="refSelect"
      :popper-class="`custom-select-options-box ${props.popperClass ?? ''}`"
      v-model="renderData.value"
      :placeholder="props.placeholder"
      :multiple="props.multiple"
      :clearable="props.clearable"
      :disabled="props.disabled"
      :multipleLimit="props.multipleLimit"
      @change="change"
      @clear="clear"
      @close="close"
      @click="click"
      @blur="blur"
      :rules="props.rules"
      :validateTrigger="props.validateTrigger"
    >
      <template
        v-for="(option, index) in props.options"
        :key="option[props.valueKey]"
      >
        <el-option
          :label="option[props.labelKey]"
          :value="option[props.valueKey]"
        />
      </template>
    </el-select>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
} from "vue";

import { useRouter, useRoute } from "vue-router";
import dataUtils from "@/utils/dataUtils.js";

const Router = useRouter();
const route = useRoute();

const props = defineProps({
  renderStyle: {
    type: Object,
    default: () => ({}),
  },
  valueKey: {
    type: String,
    default: "value",
  },
  labelKey: {
    type: String,
    default: "label",
  },
  options: {
    type: Array,
    default: () => [
      // {
      //   label: "吃饭",
      //   value: 1,
      // },
      // {
      //   label: "睡觉",
      //   value: 2,
      // },
      // {
      //   label: "打豆豆",
      //   value: 3,
      // },
    ],
  },
  modelValue: {
    type: [Object, Array],
    default: () => null,
  },
  multipleLimit: {
    type: Number,
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: Boolean,
  placeholder: {
    type: String,
    default: "please select",
  },
  clearable: Boolean,
  multiple: Boolean,
  rules: {
    type: Array,
    default: () => [],
  },
  validateTrigger: {
    type: Array,
    default: () => ["onChange", "onClear", "onInput"],
  },
});

const renderDefaultStyle = {
  "--height": "auto",
  "--padding": "0 0 0 0",
  "--fontSize": "14px",
  "--fontColor": "#000",
  "--fontWeight": "500",
  "--lineHeight": "30px",

  "--borderRadius": "8px",
  "--border": "1px solid #D4D6DC",
  "--focusBorder": "1px solid #0057FF",
};

const refSelect = ref(null);

const renderStyle = Object.assign(
  {},
  renderDefaultStyle,
  props.renderStyle ?? {}
);

const renderDataMap = dataUtils.treeToListMap(props.options, {
  mapKey: props.valueKey,
});
const getRenderValue = () => {
  return props.multiple
    ? (props.modelValue ?? []).map((it) => it[props.valueKey])
    : (props.modelValue ?? {})[props.valueKey] ?? null;
};

const getCallbackValue = () => {
  return props.multiple
    ? (renderData.value ?? []).map((it) => renderDataMap.map[it])
    : renderDataMap.map[renderData.value] ?? null;
};

const emits = defineEmits([
  "update:modelValue",
  "change",
  "focus",
  "blur",
  "close",
  "clear",
  "click",
]);
const renderData = reactive({
  value: getRenderValue(),
});

const change = (val) => {
  renderData.value = val;
  emits("update:modelValue", getCallbackValue());
  emits("change", getCallbackValue());
};

const clear = (val) => {
  renderData.value = null;
  emits("clear", props.multiple ? [] : null);
};
const close = (val) => {
  emits("close", val);
};
const click = (val) => {
  emits("click", val);
};
const blur = (val) => {
  emits("blur", val);
};

watch(
  () => props.modelValue,
  (val) => {
    renderData.value = getRenderValue();
  }
);

onMounted(() => {});
const exposeObj = {
  focus: () => {
    refSelect.value?.focus();
  },
  blur: () => {
    refSelect.value?.blur();
  },
  validate: () => {
    return refSelect.value?.validate();
  },
  resetValidation: () => {
    refSelect.value?.resetValidation();
  },
  reset: () => {
    refSelect.value?.reset();
    inputValue.value = props.multiple ? [] : null;
  },
};
defineExpose(exposeObj);
</script>
<style lang="scss">
.custom-select {
  .el-select__wrapper {
    border-radius: 40px;
    padding: 3px 12px;

    background: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 24px;
    /* 纯白 */
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0);
    box-shadow: none;
    &.is-hovering {
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 0);
      box-shadow: none;
    }
    &.is-focused {
      border: 1px solid #94adff;
      box-shadow: none;
    }
  }
  .el-select__placeholder {
    color: #ffffff;
  }
  .is-transparent {
    color: #ffffff;
  }
  .el-select__placeholder.is-transparent {
    color: rgba(255, 255, 255, 0.5);
  }
  .el-select__icon {
    background: url("@/assets/img/select-arrow-ico.png");
    background-size: 100% 100%;
    height: 16px;
    width: 16px;
    svg {
      display: none;
    }
    &.is-reverse {
      transform: rotate(180deg);
    }
  }
  &.custom-select-multiple {
    .el-select__selection.is-near {
      flex-wrap: nowrap;
      overflow-y: auto;
    }
  }

  &.custom-select-canclearable {
    .el-select:hover {
      .el-select__icon {
        background: url("@/assets/img/close-circle-ico.png");
        background-size: 100% 100%;
        height: 16px;
        width: 16px;
        svg {
          display: none;
        }
        &.is-reverse {
          transform: rotate(180deg);
        }
      }
    }
  }
  .el-select__selected-item {
    .el-tag {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
    }
    span {
      text-transform: capitalize;
    }
    .el-icon {
      color: #fff;
    }
  }
}
.el-popper.custom-select-options-box {
  .el-select-dropdown__wrap {
    max-height: 160px;
  }
  &.el-select__popper {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);

    backdrop-filter: blur(20px);
    border: none;
    box-shadow: none;
    overflow-y: auto;
    .el-popper__arrow:before {
      display: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
    }
    .el-select-dropdown__item {
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 24px;
      height: 24px;
      padding: 0 12px;
      color: rgba(255, 255, 255, 0.7);
      span {
        display: inline-block;
        vertical-align: middle;
        text-transform: capitalize;
      }
      &.is-selected:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin: 0 4px 0 0;
        background: url(@/assets/img/check-ico.png);
        background-size: 100% 100%;
      }

      &.is-hovering {
        background: rgba(255, 255, 255, 0.2);
        color: #ffffff;
      }
    }
  }
}
</style>
