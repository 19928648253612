import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import { initializeApp } from "firebase/app"

// debug测试
// https://tagassistant.google.com/
var analytics

export const firebaseInit = (firebaseConfig) => {
  const app = initializeApp(firebaseConfig)
  // firebase 打点
  analytics = getAnalytics(app)
}

export const firebaseLogEvent = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams)
}

export const firebaseSetUserId = (userId) => {
  setUserId(analytics, userId)
}
