<template>
  <Dialog
    :width="createRenderType == 'pc' ? '340px' : '320px'"
    :backgroundImage="null"
    background="rgba(255, 255, 255, 0.2)"
    titleAlign="center"
    title=""
    customClass="download-mine-audio-pay-dialog"
    :hideCloseBtn="false"
    :visible="renderData.visible"
    @close="closeDialog"
  >
    <div class="height-all download-mine-audio-pay flex-column">
      <div class="dmap-title tc">
        微信{{ isMobileDevice() ? "" : "扫码" }}支付
        <span>{{ totalPrice }}元</span>
      </div>
      <div class="dmap-content">
        <div
          class="dmap-qr-code height-all"
          v-if="!isMobileDevice() && renderData.isPaying"
        >
          <div
            class="dmap-qr-code-img"
            :style="{
              backgroundImage: `url(${qrCodeUrl})`,
              backgroundSize: '100% 100%',
            }"
          ></div>
        </div>
        <div
          class="dmap-items"
          :class="`${createRenderType}-dmap-items`"
          v-if="(!isMobileDevice() && !renderData.isPaying) || isMobileDevice()"
        >
          <div class="dmap-items-title tc">
            {{ $$t("create.read_before_paying") }}
          </div>
          <div class="dmap-items-terms tc pointer" @click="handleOpenService">
            {{ $$t("create.paid_service") }}
          </div>
          <div class="dmap-items-go-pay">
            <Button
              @click="onAggreeClick"
              :renderStyle="{
                '--padding': '0',
                '--width': '100%',
                '--height': '44px',
                '--borderRadius': '50px',
                '--background':
                  'linear-gradient(107deg, #4FDEFF 0%, #E675FF 100%)', //#94ADFF
                '--fontColor': '#000',
                '--fontSize': '14px',
                '--fontWeight': '500',
                '--border': 'none',
                '--backgroundHover':
                  'linear-gradient(97deg, rgba(79, 222, 255, 0.75) 0%, rgba(230, 117, 255, 0.75) 99%)', //#94ADFF
                '--fontColorHover': '#000',
              }"
            >
              {{ $$t("create.agree_to_pay") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  reactive,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { storeToRefs } from "pinia";
import QRCode from "qrcode";
import Dialog from "@/components/basic/dialog/Dialog.vue";
import Button from "@/components/basic/button/Button.vue";
import { $$t } from "@/i18n/i18n.js";
import apiMine from "@/api/api/apiMine.js";
import { isMobileDevice } from "@/utils/tools";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";

const props = defineProps({
  orderId: {
    type: Number,
    default: 0,
  },
  totalPrice: {
    type: Number,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  qrCode: {
    type: String,
    default: "",
  },
  paySuccess: {
    type: Function,
    default: null,
  },
  payFail: {
    type: Function,
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
});

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const emits = defineEmits(["update:visible"]);
const renderData = reactive({
  visible: props.visible,
  isPaying: false,
});
const qrCodeUrl = ref("");
let intervalId = null;

const onAggreeClick = async () => {
  if (!isMobileDevice()) {
    renderData.isPaying = true;
  } else {
    // 唤起h5支付
    window.location.href = props.qrCode
    // window.open(props.qrCode);
  }
};

const closeDialog = () => {
  renderData.visible = false;
};

const getQrCodeUrl = () => {
  QRCode.toDataURL(props.qrCode)
    .then((url) => {
      qrCodeUrl.value = url;
    })
    .catch((error) => {
      console.error("生成二维码失败：", error);
    });
};

watch(
  () => props.visible,
  (val) => {
    renderData.visible = val;
  }
);

watch(
  () => renderData.visible,
  (val) => {
    intervalId && clearInterval(intervalId);
    !val && props.remove();
    emits("update:visible", val);
  }
);

watch(
  () => props.qrCode,
  () => {
    getQrCodeUrl();
  },
  {
    immediate: true,
  }
);

watch(
  () => createRenderType.value,
  () => {
    props.remove();
    renderData.visible = false;
    emits("update:visible", false);
  }
);

const getResult = () => {
  apiMine
    .mineOrderDetail({
      order_id: props.orderId,
    })
    .then((res) => {
      if (res.code == 200) {
        const {
          data: { state, detail },
        } = res;
        if (state == 5) {
          clearInterval(intervalId);
          closeDialog();
          props.payFail && props.payFail();
        } else if (state == 4) {
          clearInterval(intervalId);
          closeDialog();
          props.paySuccess && props.paySuccess(detail?.type);
        }
      }
    });
};

const handleOpenService = () => {
  window.open(window.location.origin + "/paid-service", "_blank");
};

onMounted(() => {
  intervalId = setInterval(getResult, 5000);
});

onUnmounted(() => {
  intervalId && clearInterval(intervalId);
});
</script>
<style lang="scss">
.download-mine-audio-pay-dialog {
  .dialog-box {
    padding: 24px 0 0 0;
    border-radius: 13px;
    opacity: 1;
    backdrop-filter: blur(20px);
  }
  .dialog-close {
    top: 16px;
    right: 16px;
  }
  .dialog-header {
    font-family: HarmonyOS Sans SC;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .content-top-margin {
    height: 7px;
  }
  .dmap-title {
    font-family: HarmonyOS Sans SC;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    span {
      color: #94adff;
    }
  }
  .dmap-content {
    margin: 28px auto 60px;
    width: 296px;
  }
  .dmap-qr-code {
    .dmap-qr-code-img {
      margin: 0 auto;
      width: 184px;
      height: 184px;
      border-radius: 8px;
    }
    .dmap-qr-code-text {
      margin-top: 12px;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      opacity: 0;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .dmap-items {
    height: 184px;
    width: 184px;
    border-radius: 8px;
    border: 1px solid rgba(148, 173, 255, 0.3);
    margin: 0 auto;
    .dmap-items-title {
      padding-top: 28px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
    }
    .dmap-items-terms {
      margin-top: 20px;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
      color: #fff;
      opacity: 0.8;
      text-decoration: underline;
      &:hover {
        opacity: 0.75;
      }
    }
    .dmap-items-go-pay {
      margin: 20px auto 0 auto;
      width: 140px;
    }
  }
  .mobile-dmap-items {
    width: 200px;
    height: 200px;
  }
}
</style>
