import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import Router from "@/router/index.js";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { showActionDialog } from "@/components/functionCallComponent/action/action.js";
import { $$language, $$t } from "@/i18n/i18n.js";

import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import useUserDataStore from "@/store/userDataStore.js";

import useDraftDataStore from "./draftDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import userReferMusicStore from "./referMusicStore.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";

import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();
// const referMusicStore = userReferMusicStore();

const TabDataSuffixLists = ["createdresult", "likemysong", "mysong"];
export const TabDataSuffixMap = {
  mysong: "mysong",
  likemysong: "likemysong",
  createdresult: "createdresult",
};

const FeedListApiMap = {
  mysong_true: feedListApi.getFilterFeedList,
  mysong_false: feedListApi.getFeedList,

  likemysong_true: feedListApi.getMyFavoriteSongs,
  likemysong_false: feedListApi.getMyFavoriteSongs,

  createdresult_true: feedListApi.getFilterFeedList,
  createdresult_false: feedListApi.getFeedList,
};

const useFeedListStore = defineStore({
  id: "FeedListStore",
  state: () => {
    return {
      feedlist_createdresult: [],
      total_createdresult: 0,
      loadMoreId_createdresult: "",
      isloading_createdresult: true,
      hasInit_createdresult: false,
      hasMore_createdresult: false,

      feedlist_likemysong: [],
      total_likemysong: 0,
      loadMoreId_likemysong: "",
      isloading_likemysong: true,
      hasInit_likemysong: false,
      hasMore_likemysong: false,

      feedlist_mysong: [],
      total_mysong: 0,
      loadMoreId_mysong: "",
      isloading_mysong: true,
      hasInit_mysong: false,
      hasMore_mysong: false,

      refFeedList: null,
      curSseConnectId: null,
      curGenerateFeedId: null,

      generating: false,
      initGenerateFeedData: null,
    };
  },
  actions: {
    clearData() {
      this.feedlist_createdresult = [];
      this.loadMoreId_createdresult = "";
      this.isloading_createdresult = true;
      this.hasInit_createdresult = false;
      this.hasMore_createdresult = false;

      this.feedlist_likemysong = [];
      this.loadMoreId_likemysong = "";
      this.isloading_likemysong = true;
      this.hasInit_likemysong = false;
      this.hasMore_likemysong = false;

      this.feedlist_mysong = [];
      this.loadMoreId_mysong = "";
      this.isloading_mysong = true;
      this.hasInit_mysong = false;
      this.hasMore_mysong = false;

      this.curSseConnectId = null;
      this.sseGenerating?.abort();
      this.generating = false;
    },
    initFeedData(data, params) {
      const { listRenderType } = params;

      this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = data?.list ?? [];
      this[`total_${TabDataSuffixMap[listRenderType]}`] = data?.total ?? 0;
      if (this.initGenerateFeedData && listRenderType == "createdresult") {
        if (
          !this[`feedlist_${TabDataSuffixMap[listRenderType]}`].some(
            (item) => item.feed_id === this.initGenerateFeedData.feed_id
          )
        ) {
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`].unshift(
            this.initGenerateFeedData
          );
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = [].concat(
            this[`feedlist_${TabDataSuffixMap[listRenderType]}`]
          );
        }
        this.initGenerateFeedData = null;
      }
      this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
        data?.last_id ?? "";
      if (listRenderType == "createdresult") {
        if (
          this.feedlist_createdresult.length > 0 &&
          [1, 2].indexOf(this.feedlist_createdresult[0].state) >= 0
        ) {
          this.doGenerateConnectedSSE.call(
            this,
            this.feedlist_createdresult[0].conn_id,
            this.feedlist_createdresult[0]?.feed_id
          );
        }
      }
      return this[`feedlist_${TabDataSuffixMap[listRenderType]}`];
    },

    getFeedList(params, done, errordone) {
      const { listRenderType } = params;
      this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
      this[`params_${listRenderType}`] = params;
      if (!params.title) {
        delete params.title;
      }
      return new Promise(async (resolve, reject) => {
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              let resData = this.initFeedData(data, params);
              resolve(resData);
              done(resData, !data.more);
            } else {
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((e) => {
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            reject(false);
          });
      });
    },

    initialize() {
      this.init(this[`params_${listRenderType}`] ?? {});
    },

    init(params = {}, done, errordone, isSearch, showLoading) {
      const { listRenderType } = params;
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: $$t("create.loading"),
          });
        }

        this.getFeedList(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[listRenderType]}`] = true;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            reject(err);
          });
      });
    },

    async refresh(params, done, errordone) {
      const { listRenderType } = params;
      return new Promise((resolve, reject) => {
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          return;
        }
        this.getFeedList(params, done, errordone)
          .then((datas) => {
            resolve(datas);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadMore(done, errordone, listRenderType) {
      return new Promise((resolve, reject) => {
        this[`params_${listRenderType}`].last_id =
          this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`];
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          reject("loading");
          return;
        }
        if (
          !this[`params_${listRenderType}`].last_id ||
          !this[`hasMore_${TabDataSuffixMap[listRenderType]}`]
        ) {
          done && done([], true);
          reject("no more");
          return;
        }
        this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](this[`params_${listRenderType}`])
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
                data.last_id;
              this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = []
                .concat(this[`feedlist_${TabDataSuffixMap[listRenderType]}`])
                .concat(data.list ?? []);
              this.addFeedDataToPlayList(data.list ?? []);
              done(data.list ?? [], !data.more);
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              resolve(data.list ?? []);
            } else {
              reject(msg ?? "error qquery code");
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((err) => {
            reject(err);
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            errordone();
          });
      });
    },
    checkCreateRouter(isOpenGenerate) {
      const createLayoutStore = useCreateLayoutStore();
      createLayoutStore.toggleCreateEditOpenState(isOpenGenerate, true);
      if (Router?.currentRoute?.name != "create") {
        Router.push({
          name: "create",
        });
      }
    },

    doGernerate(isInit) {
      const draftDataStore = useDraftDataStore();
      const userDataStore = useUserDataStore();
      return new Promise(async (resolve, reject) => {
        const feedData = await draftDataStore.getGenerateDraftData();
        let prompt = feedData.prompt?.content;
        if (feedData.lyrics?.length < 50) {
          message.info({
            position: "top",
            content: $$t("create.min_50_letters_lyric"),
          });
          reject($$t("create.min_50_letters_lyric"));
          return;
        }
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject($$t("create.has_song_generating"));
          return;
        }
        this.generating = true;
        feedListApi
          .doGenerate(feedData)
          .then((res) => {
            const { data, code, msg } = res;

            if (code == 200) {
              draftDataStore.setLastGenerateDraftData(feedData);
              this.conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              if (isInit) {
                this.initGenerateFeedData = data.feed;
              } else {
                this.initGenerateFeedData = null;
              }
              this.insertGenerateCard(data.feed);
              this.generating = false;
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              userDataStore.refreshUserAccount();
              resolve(data);

              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                mode: "2",

                musicid: data.feed?.feed_id,

                ...(prompt ? { prompt } : {}),
              });
              this.checkCreateRouter(false);
            } else {
              this.checkGenerateError(
                {
                  res,
                  isGenerate: true,
                  feedData,
                  mode: "2",
                },
                prompt ? { prompt } : {}
              );
              this.generating = false;
              reject("feed generate failed");
            }
          })
          .catch((err) => {
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: "2",
              ...(prompt ? { prompt } : {}),
            });
            this.generating = false;
            reject(err);
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
          });
      });
    },
    doGenerateEasyMode(isInit) {
      const draftDataStore = useDraftDataStore();
      const userDataStore = useUserDataStore();
      return new Promise(async (resolve, reject) => {
        const feedData = await draftDataStore.getGenerateDraftDataEasyMode();
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject($$t("create.has_song_generating"));
          return;
        }
        let prompt = feedData.prompt?.content;
        this.generating = true;
        feedListApi
          .doGenerate(feedData)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              draftDataStore.setLastGenerateDraftData(feedData);
              this.conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              // if (isInit) {
              //   this.initGenerateFeedData = data.feed;
              // } else {
              //   this.initGenerateFeedData = null;
              // }
              this.insertGenerateCard(data.feed);
              this.generating = false;
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              userDataStore.refreshUserAccount();
              resolve(data);
              firebaseUtils.logClickEvent("create_generate", {
                prompt,
                mode: "1",
                result: 0,
                musicid: data.feed?.feed_id,
              });
              this.checkCreateRouter(false);
            } else {
              this.checkGenerateError(
                {
                  res,
                  isGenerate: true,
                  feedData,
                  mode: "1",
                },
                {
                  prompt,
                }
              );
              this.generating = false;
              reject("feed generate failed");
            }
          })
          .catch((err) => {
            firebaseUtils.logClickEvent("create_generate", {
              mode: "1",
              result: 2,
              prompt: feedData.prompt?.content,
            });
            this.generating = false;
            reject(err);
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
          });
      });
    },
    checkGenerateError({ res, isGenerate, feedData, mode }, logParams) {
      const museDataStore = useMuseDataStore();
      let machine_audit_state = null;

      switch (res.code) {
        case 6101:
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          break;

        case 6103:
          message.error({
            position: "top",
            content: $$t("create.generate_title_audit_failed"),
          });
          break;

        case 6104:
          message.error({
            position: "top",
            content: $$t("create.generate_lyric_audit_failed"),
          });
          break;

        case 6108:
          message.error({
            position: "top",
            content: $$t("create.generate_text_audit_failed"),
          });
          break;
        case 6314:
          machine_audit_state = 2;
          message.info({
            position: "top",
            content: $$t("create.song_state_auditing"),
          });
          break;

        case 6315:
          machine_audit_state = 4;
          message.info({
            position: "top",
            content: $$t("create.song_state_auditing_failed"),
          });
          this.updateFeedSongItem({
            song_id: item.song_id,
            machine_audit_state,
          });
          break;

        case 6317:
          message.error({
            position: "top",
            content: $$t("create.generate_refer_audit_failed"),
          });
          if (isGenerate) {
            museDataStore.deleteMuseDataByAudit(feedData);
          }
          break;

        case 6310:
          Router.push({
            name: "credits",
          });
          firebaseUtils.logClickEvent("create_generate", {
            result: 1,
            mode,
            ...(logParams ?? {}),
          });
          break;

        case 6301:
        case 6302:
        case 6303:
        case 6308:
          message.error({
            position: "top",
            content: $$t("create.generate_failed"),
          });
          firebaseUtils.logClickEvent("create_generate", {
            result: 2,
          });
          break;

        default:
          message.error({
            position: "top",
            content: $$t("create.generate_failed"),
          });
          firebaseUtils.logClickEvent("create_generate", {
            result: 2,
            mode,
          });
          break;
      }
    },
    insertGenerateCard(data) {
      this.feedlist_createdresult.unshift(data);
      this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
    },
    async doGenerateConnectedSSE(conn_id, feed_id) {
      if (this.generating) {
        return;
      }
      const userDataStore = useUserDataStore();
      this.generating = true;
      this.curSseConnectId = conn_id;
      this.curGenerateFeedId = feed_id;
      this.sseGenerating = await sseGenerateApi.doSseGetGenerate({
        params: `conn_id=${conn_id}`,
        onOpen(e) {},
        onMessage: (res) => {
          if (["inf_feed", "pgc_feed"].indexOf(res.event) >= 0) {
            const feeddata = JSON.parse(res.data ?? "{}");
            this.updateGenerateCard(feeddata, feed_id);

            if (feeddata?.state == 4 || feeddata?.state == 6) {
              userDataStore.refreshUserAccount();
            }
            if (feeddata?.state == 3) {
              let count = 0;
              feeddata.songs?.forEach((item, index) => {
                if (item.recall) {
                  count++;
                }
              });
              if (count > 0) {
                message.error({
                  position: "top",
                  content: $$t("create.generating_some_error").replace(
                    "[count]",
                    count
                  ),
                });
                userDataStore.refreshUserAccount();
              }
            }
          } else {
            if (res.event == "done") {
              this.sseGenerating?.abort();
              this.generating = false;
            }
          }
        },
        onError: (e) => {},
        onClose: (e) => {
          this.generating = false;
        },
      });
    },
    updateGenerateCard(item, feed_id) {
      item.feed_id = item.feed_id ?? feedid;
      let index = this.feedlist_createdresult.findIndex(
        (itemF) => itemF.feed_id === item.feed_id
      );
      if (index > -1) {
        Object.assign(this.feedlist_createdresult[index], item);
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }
    },
    updateFeedSongItem({ song_id, ...obj }) {
      if (
        this.feedlist_createdresult?.some((itemF) => {
          return itemF.songs?.some((item) => {
            if (item.song_id == song_id) {
              Object.assign(item, obj);
              return true;
            } else {
              return false;
            }
          });
        })
      ) {
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }
    },

    doRestore(pitem, item) {
      const draftDataStore = useDraftDataStore();
      const createLayoutStore = useCreateLayoutStore();
      return new Promise((resolve, reject) => {
        showActionDialog({
          content: $$t("create.restore_alert"),
          cancelLabel: $$t("create.cancel"),
          confirmLabel: $$t("create.confirm"),
          confirm: (closeAction) => {
            closeAction();
            const closeLoading = message.loading({
              position: "center",
              content: $$t("create.restoring"),
            });
            feedListApi
              .reEditCurFeedDraft({
                feed_id: pitem.feed_id,
                song_id: item.song_id,
              })
              .then((res) => {
                closeLoading?.clear();
                if (res.code == 200) {
                  draftDataStore.setDraftData(res.data, true);
                  if (res.data?.mode == 1) {
                    createLayoutStore.setCreateEditMode("easy");
                  } else {
                    createLayoutStore.setCreateEditMode("hard");
                  }

                  resolve();
                  this.checkCreateRouter(true);
                } else {
                  let machine_audit_state = null;
                  if (res.code == 6314) {
                    machine_audit_state = 2;
                    message.info({
                      position: "top",
                      content: $$t("create.song_state_auditing"),
                    });
                    reject($$t("create.song_state_auditing"));
                  } else if (res.code == 6315) {
                    machine_audit_state = 4;
                    message.info({
                      position: "top",
                      content: $$t("create.song_state_auditing_failed"),
                    });
                    reject($$t("create.song_state_auditing_failed"));
                    this.updateFeedSongItem({
                      song_id: item.song_id,
                      machine_audit_state,
                    });
                  } else {
                    message.error({
                      position: "top",
                      content: $$t("create.restore_failed"),
                    });
                    reject($$t("create.restore_failed"));
                  }
                }
              })
              .catch((err) => {
                closeLoading?.clear();
                console.error(err);
                message.error({
                  position: "top",
                  content: $$t("create.restore_failed"),
                });
                reject($$t("create.restore_failed"));
              });
          },
          cancel(closeAction) {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },

    doRegenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject($$t("create.has_song_generating"));
          return;
        } else {
          this.generating = true;
          feedListApi
            .feedCardGenerate({})
            .then((res) => {
              const { data, code, msg } = res;
              if (code == 200) {
                this.conn_id = data.conn_id;
                this.insertGenerateCard(data.feed);
                this.generating = false;
                this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
                userDataStore.refreshUserAccount();
                resolve(data);
                firebaseUtils.logClickEvent("create_generate", {
                  mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
                  musicid: data.feed?.feed_id,
                  result: 0,
                });
                this.checkCreateRouter(false);
              } else {
                this.checkGenerateError({ res, mode: "2" });
                this.generating = false;
                reject("feedCardGenerate failed");
              }
            })
            .catch((err) => {
              firebaseUtils.logClickEvent("create_generate", {
                result: 2,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.generating = false;
              reject(err);
              message.error({
                position: "top",
                content: $$t("create.generate_failed"),
              });
            });
        }
      });
    },
    doEditSongDoneReGenerate(pitem, item, params) {
      params.start_milliseconds = Math.max(
        0,
        Math.min(item.duration_milliseconds, params.start_milliseconds)
      );

      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      console.log("doEditSongDoneReGenerate", pitem, item, params);
      return new Promise((resolve, reject) => {
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject($$t("create.has_song_generating"));
          return;
        } else {
          this.generating = true;
          feedListApi
            .feedCardEditedReGenerate(params)
            .then((res) => {
              const { data, code, msg } = res;
              if (code == 200) {
                userDataStore.refreshUserAccount();
                this.conn_id = data.conn_id;
                draftDataStore.updateFeedId(data.new_id);
                this.insertGenerateCard(data.feed);
                this.generating = false;
                this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);

                resolve(data);
                firebaseUtils.logClickEvent("create_generate", {
                  result: 0,
                  musicid: data.feed?.feed_id,
                  mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
                });
                this.checkCreateRouter(false);
              } else {
                this.checkGenerateError({ res, mode: "2" });
                this.generating = false;
                reject("feedCard Generate failed");
              }
            })
            .catch((err) => {
              firebaseUtils.logClickEvent("create_generate", {
                result: 2,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.generating = false;
              reject(err);
              message.error({
                position: "top",
                content: $$t("create.generate_failed"),
              });
            });
        }
      });
    },
    doExtendDoneReGenerate(pitem, item, params) {
      params.start_milliseconds = Math.max(
        0,
        Math.max(
          0,
          Math.min(item.duration_milliseconds, params.start_milliseconds) -
            ($$language() == "cn" ? 5000 : 0)
        )
      );
      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      return new Promise((resolve, reject) => {
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject($$t("create.has_song_generating"));
          return;
        } else {
          this.generating = true;
          feedListApi
            .feedCardEditedReGenerate(params)
            .then((res) => {
              const { data, code, msg } = res;
              if (code == 200) {
                userDataStore.refreshUserAccount();
                this.conn_id = data.conn_id;
                draftDataStore.updateFeedId(data.new_id);
                this.insertGenerateCard(data.feed);
                this.generating = false;
                this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
                resolve(data);
                firebaseUtils.logClickEvent("create_generate", {
                  result: 0,
                  musicid: data.feed?.feed_id,
                  mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
                });
                this.checkCreateRouter(false);
              } else {
                this.checkGenerateError({ res, mode: "2" });
                this.generating = false;
                reject("feedCard Generate failed");
              }
            })
            .catch((err) => {
              firebaseUtils.logClickEvent("create_generate", {
                result: 2,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.generating = false;
              reject(err);
              message.error({
                position: "top",
                content: $$t("create.generate_failed"),
              });
            });
        }
      });
    },
    reGenerateSmItem() {},
    doExtend(pitem, item) {},
    doFrom(pitem, item) {},
    doRetryGenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      return new Promise((resolve, reject) => {
        if (this.generating) {
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          reject("has song generating");
        }
        this.generating = true;
        feedListApi
          .feedCardRetryGenerate({
            feed_id: pitem.feed_id,
          })
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              userDataStore.refreshUserAccount();
              this.conn_id = data.conn_id;
              this.updateGenerateCard(data.feed);
              this.generating = false;
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              resolve(data);
              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                musicid: data.feed?.feed_id,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
            } else {
              this.checkGenerateError({
                res,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.generating = false;
              reject("feedCard Generate failed");
            }
          })
          .catch((err) => {
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
            });
            this.generating = false;
            reject(err);
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
          });
      });
    },
    doCancelGenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        showActionDialog({
          // title: "Title",
          content: $$t("create.cancel_generating_alert"),
          confirmLabel: $$t("create.confirm"),
          cancelLabel: $$t("create.cancel"),
          confirm: (closeAction) => {
            closeAction();
            feedListApi
              .feedCardCancelGenerate({
                feed_id: pitem.feed_id,
              })
              .then((res) => {
                if (res.code == 200) {
                  if (this.curGenerateFeedId == pitem?.feed_id) {
                    this.curGenerateFeedId = null;
                    this.sseGenerating?.abort();
                    this.generating = false;
                    this.initGenerateFeedData = null;
                  }
                  let index = this.feedlist_createdresult.findIndex(
                    (itemF) => itemF.feed_id == pitem.feed_id
                  );
                  if (index > -1) {
                    this.feedlist_createdresult.splice(index, 1);
                    this.feedlist_createdresult = [].concat(
                      this.feedlist_createdresult
                    );
                    resolve();
                  }
                  userDataStore.refreshUserAccount();
                } else {
                  message.error({
                    position: "top",
                    content: $$t("create.cancel_generating_failed"),
                  });
                  reject("feedCard Cancel Generate failed");
                }
              })
              .catch((err) => {
                message.error({
                  position: "top",
                  content: $$t("create.cancel_generating_failed"),
                });
                reject(err);
              });
          },
          cancel: (closeAction) => {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },
    doDeleteGenerate(pitem, item) {
      return new Promise((resolve, reject) => {
        // showActionDialog({
        //   // title: "Title",
        //   content: "Are you sure you want to remove the generation?",
        //   confirm: (closeAction) => {
        //     closeAction();
        feedListApi
          .feedCardDeleteGenerate({
            feed_id: pitem.feed_id,
          })
          .then((res) => {
            if (res.code == 200) {
              if (this.curGenerateFeedId == pitem?.feed_id) {
                this.curGenerateFeedId = null;
                this.sseGenerating?.abort();
                this.generating = false;
                this.initGenerateFeedData = null;
              }
              let index = this.feedlist_createdresult.findIndex(
                (itemF) => itemF.feed_id == pitem.feed_id
              );
              if (index > -1) {
                this.feedlist_createdresult.splice(index, 1);
                this.feedlist_createdresult = [].concat(
                  this.feedlist_createdresult
                );
                resolve();
              }
            } else {
              message.error({
                position: "top",
                content: $$t("create.cancel_generating_failed"),
              });
              reject("feedCard Cancel Generate failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.cancel_generating_failed"),
            });
            reject(err);
          });
        //   },
        //   cancel: (closeAction) => {
        //     closeAction();
        //     reject("User canceled");
        //   },
        // });
      });
    },
    doDelete(pitem, item) {
      return new Promise((resolve, reject) => {
        showActionDialog({
          // title: "Title",
          content: $$t("create.remove_song_card"),
          confirmLabel: $$t("create.confirm"),
          cancelLabel: $$t("create.cancel"),
          confirm: (closeAction) => {
            closeAction();
            feedListApi
              .feedCardDelete({
                song_id: item.song_id,
              })
              .then((res) => {
                if (res.code == 200) {
                  for (let i = 0; i < TabDataSuffixLists.length; i++) {
                    const listRenderType = TabDataSuffixLists[i];
                    let index = this[`feedlist_${listRenderType}`].findIndex(
                      (itemF) =>
                        itemF.songs?.some(
                          (itemc) => itemc.song_id == item.song_id
                        )
                    );
                    if (index > -1) {
                      let delIndex = this[`feedlist_${listRenderType}`][
                        index
                      ]?.songs.findIndex((itemF) => {
                        return item.song_id == itemF.song_id;
                      });
                      this[`feedlist_${listRenderType}`][index]?.songs?.splice(
                        delIndex,
                        1
                      );
                      this[`feedlist_${listRenderType}`] = [].concat(
                        this[`feedlist_${listRenderType}`]
                      );
                    }
                  }
                  resolve();
                } else {
                  message.error({
                    position: "top",
                    content: $$t("create.remove_song_card_failed"),
                  });
                  reject("remove song card failed");
                }
              })
              .catch((err) => {
                message.error({
                  position: "top",
                  content: $$t("create.remove_song_card_failed"),
                });
                reject("remove song card failed");
              });
          },
          cancel: (closeAction) => {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },
    updataSongDataItem(data) {
      for (let i = 0; i < TabDataSuffixLists.length; i++) {
        const listRenderType = TabDataSuffixLists[i];
        let index = this[`feedlist_${listRenderType}`].findIndex((itemF) =>
          itemF.songs?.some((itemc) => {
            if (itemc.song_id == data.song_id) {
              Object.assign(itemc, data);
              return true;
            }
            return false;
          })
        );
        if (index > 0) {
          this[`feedlist_${listRenderType}`] = [].concat(
            this[`feedlist_${listRenderType}`]
          );
        }
      }
    },
    doLike(pitem, item) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      return new Promise((resolve, reject) => {
        let state = item.is_liked ? 0 : 1;
        feedListApi
          .favoriteSong({
            song_id: item.song_id,
            state,
          })
          .then((res) => {
            if (res.code == 200) {
              for (let i = 0; i < TabDataSuffixLists.length; i++) {
                const listRenderType = TabDataSuffixLists[i];
                let index = this[`feedlist_${listRenderType}`].findIndex(
                  (itemF) =>
                    itemF.songs?.some((itemc) => itemc.song_id == item.song_id)
                );
                let cindex = -1;
                if (index > -1) {
                  this[`feedlist_${listRenderType}`][index].songs.forEach(
                    (itemc, indexc) => {
                      if (itemc.song_id == item.song_id) {
                        cindex = indexc;
                        itemc.is_liked = res.data.state;
                        itemc.favorite_state = itemc.is_liked;
                      }
                    }
                  );
                  if (listRenderType == "likemysong" && cindex > -1) {
                    this[`feedlist_${listRenderType}`][index].songs.splice(
                      cindex,
                      1
                    );
                    if (
                      this[`feedlist_${listRenderType}`][index].songs.length ==
                      0
                    ) {
                      this[`feedlist_${listRenderType}`].splice(index, 1);
                    }
                  }
                  this[`feedlist_${listRenderType}`] = [].concat(
                    this[`feedlist_${listRenderType}`]
                  );
                  globalPlayDataStore.updatePlayDataItem(item);
                }
              }
              resolve(state);
            } else {
              // message.error({
              //   position: "top",
              //   content: "Save failed",
              // });
              reject(res.code);
            }
          })
          .catch((err) => {
            // message.error({
            //   position: "top",
            //   content: "Save failed",
            // });
            reject(err);
          });
      });
    },
    doMore(pitem, item) {},
    doGoDetail(pitem, item) {
      Router.push({
        name: "createDetail",
        query: {
          id: item.song_id,
        },
      });
    },
    doEditSongTitle(pitem, item) {
      for (let i = 0; i < TabDataSuffixLists.length; i++) {
        const listRenderType = TabDataSuffixLists[i];
        let index = this[`feedlist_${listRenderType}`].findIndex((itemF) =>
          itemF.songs?.some((itemc) => itemc.song_id == item.song_id)
        );
        if (index > -1) {
          this[`feedlist_${listRenderType}`][index].songs.forEach((itemc) => {
            if (itemc.song_id == item.song_id) {
              Object.assign(itemc, item);
            }
          });
          this[`feedlist_${listRenderType}`] = [].concat(
            this[`feedlist_${listRenderType}`]
          );
        }
        globalPlayDataStore.updatePlayDataItem(item);
      }
    },
    doShare(pitem, item) {
      return new Promise((resolve, reject) => {
        tools
          .copyToClipboard(
            `${globalConfig.baseOrigin}/store-detail?share_key=${item.share_key}&is_from_share=1`
          )
          .then((res) => {
            // message.success({
            //   content: "Song link copied",
            //   position: "top",
            // });
            resolve();
          })
          .catch(() => {
            reject("Copy failed");
          });
        feedListApi.shareSongReport({
          song_id: item.song_id,
          channel: "link",
        });
        // .then((res) => {
        //   if (res.code == 200) {
        //     resolve();
        //   } else {
        //     reject("share report failed");
        //   }
        // })
        // .catch((err) => {
        //   reject("share report failed");
        // });
      });
    },
    doSureSoldOut(pitem, item) {
      let closeMsg = message.loading({
        position: "center",
        content: $$t("create.success"),
      });
      const globalPlayDataStore = useGlobalPlayDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .saleManageSong({
            song_id: item.song_id,
            type: 0,
          })
          .then((res) => {
            closeMsg && closeMsg.clear();
            if (res.code == 200) {
              for (let i = 0; i < TabDataSuffixLists.length; i++) {
                const listRenderType = TabDataSuffixLists[i];
                let index = this[`feedlist_${listRenderType}`].findIndex(
                  (itemF) =>
                    itemF.songs?.some((itemc) => itemc.song_id == item.song_id)
                );
                if (index > -1) {
                  this[`feedlist_${listRenderType}`][index].songs.some(
                    (itemc) => {
                      if (itemc.song_id == item.song_id) {
                        itemc.state = 0;
                        return true;
                      }
                    }
                  );
                  this[`feedlist_${listRenderType}`] = [].concat(
                    this[`feedlist_${listRenderType}`]
                  );
                  item.state = 0;
                  globalPlayDataStore.updatePlayDataItem(item);
                }
              }
              resolve();
              message.success({
                position: "top",
                content: $$t("create.save_success"),
              });
            } else {
              let machine_audit_state = null;
              if (res.code == 6314) {
                machine_audit_state = 2;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing"),
                });
                reject("song state auditing");
              } else if (res.code == 6315) {
                machine_audit_state = 4;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing_failed"),
                });
                reject("song state auditing failed");
                this.updateFeedSongItem({
                  song_id: item.song_id,
                  machine_audit_state,
                });
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.save_failed"),
                });
                reject("save failed");
              }
            }
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();

            message.error({
              position: "top",
              content: $$t("create.save_failed"),
            });
            reject(err);
          });
      });
    },
    doSureSale(pitem, item, params = {}) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      let closeMsg = message.loading({
        position: "center",
        content: $$t("create.applying"),
      });
      return new Promise((resolve, reject) => {
        feedListApi
          .saleManageSong({
            song_id: item.song_id,
            ...params,
            type: item.state == 3 ? 2 : 1,
          })
          .then((res) => {
            if (res.code == 200) {
              for (let i = 0; i < TabDataSuffixLists.length; i++) {
                const listRenderType = TabDataSuffixLists[i];
                let index = this[`feedlist_${listRenderType}`].findIndex(
                  (itemF) =>
                    itemF.songs?.some((itemc) => itemc.song_id == item.song_id)
                );
                if (index > -1) {
                  this[`feedlist_${listRenderType}`][index].songs.some(
                    (itemc) => {
                      if (itemc.song_id == item.song_id) {
                        itemc.state = res.data?.state ?? 1;
                        return true;
                      }
                    }
                  );
                  this[`feedlist_${listRenderType}`] = [].concat(
                    this[`feedlist_${listRenderType}`]
                  );
                  item.state = res.data?.state ?? 1;
                  globalPlayDataStore.updatePlayDataItem(item);
                }
              }
              resolve();
              closeMsg && closeMsg.clear();
              message.success({
                position: "top",
                content: $$t("create.song_sale_under_review"),
              });
            } else {
              closeMsg && closeMsg.clear();
              let machine_audit_state = null;
              if (res.code == 6314) {
                machine_audit_state = 2;
              } else if (res.code == 6315) {
                machine_audit_state = 4;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing_failed"),
                });
                this.updateFeedSongItem({
                  song_id: item.song_id,
                  machine_audit_state,
                });
              } else if (res.code == 6121) {
                message.error({
                  position: "top",
                  content: $$t("create.process_song_info"),
                });
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.applying_failed"),
                });
              }
              reject("sale failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.applying_failed"),
            });
            closeMsg && closeMsg.clear();
            reject(err);
          });
      });
    },
    doDownloadAuto(pitem, item) {
      message.error({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 2,
          })
          .then((res) => {
            let machine_audit_state = null;
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
              reject("song state auditing failed");
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: item.mp3_url,
                  fileName:
                    item.title.replace(/(\s+)|(\n+)/g, "_") +
                    "." +
                    tools.getFileExtension(href),
                })
                .then((res) => {
                  // message.success({
                  //   content: $$t("create.download_success"),
                  //   position: "top",
                  // });
                });
              resolve();
            } else if (res.data.paid_state == 3) {
              message.error({
                position: "top",
                content: $$t("create.credits_not_enough"),
              });
              reject("credits not enough");
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject(err);
          });
      });
    },
    doDownloadMp3(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 1,
          })
          .then((res) => {
            let machine_audit_state = null;
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: getObjectStorageFullUrl(res.data.oss_key),
                  fileName:
                    item.title.replace(/(\s+)|(\n+)/g, "_") +
                    "." +
                    tools.getFileExtension(res.data.oss_key),
                })
                .then((res) => {
                  // message.success({
                  //   position: "top",
                  //   content: $$t("create.download_success"),
                  // });
                  resolve();
                })
                .catch((err) => {
                  reject(MediaError);
                });
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject("downloading failed");
          });
      });
    },
    doDownloadCopyright(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 5,
          })
          .then((res) => {
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: getObjectStorageFullUrl(res.data.oss_key),
                  fileName:
                    $$t("create.ownership_certificate") +
                    "." +
                    tools.getFileExtension(res.data.oss_key),
                })
                .then((res) => {
                  // message.success({
                  //   position: "top",
                  //   content: $$t("create.download_success"),
                  // });
                });
              resolve();
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });
            reject("downloading failed");
          });
      });
    },
    doDownloadSongAllData(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.stem_audio_processing"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 2,
          })
          .then((res) => {
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              if (res.data?.paid_state == 1 || !res.data?.paid_state) {
                message.info({
                  position: "top",
                  content: $$t("create.downloading_stem_audio"),
                });
                tools
                  .downLoadAtag({
                    useAlink: true,
                    href: getObjectStorageFullUrl(res.data.oss_key),
                    fileName:
                      item.title.replace(/(\s+)|(\n+)/g, "_") +
                      "." +
                      tools.getFileExtension(res.data.oss_key),
                  })
                  .then((res) => {
                    resolve();
                    // message.success({
                    //   position: "top",
                    //   content: $$t("create.download_success"),
                    // });
                  })
                  .catch((err) => {
                    reject(false);
                  });
              } else if (res.data?.paid_state == 2) {
                message.error({
                  position: "top",
                  content: $$t("create.credits_not_enough"),
                });
                Router.push({
                  name: "credits",
                });
                reject(false);
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.downloading_failed"),
                });
                reject(false);
              }
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject(false);
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject(err);
          });
      });
    },
    doAddPlayList(listRenderType) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup("feedlist");
      let datas = this[`feedlist_${listRenderType}`].reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
          return res;
        }
        return res;
      }, []);
      globalPlayDataStore.playlist = [];
      globalPlayDataStore.addPlayDatas(datas);
    },
    doTogglePlay(pitem, item, isPlay, listRenderType) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup("feedlist");

      let datas = this[`feedlist_${listRenderType}`].reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
          return res;
        }
        return res;
      }, []);
      item.favorite_state = item.is_liked;
      globalPlayDataStore.setPlayDatas(datas, item);
    },
    addFeedDataToPlayList(datas) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      if (globalPlayDataStore.curPlayGroup != "feedlist") {
        return;
      }
      let addDatas = datas.reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
        }
        return res;
      }, []);
      globalPlayDataStore.addPlayDatas(addDatas);
    },
  },
});
export default useFeedListStore;
// export const feedlistStore = useFeedListStore()
