import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import message from "@/components/functionCallComponent/message.jsx";

export const TabDataSuffixMap = {
  1: "all",
};

const MapEasyModeProsetDatApiMap = {
  1: feedListApi.getEasyModePresetData,
};

const useCreateEasyModeStore = defineStore({
  id: "createEasyModeStore",
  state: () => {
    return {
      easyPromptData: {
        content: "",
      },
      datas_all: [],
      loadmoreid_all: "",
      isloading_all: false,
      hasInit_all: false,
      loadtimestamp_all: "",
    };
  },
  actions: {
    initEasyPromptData(data) {
      this.easyPromptData = data?.prompt ?? {
        content: "",
      };
    },
    setEasyPromptData(data) {
      this.easyPromptData = data;
    },
    setPromptData(data) {
      this.easyPromptData = {
        content: data,
      };
    },
    getEasyPromptData() {
      if (!this.easyPromptData?.content) {
        return {
          content: this.defaultPromptData?.content ?? "",
        };
      }
      return (
        this.easyPromptData ?? {
          content: "",
        }
      );
    },
    setDefaultPromptData(data) {
      this.defaultPromptData = {
        content: data,
      };
    },

    init(params, done, errordone, isSearch, showLoading) {
      const { query_type } = params;
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: $$t("create.loading"),
          });
        }

        this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
        this.getdatas(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[query_type]}`] = true;
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            this[`hasInit${TabDataSuffixMap[query_type]}`] = false;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            reject(err);
          });
      });
    },
    getdatas(params, done, errordone, isSearch) {
      const { query_type } = params;
      let loadtimestamp = new Date().getTime();
      this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] = loadtimestamp;
      const getEasyModeProsetData = MapEasyModeProsetDatApiMap[query_type];

      return new Promise(async (resolve, reject) => {
        getEasyModeProsetData(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              if (
                !isSearch ||
                this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] ==
                  loadtimestamp
              ) {
                this[`datas_${TabDataSuffixMap[query_type]}`] = data.list ?? [];
                this[`loadmoreid_${TabDataSuffixMap[query_type]}`] =
                  data.last_id;
              }
              done && done(data.list ?? [], !data.last_id);
              resolve(this[`datas_${TabDataSuffixMap[query_type]}`]);
            } else {
              errordone && errordone(code == -200);
              // if (code == -200) {
              //   message.error({position: "top", content: msg });
              // } else {
              //   message.error({position: "top", content: "加载失败" });
              // }
              reject(this[`datas_${TabDataSuffixMap[query_type]}`]);
            }
          })
          .catch((err) => {
            errordone && errordone();
            reject(err);
            //   message.error({position: "top", content: "加载失败" });
          });
      });
    },
    async refresh(params, done, errordone) {
      const { query_type } = params;
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      this.getdatas(params, done, errordone)
        .then((res) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        })
        .catch((e) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        });
    },
    async loadMore(params, done, errordone) {
      const { query_type } = params;
      params.last_id = this[`loadmoreid_${TabDataSuffixMap[query_type]}`];
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      if (!params.last_id) {
        done && done();
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      const getEasyModeProsetData = MapEasyModeProsetDatApiMap[query_type];

      getEasyModeProsetData(params)
        .then((res) => {
          const { data, code, msg } = res;
          if (code == 200) {
            this[`datas_${TabDataSuffixMap[query_type]}`] = this[
              `datas_${TabDataSuffixMap[query_type]}`
            ].concat(data.list ?? []);
            this[`loadmoreid_${TabDataSuffixMap[query_type]}`] = data.last_id;
          }
          //  else {
          //   message.error({ content: msg });
          // }

          nextTick(() => {
            if (code == 200) {
              done && done(data.list ?? [], !data.last_id);
            } else {
              errordone && errordone(code == -200);
            }
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
          });
        })
        .catch((err) => {
          errordone && errordone(err);
        });
    },
  },
});

export default useCreateEasyModeStore;
