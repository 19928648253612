import globalConfig from "@/config/config.js"
import { $$language } from "@/i18n/i18n.js"
import { getType, getDeviceId, getUrlAllParams } from "@/utils/tools.js"
// import sa from "sa-sdk-javascript"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import { isIos } from "@/utils/cookie"

const headerConfig = {
  "X-App-Name": globalConfig.appname,
}

let AuthData = {
  auth_secret: "8I7RZPf0SOIoCKIT9e8WrteSbFDBFzXI",
  auth_key: "mH8HP1kMFBhT8c3gFgFWrJolHsuAY4nX",
  user_id: 1990,
}

const getAuthorization = () => {
  const { oauth_key, auth_secret, timestamp } = AuthData
  return `oauth_token=${oauth_key},oauth_signature=${auth_secret},oauth_timestamp=${timestamp}`
}
const getUserAgent = async () => {
  //  return 'en/1.0.1/h5//web/635e3f65711348759f54180b91220156/unknown//3x';
  const osVersion = isIos() ? "ios" : "android"
  const deviceId = await firebaseUtils.getAnonymousID()
  const netType = "unknown"
  const deviceType = ""
  const resolution = "3x"
  // $$language()
  return ["cn", globalConfig.version, "h5", osVersion, "web", deviceId, netType, deviceType, resolution].join("/")
}
export const paramFormat = (data = {}) => {
  const result = {}
  const time = new Date().getTime()
  /* 0 app内部进入 1 外部浏览进入 */
  Object.assign(result, {
    time,
    ...data,
  })
  return result
}

export const updateHeaderConfig = (authData) => {
  console.log("updateHeaderConfig", authData)
  AuthData = authData
}

export const getPublicHeader = async () => {
  const useSource = getUseSource()
  return {
    ...headerConfig,
    // 'Authorization': getAuthorization(),
    "X-User-Agent": await getUserAgent(),
    "X-Auth-Timestamp": Math.floor(new Date().getTime()),
    "X-Source": useSource ?? "",
  }
}

export const getUseSource = () => {
  // if (source) {
  //   return source === "baidu" ? `baidu_bd_vid=${bd_vid}` : source;
  // } 

  const adSourceStorage = window.sessionStorage.getItem("ad_source") ?? "";
  if (adSourceStorage) {
    const { source: storedSource, bd_vid: storedBdVid } = JSON.parse(adSourceStorage);
    return storedSource === "baidu" ? `baidu_bd_vid=${storedBdVid}` : storedSource;
  }

  return null; // 或者返回一个默认值
};

// 统一返回格式
// res = {
//   code: '',
//   data: {},
//   "msg": "xxxxx"
// };

export const interfaceSpecification = (data) => {
  // 接口规范
  if (getType(data) === "Object") {
    return data
  } else {
    return data
    console.error("非标准接口数据格式", data)
  }
}
