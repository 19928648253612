<template>
  <transition name="fade">
    <div class="cart-preview flex-column" v-if="popupVisible" @click.stop>
      <div class="preview-title">
        {{ $$t("store.inYourCart") }} ({{ shopCartStore.shopCartNum }})
      </div>
      <div class="flex1 oauto">
        <div class="item" v-for="item in list" :key="item.title">
          <div class="song-name olh">{{ item?.song?.title }}</div>
          <div class="song-nick-name olh">
            {{ item?.song?.user?.stage_name }}
          </div>
          <div class="price">
            {{ $$language() == "en" ? "$" : "¥" }} {{ item.price }}
          </div>
        </div>
      </div>
      <div class="btn pointer" @click="checkout">{{$$t('store.Checkout')}}</div>
    </div>
  </transition>
</template>

<script setup>
import {
  ref,
  defineProps,
  watch,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from "vue";
import { getCartList } from "@/api/api/apiStore.js";
import useShopCartStore from "@/store/shopCartStore.js";
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
import { $$t, $$language } from "@/i18n/i18n";
import message from "@/components/functionCallComponent/message/message.js";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  visible: {
    type: Boolean,
    default: true,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  checkout: {
    type: Function,
    default: null,
  },
});

const shopCartStore = useShopCartStore();
const popupVisible = ref(props.visible);
const list = ref([]);
const timer = ref(null);

const close = () => {
  popupVisible.value = false;
};

const checkout = () => {
  popupVisible.value = false;
  props.checkout(list.value).then((res) => {
    if (res.code == 200) {
      const { invalid_lib_song_ids } = res.data;
      const value = [];
      const type = [];
      if (invalid_lib_song_ids) {
        list.value.forEach((item) => {
          if (
            item.song.cart_state != 2 &&
            !invalid_lib_song_ids.includes(item.song.id)
          ) {
            value.push(item.song.song_id);
            type.push(item.buy_type == 2 ? "Exclusive" : "Non-exclusive");
          }
        });
      } else {
        console.log(list, "yyyyyyy")
        list.value.forEach((item) => {
          if (item.song.cart_state != 2) {
            value.push(item.song.song_id);
            type.push(item.buy_type == 2 ? "Exclusive" : "Non-exclusive");
          }
        });
      }
      firebaseUtils.logClickEvent("song_license_type_checkout", {
        id: value.join(", "),
        type: type.join(","),
        source: "add_to_cart_tips",
      });
    } else if (res.code == 9203) {
          message.error({
            content: "Payment is not available in your country/region",
          });
        }
  });
};

const getList = () => {
  getCartList({}).then((res) => {
    if (res.code == 200) {
      const { data } = res;
      list.value = data.list || [];
    }
  });
};

watch(
  () => popupVisible.value,
  (val) => {
    if (val) {
      getList();
      timer.value = setTimeout(() => {
        popupVisible.value = false;
      }, 3000);
    } else {
      props.remove();
    }
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  document.addEventListener("click", close);
});

onBeforeUnmount(() => {
  clearTimeout(timer.value);
  document.removeEventListener("click", close);
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.cart-preview {
  position: fixed;
  top: 68px;
  right: 85px;
  width: 260px;
  height: 296px;
  background: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  font-family: HarmonyOS Sans SC;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  .preview-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .oauto {
    overflow-y: auto;
  }
  .item {
    position: relative;
    margin-bottom: 16px;
    .song-name {
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
    }
    .song-nick-name {
      margin-top: 3px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
    .price {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .btn {
    align-self: center;
    margin-top: 28px;
    width: max-content;
    padding: 7px 20px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    background: linear-gradient(120deg, #94ADFF 19%, #80fbff 90%);
    border-radius: 62px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>