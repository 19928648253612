<template>
  <div class="login-cn">
    <div class="form-module">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="demo-ruleForm">
        <el-form-item label="" prop="email">
          <Input
            :value="ruleForm.email"
            placeholder="请输入手机号"
            :prefix="mobile"
            :max="11"
            @change="
              (val) => {
                update('email', val)
              }
            "
          />
        </el-form-item>
        <div class="verify-code flex-row">
          <el-form-item class="flex1" :style="{ marginRight: '8px' }" label="" prop="password">
            <Input
              :value="ruleForm.password"
              placeholder="验证码"
              :prefix="LoginLock"
              @change="
                (val) => {
                  update('password', val)
                }
              "
              @enterPressed="onSubmit"
              :max="6"
            />
          </el-form-item>
          <Button
            class="btn-verify"
            @click="getVerifyCode"
            :renderStyle="{
              '--height': '48px',
              // '--padding': '0 16px',
              '--borderRadius': '8px',
              '--background': renderData.isSending ? 'rgba(255, 255, 255, 0.1)' : '#94ADFF',
              '--backgroundHover': renderData.isSending ? 'rgba(255, 255, 255, 0.1)' : 'rgba(148,173,255,0.8)',
              '--fontColor': renderData.isSending ? '#fff' : '#000',
              '--fontColorHover': renderData.isSending ? '#fff' : '#000',
              '--fontSize': '15px',
              '--fontWeight': '400',
              '--border': 'none',
              '--marginLeft': '8px',
            }"
            >{{ renderData.btnText }}</Button
          >
        </div>
      </el-form>
      <div class="login-regist-module-btn">
        <Button
          :renderStyle="{
            '--width': '100%',
            '--height': '48px',
            '--borderRadius': '50px',
            '--background': 'linear-gradient(99deg, #4FDEFF -1%, #E675FF 100%)',
            '--backgroundHover': 'linear-gradient(114deg, rgba(79,222,255,0.8) 21%, rgba(230,117,255,0.8) 90%)',
            '--fontColor': '#000',
            '--fontColorHover': '#000',
            '--fontSize': '16px',
            '--fontWeight': 'bold',
            '--border': 'none',
          }"
          type="primary"
          @click="onSubmit"
          >登录</Button
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef } from "vue"
import Input from "@/components/basic/input/LoginInput2.vue"
import Button from "@/components/basic/button/Button.vue"

import { useRouter } from "vue-router"
import mobile from "@/assets/img/login/mobile.png"
import LoginLock from "@/assets/img/login_lock.png"
import apiUser from "@/api/api/apiUser.js"
import message from "@/components/functionCallComponent/message/message.js"

const Router = useRouter()
const props = defineProps({})
const emits = defineEmits(["submit"])
const ruleForm = reactive({
  email: "", //'test@123.com',
  password: "", //'Qwe123456',
})
const ruleFormRef = ref(null)

const renderData = reactive({
  isSending: false,
  number: 60,
  btnText: "获取验证码",
})

const validateMobile = (rule, value, callback) => {
  const reg = /^[1][3-9]\d{9}$/
  if (value == "") {
    callback(new Error("请输入手机号"))
  } else if (!reg.test(value)) {
    callback(new Error("手机号格式不正确"))
  } else {
    callback()
  }
}

const rules = {
  email: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    // { min: 11, max: 11, message: '手机号格式不正确', trigger: 'submit' },
    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
    // { validator: validateMobile, trigger: "blur" },
  ],
  password: [
    {
      validator: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("验证码不能为空"))
        } else if (value.length < 6) {
          callback(new Error("验证码不足6位"))
        } else {
          callback()
        }
      },
      trigger: "click",
    },
  ],
}

const update = (key, val) => {
  ruleForm[key] = val
}

const onSubmit = async (formEl) => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      emits("submit", ruleForm)
    }
  })
}

const renderText = () => {
  return renderData.isSending ? renderData.number : "获取验证码"
}

const validateSingleItem = async (ruleFormRef, fieldName) => {
  try {
    await ruleFormRef.value.validateField(fieldName)
  } catch (error) {
    return false // 返回 false 表示校验失败
  }
  return true // 返回 true 表示校验通过
}

function isValidChinesePhoneNumber(phone) {
  const regex = /^1[3-9]\d{9}$/;
  return regex.test(phone);
}

const getVerifyCode = async () => {
  if (renderData.isSending) return
  // const isValidEmail = await validateSingleItem(ruleFormRef, "email")
  // if (!isValidEmail) return
  if(!isValidChinesePhoneNumber(ruleForm.email)) return
  // 调用接口获取验证码
  try {
    const { code, msg, data } = await apiUser.getVerifyCode({ phone: ruleForm.email })
    if (code === 200) {
      renderData.isSending = true
      renderData.btnText = '60'
      let timer = setInterval(() => {
        renderData.number--
        renderData.btnText = renderData.number
        if (renderData.number === 0) {
          renderData.isSending = false
          renderData.number = 60
          renderData.btnText = "重新发送"
          clearInterval(timer)
        }
      }, 1000)
    } else if (code == 3002 || code == 3003) {
      message.error({
        maskClosable: true,
        position: "top",
        content: "发送过于频繁，请稍后再试",
      })
    } else {
      message.error({
        maskClosable: true,
        position: "top",
        content: "获取验证码失败，请稍后重试",
      })
    }
  } catch (error) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "获取验证码失败，请稍后重试",
    })
  }
}

onMounted(() => {})
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 22px;
  .el-form-item__error {
    padding-top: 4px;
  }
  .custom-button {
    width: 100%;
  }
}
.login-cn {
  .login-regist-module-btn {
    width: 100%;
    // height: 48px;
  }
  .btn-verify {
    width: 170px;
  }
  :deep(.btn-verify) {
    margin-bottom: 22px;
    button {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .btn-verify {
      width: 100px;
    }
  }
}
</style>
