<template>
  <div>
    <Dialog
      :customClass="`${createRenderType}-cart-dialog`"
      :mobileRenderType="createRenderType == 'mobile' ? 'drawerDialog' : ''"
      width="678px"
      :height="
        createRenderType == 'mobile'
          ? ''
          : $$language() == 'cn'
          ? '720px'
          : '640px'
      "
      background="transparent"
      :backgroundImage="false"
      :title="
        createRenderType == 'pc'
          ? $$t('store.purchaselicense')
          : type == 'cart'
          ? $$t('store.addCart')
          : $$t('store.Checkout')
      "
      :visible="popupVisible"
      @close="onCloseClick"
    >
      <div :class="`${createRenderType}-authorize-popup flex-column`">
        <div class="item-wrapper">
          <div
            class="flex"
            :class="
              createRenderType == 'pc' ? 'flex-space-between' : 'item-box'
            "
          >
            <div class="other" :class="$$language() == 'cn' ? 'cn-other' : ''"></div>
            <div
              class="item flex-column"
              :class="[
                buyType == 1 ? 'item-active' : '',
                $$language() == 'cn' ? 'cn-item' : '',
              ]"
              @click="buyType = 1"
            >
              <div class="title">{{ $$t("store.nonExclusiveLicense") }}</div>
              <div class="price flex">
                <div class="unit">{{ $$t("store.moneyUnit") }}</div>
                <div class="num">
                  {{ $$t("store.nonExclusiveLicensePrice") }}
                </div>
              </div>
              <template v-if="$$language() == 'cn'">
                <div class="info-cn">
                  <p>您将获得：</p>
                  <p class="th">歌曲mp3、wav音频</p>
                  <p class="th">非独家商用授权书电子版</p>
                </div>
                <div class="applicable-cn">
                  非独家商用授权说明：<br />
                  其他人也可购买该歌曲的商业使用权<br />
                  授权适用于媒体/产品/场景包括：
                </div>
                <div class="scense">
                  <div class="scense-item flex-row-center">
                    电视、广播、应用程序、游戏和电影等
                  </div>
                  <div class="scense-item flex-row-center">
                    线上和线下商业演出
                  </div>
                  <div class="scense-item flex-row-center">网络社交媒体</div>
                </div>
              </template>
              <template v-else>
                <div class="info">
                  The song is non-exclusively licensed to you, others can buy
                  commercial rights too.
                </div>
                <div class="applicable">
                  Applicable to media/products/scenes：
                </div>
                <div class="scense">
                  <div class="scense-item flex-row-center">
                    TV & Radio, Apps, Games & Film, etc.
                  </div>
                  <div class="scense-item flex-row-center">
                    Online and offline commercial performances
                  </div>
                  <div class="scense-item flex-row-center">
                    Unlimited Social Channels Monetized
                  </div>
                </div>
              </template>
            </div>
            <div
              class="item flex-column"
              :class="[
                buyType == 2 ? 'item-active' : '',
                $$language() == 'cn' ? 'cn-item' : '',
              ]"
              @click="buyType = 2"
            >
              <div class="recommend flex-center">
                <span>{{ $$t("store.recommend") }}</span>
              </div>
              <div class="title">{{ $$t("store.exclusiveLicense") }}</div>
              <div class="price flex">
                <div class="unit">{{ $$t("store.moneyUnit") }}</div>
                <div class="num">{{ $$t("store.exclusiveLicensePrice") }}</div>
              </div>
              <template v-if="$$language() == 'cn'">
                <div class="info-cn">
                  <p>您将获得：</p>
                  <p class="th">歌曲mp3、wav、分轨音频</p>
                  <p class="th">独家商用授权书电子版</p>
                </div>
                <div class="applicable-cn">
                  独家商用授权说明：<br />
                  购买后，其他人不可继续购买该歌曲，此前已出售的非独家授权仍然有效<br />
                  授权适用于媒体/产品/场景包括：
                </div>
                <div class="scense">
                  <div class="scense-item flex-row-center">
                    电视、广播、应用程序、游戏和电影等
                  </div>
                  <div class="scense-item flex-row-center">
                    线上和线下商业演出
                  </div>
                  <div class="scense-item flex-row-center">网络社交媒体</div>
                </div>
              </template>
              <template v-else>
                <div class="info">
                  Exclusive authorization for you to use the song.
                </div>
                <div class="applicable">
                  Applicable to media/products/scenes：
                </div>
                <div class="scense">
                  <div class="scense-item flex-row-center">
                    TV & Radio, Apps, Games & Film, etc.
                  </div>
                  <div class="scense-item flex-row-center">
                    Online and offline commercial performances
                  </div>
                  <div class="scense-item flex-row-center">
                    Unlimited Social Channels Monetized
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="flex-center btn-box">
          <div
            v-if="type == 'cart'"
            class="btn flex-center pointer"
            @click="handleConfirmAddCart"
          >
            <img src="@/assets/img/car.png" alt="" />
            <span>{{ $$t("store.addCart") }}</span>
          </div>
          <div
            v-else
            class="btn flex-center pointer"
            @click="handleConfirmCheckout"
          >
            <span>{{ $$t("store.proceedToCheckout") }}</span>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { storeToRefs } from "pinia";
import Dialog from "@/components/basic/dialog/Dialog.vue";
import { $$t, $$language } from "@/i18n/i18n";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const buyType = ref(2);

const props = defineProps({
  // renderType: {
  //   type: String,
  //   default: '',
  // },
  visible: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "cart",
  },
  confirmAdd: {
    type: Function, // 添加购物车的方法
    default: null,
  },
  confirmCancel: {
    type: Function, // 添加购物车的方法
    default: null,
  },
  confirmCheckout: {
    type: Function, // 购买的方法
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  handleSuccessAdd: {
    type: Function, // 添加购物车成功的回掉
    default: null,
  },
});

const popupVisible = ref(props.visible);

watch(
  () => popupVisible.value,
  (val) => {
    !val && props.remove();
  }
);

const onCloseClick = () => {
  props.confirmCancel();
  popupVisible.value = false;
};

// 添加购物车
const handleConfirmAddCart = async () => {
  await props.confirmAdd(buyType.value);
  popupVisible.value = false;
};

// 购买
const handleConfirmCheckout = async () => {
  await props.confirmCheckout(buyType.value);
  popupVisible.value = false;
};
</script>

<style lang="scss">
.cart-dialog {
  .dialog-header {
    font-size: 32px;
    line-height: 32px;
  }
}
</style>

<style lang="scss" scoped>
.pc-authorize-popup {
  box-sizing: border-box;
  font-family: HarmonyOS Sans SC;
  margin-top: 44px;
  color: transparent;
  .other {
    display: none;
  }
  .item {
    position: relative;
    width: 320px;
    height: 424px;
    background: rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    padding: 40px 20px 44px;
    border-radius: 13px;
    backdrop-filter: blur(20px);
    color: #fff;
    border: 2px solid transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
    .recommend {
      position: absolute;
      top: -2px;
      right: -2px;
      border-radius: 0px 12px 0px 14px;
      width: 82px;
      height: 28px;
      background: rgba(255, 255, 255, 0.2);
      span {
        background: linear-gradient(112deg, #4fdeff 0%, #e675ff 100%);
        background-clip: text;
        font-size: 10px;
        font-weight: 500;
        color: transparent;
        // line-height: 24px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      background: linear-gradient(98deg, #4fdeff 1%, #e675ff 100%);
      background-clip: text;
      color: transparent;
    }
    .price {
      margin-top: 40px;
      font-size: 40px;
      font-weight: 900;
      line-height: 40px;
      .unit {
        font-size: 16px;
        line-height: 40px;
        margin-right: 6px;
      }
    }
    &-active {
      border-color: #94adff;
    }

    .info {
      margin-top: 20px;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: #fff;
    }
    .info-cn {
      margin-top: 20px;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: #fff;
      .th {
        opacity: 0.8;
      }
    }
    .applicable {
      margin-top: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      padding-bottom: 8px;
    }
    .applicable-cn {
      margin-top: 14px;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      opacity: 0.6;
      color: #fff;
      padding-bottom: 2px;
    }
    .scense {
      color: #fff;
      opacity: 0.6;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      .scense-item {
        margin-top: 5px;
        &::before {
          margin-right: 8px;
          content: "";
          width: 16px;
          height: 16px;
          background: url(@/assets/img/check.png);
          background-size: 100% 100%;
        }
      }
    }
  }
  .btn-box {
    margin-top: 44px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .btn {
      width: max-content;
      padding: 12px 98px;
      background: linear-gradient(97deg, #4fdeff 0%, #e675ff 100%);
      font-size: 16px;
      font-weight: 500;
      color: #000;
      border-radius: 60px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.mobile-authorize-popup {
  overflow-x: hidden;
  padding-top: 17px;
  .item-wrapper {
    overflow-x: auto;
  }
  .item-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .item {
    flex-shrink: 0;
    margin-left: 16px;
    position: relative;
    width: 260px;
    height: 306px;
    background: rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    padding: 24px 16px 0;
    border-radius: 13px;
    backdrop-filter: blur(20px);
    color: #fff;
    border: 1px solid transparent;
    &-active {
      border-color: #94adff;
    }
    .recommend {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 0px 12px 0px 14px;
      width: 82px;
      height: 28px;
      background: rgba(148, 173, 255, 0.3);
      span {
        background: linear-gradient(112deg, #4fdeff 0%, #e675ff 100%);
        background-clip: text;
        font-size: 10px;
        font-weight: 500;
        color: transparent;
      }
    }
    .title {
      width: max-content;
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      background: linear-gradient(95deg, #4fdeff 0%, #e675ff 100%);
      background-clip: text;
      color: transparent;
    }
    .price {
      width: max-content;
      margin-top: 24px;
      font-size: 28px;
      font-weight: 900;
      line-height: 40px;
      background: linear-gradient(123deg, #4fdeff 0%, #e675ff 100%);
      background-clip: text;
      color: transparent;
      .unit {
        font-size: 16px;
        line-height: 40px;
        margin-right: 6px;
      }
    }
    .info {
      margin-top: 16px;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #fff;
    }
    .info-cn {
      margin-top: 20px;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: #fff;
      .th {
        opacity: 0.8;
      }
    }
    .applicable {
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      padding-bottom: 8px;
    }
    .applicable-cn {
      margin-top: 14px;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      opacity: 0.6;
      color: #fff;
      padding-bottom: 2px;
    }
    .scense {
      color: #fff;
      opacity: 0.6;
      font-size: 11px;
      font-weight: normal;
      line-height: 16px;
      .scense-item {
        margin-top: 1px;
        &::before {
          margin-right: 8px;
          content: "";
          width: 16px;
          height: 16px;
          background: url(@/assets/img/check.png);
          background-size: 100% 100%;
        }
      }
    }
  }
  .other {
    flex-shrink: 0;
    width: 3px !important;
    height: 306px;
  }
  .cn-other {
    width: 4px;
    height: 350px;
  }
  .cn-item {
    height: 350px;
  }
  .btn-box {
    margin: 24px 16px 16px;
    img {
      display: none;
    }
    .btn {
      width: 100%;
      // padding: 12px 98px;
      height: 44px;
      background: linear-gradient(97deg, #4fdeff 0%, #e675ff 100%);
      font-size: 16px;
      font-weight: 500;
      color: #000;
      border-radius: 60px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>