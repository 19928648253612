import { createRouter, createWebHistory } from "vue-router";
import { isDev } from "@/utils/tools.js";
import useUserDataStore from "@/store/userDataStore";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import message from "@/components/functionCallComponent/message.jsx";
import layoutConfig from "@/config/layoutConfig"
export const loginRoutes = [];
export const noAuthenticationRoutes = ["create"];
import { $$language, $$t } from "@/i18n/i18n.js";


export const isNoAuthenticatedToLogin = () => {
  const userDataStore = useUserDataStore();
  const regs = [...noAuthenticationRoutes, ...loginRoutes]
    .map((item) => item?.split("/").pop().toLocaleLowerCase())
    .join("|");
  if (
    (window.location.pathname == "/" ||
      !new RegExp(`(${regs})$`).test(
        window.location.pathname.split("/").pop().toLocaleLowerCase()
      )) &&
    !userDataStore.isLogin
  ) {
    return true;
  }
  return false;
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      // 首页
      path: `/`,
      // name: "home",
      // component: () => import("@/page/home/home.vue"),
      redirect: "/create",
    },
    // 宣传页
    {
      path: "/home",
      name: "home",
      component: () => import("@/page/land/landing.vue"),
      meta: {
        title: "home",
        enTitle: $$t("common.nav_home", "en"),
        cnTitle: $$t("common.nav_home", "cn"),
      },
    },
    {
      path: `/mine`,
      name: "mine",
      component: () => import("@/mobile/mine/mine.vue"),
    },
    {
      path: "/disclaimer",
      name: "disclaimer",
      component: () => import("@/page/items/disclaimer.vue"),
    },
    {
      path: `/nav`,
      name: "main",
      component: () => import("@/page/main/main.vue"),
      children: [
        {
          // 创作
          path: `/create`,
          name: "create",
          component: () => import("@/page/create/create.vue"),
          meta: {
            title: "create",
            enTitle: $$t("common.nav_create", "en"),
            cnTitle: $$t("common.nav_create", "cn"),
          },
        },
        {
          // 天空工坊
          path: `/store`,
          name: "store",
          component: () => import("@/page/store/store.vue"),
          meta: {
            title: "store",
            enTitle: $$t("common.nav_store", "en"),
            cnTitle: $$t("common.nav_store", "cn"),

            keepAlive: true,
          },
        },
        {
          // 商详
          path: `/store-detail`,
          name: "storeDetail",
          component: () => import("@/page/home/detail.vue"),
          meta: {
            // title: "store",
            enTitle: $$t("common.nav_detail", "en"),
            cnTitle: $$t("common.nav_detail", "cn"),
            skipNext: true,
          },
        },
        {
          // 商详
          path: `/create-detail`,
          name: "createDetail",
          component: () => import("@/page/home/detail.vue"),
          meta: {
            // title: "store",
            enTitle: $$t("common.nav_detail", "en"),
            cnTitle: $$t("common.nav_detail", "cn"),
            skipNext: true,
          },
        },
        {
          // 天空工坊
          path: `/store-cart`,
          name: "storeCart",
          component: () => import("@/page/cart/cart.vue"),
          meta: {
            full: true,
            title: "store",
            enTitle: $$t("common.nav_store", "en"),
            cnTitle: $$t("common.nav_store", "cn"),
          },
        },
        {
          // 天空工坊
          path: `/store-purchase`,
          name: "storePurchase",
          component: () => import("@/page/purchase/purchase.vue"),
          meta: {
            full: true,
            title: "store",
            enTitle: $$t("common.nav_store", "en"),
            cnTitle: $$t("common.nav_store", "cn"),
            hidePlayer: true,
          },
        },
        {
          // 天空工坊
          path: `/credits`,
          name: "credits",
          component: () => import("@/page/credits/credits.vue"),
          meta: {
            full: true,
            title: "credits",
            enTitle: $$t("common.nav_credits", "en"),
            cnTitle: $$t("common.nav_credits", "cn"),
          },
        },
        {
          // 收藏
          path: `/library`,
          name: "library",
          component: () => import("@/page/library/library.vue"),
          meta: {
            title: "library",
            enTitle: $$t("common.nav_library", "en"),
            cnTitle: $$t("common.nav_library", "cn"),
            // keepAlive: true,
          },
        },
        {
          // 喜欢的
          path: `/liked`,
          name: "liked",
          component: () => import("@/page/library/liked.vue"),
          meta: {
            full: true,
            title: "library",
            enTitle: $$t("common.nav_library", "en"),
            cnTitle: $$t("common.nav_library", "cn"),
            // keepAlive: true,
          },
        },
        {
          // 售卖中
          path: `/sale`,
          name: "sale",
          component: () => import("@/page/library/sale.vue"),
          meta: {
            full: true,
            title: "library",
            enTitle: $$t("common.nav_library", "en"),
            cnTitle: $$t("common.nav_library", "cn"),
            // keepAlive: true,
          },
        },
        {
          // 售出
          path: `/sald`,
          name: "sald",
          component: () => import("@/page/library/saled.vue"),
          meta: {
            full: true,
            title: "library",
            enTitle: $$t("common.nav_library", "en"),
            cnTitle: $$t("common.nav_library", "cn"),
            hidePlayer: true,
            // keepAlive: true,
          },
        },
        {
          // 订单
          path: `/order`,
          name: "order",
          component: () => import("@/page/library/order.vue"),
          meta: {
            full: true,
            // title:'library',
            enTitle: $$t("common.order", "en"),
            cnTitle: $$t("common.order", "cn"),
            // keepAlive: true,
          },
        },
        {
          // 订单详情
          path: `/order-detail`,
          name: "orderDetail",
          component: () => import("@/page/library/order-detail.vue"),
          meta: {
            // title:'library',
            full: true,
            enTitle: $$t("common.orderdetail", "en"),
            cnTitle: $$t("common.orderdetail", "cn"),
            // keepAlive: true,
          },
        },
        {
          // 购买歌曲订单成功
          path: `/result-song`,
          name: "resultSong",
          component: () => import("@/page/result/song.vue"),
          meta: {
            full: true,
            title: "",
          },
        },
        {
          // 购买积分订单成功
          path: `/result-credit`,
          name: "resultCredit",
          component: () => import("@/page/result/credit.vue"),
          meta: {
            full: true,
            title: "",
          },
        },
        {
          // 购买结果loading页面
          path: `/result-wait`,
          name: "resultWait",
          component: () => import("@/page/result/wait.vue"),
          meta: {
            full: true,
            title: "",
          },
        },
        {
          // 购买结果loading页面
          path: `/result-fail`,
          name: "resultFail",
          component: () => import("@/page/result/fail.vue"),
          meta: {
            title: "",
          },
        },
      ],
    },
    {
      path: `/reset-password`,
      name: "resetPassword",
      component: () => import("@/page/login/resetPassword.vue"),
    },
    {
      path: `/mailVerification`,
      name: "mailVerification",
      component: () => import("@/page/login/mailVerification.vue"),
    },
    {
      path: `/privacy`,
      name: "privacy",
      component:
        $$language() == "en"
          ? () => import("@/page/privacy.vue")
          : () => import("@/page/privacyCn.vue"),
    },
    {
      path: `/terms`,
      name: "terms",
      component: () => import("@/page/terms/terms.vue"),
    },
    // 移动端各人中心
    {
      path: `/paid-service`,
      name: "paidService",
      component: () => import("@/page/paidService.vue"),
    },
    ...(isDev()
      ? [
          {
            // 测试组件页面
            path: `/test`,
            name: "test",
            component: () => import("@/page/test/test.vue"),
          },
          {
            // 录制测试
            path: `/test1`,
            name: "test1",
            component: () => import("@/page/test/test1.vue"),
          },
          {
            // wave.js
            path: `/test2`,
            name: "test2",
            component: () => import("@/page/test/test2.vue"),
          },
          {
            // wave.js
            path: `/test3`,
            name: "test3",
            component: () => import("@/page/test/test3.vue"),
          },
          {
            // wave.js
            path: `/createt`,
            name: "createt",
            component: () => import("@/page/test/testCreate.vue"),
          },
        ]
      : []),
  ],
});

// 在应用的状态中跟踪导航历史
export let navigationHistory = [];

// 监听路由变化
router.beforeEach((to, from, next) => {
  // message.clear();
  to.meta.from = from.fullPath;
  // 更新导航历史
  next();
});

// 在全局导航守卫中检查是否是最后一个页面
// router.afterEach((to, from) => {
//   // 检查是否是最后一个页面
//   console.log("navigationHistory2", navigationHistory)
//   if (to.path !== "/" && to.path !== "/skywork") {
//     if (navigationHistory.length === 0 || to.path !== navigationHistory[navigationHistory.length - 1]) {
//       navigationHistory.push(to.path)
//     }
//   }
// })

export default router;
