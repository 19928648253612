import axiosIS from '@/api/net/axios.js';
import ApiConfig,{ baseUrl} from '@/config/apiConfig.js';

// 商城歌曲列表
export function getShopSongs(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/songs`,
    data,
    { method: 'post' }
  );
}

// 歌曲详情
export function getSongsDetail (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/song/detail`,
    data,
    { method: 'get' }
  );
}

// 歌曲点赞
export function favoriteSong (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/user/song/favorite`,
    data,
    { method: 'post' }
  );
}

// 购物车列表
export function getCartList (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/list`,
    data,
    { method: 'get' }
  );
}

// 购物车结算
export function checkoutCart(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/checkout`,
    data,
    { method: 'post' }
  );
}

// 从购物车删除
export function deleteCart(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/delete`,
    data,
    { method: 'post' }
  );
}

// 购物车总数
export function getCartCount (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/count`,
    data,
    { method: 'get' }
  );
}

// 单个结算
export function shopCheckout (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/checkout`,
    data,
    { method: 'post' }
  );
}

// 分享获取shareKey
export function reportShare (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/share/report`,
    data,
    { method: 'post' }
  );
}