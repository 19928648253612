import { defineStore } from "pinia";
import { $$language } from "@/i18n/i18n.js"
import { isMobileDevice } from "@/utils/tools";
import { getShopSongs, getCartCount, reportShare, shopCheckout } from "@/api/api/apiStore.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import message from "@/components/functionCallComponent/message/message.js";
import ShowAuthorizeDialog from "@/components/authorizeDialog/authorizeDialog.js";
import ShowCartPreviewDialog from "@/components/cartPreviewDialog/cartPreviewDialog.js";
import { addCart } from "@/api/api/apiCommon.js";
import router from "@/router/index.js"
import {
  checkoutCart
} from "@/api/api/apiStore.js";
import useCartListStore from "@/store/cart/cartListDataStore.js"
import { $$t } from "@/i18n/i18n";

const useStoreData = defineStore({
  id: "shopCartStoreData", 
  state: () => {
    return {
      scrollHeight: 0,
      storeRnderData: {
        list: [],
        refreshing: false,
        loadingMore: false,
        isLoading: false,
        loadError: false,
        finished: false,
      },
      storeQueryLastId: 0,
      storeTotal: 0, // 商城首页总条数
      shopCartNum: 0
    };
  },
  actions: {
    resetShopNum() {
      this.shopCartNum = 0
    },
    // 格式化数据
    formatData() {
      const list = this.storeRnderData.list.map(item => {
        return {
          ...item,
          mp3_url: item.song_url
        }
      })
      return list
    },
    addPlayList() {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup('storeList');
      globalPlayDataStore.playlist = [];
      globalPlayDataStore.addPlayDatas(this.formatData());
    },
    togglePlay(item) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup('storeList')
      globalPlayDataStore.setPlayDatas(this.formatData(), {
        ...item,
        mp3_url: item.song_url
      })
    },

    // 下拉刷新
    doRefresh(done, query) {
      this.storeRnderData.isLoading = true;
      this.storeQueryLastId = 0
      getShopSongs({
        ...query,
        last_id: this.storeQueryLastId
      }).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          this.storeTotal = data?.total || 0
          if (!data.list) {
            done(this.storeRnderData, true);
          } else {
            this.storeRnderData.list = data.list;
            this.storeQueryLastId = data?.last_id ? data?.last_id : 0;
            done(this.storeRnderData, false);
          }
        } else {
          this.storeRnderData.loadError = true;
        }
      });
    },

    // 上拉加载
    doLoadMore(done, query) {
      this.storeRnderData.isLoading = true;
      getShopSongs({
        ...query,
        last_id: this.storeQueryLastId
      })
        .then((res) => {
          if (res.code == 200) {
            const globalPlayDataStore = useGlobalPlayDataStore();
            this.storeRnderData.loadError = false;
            const { data } = res;
            // this.storeTotal = data.total  ? data.total : this.storeQueryLastId ? this.storeTotal : 0
            if (!data.list) {
              done(this.storeRnderData, true);
            } else {
              this.storeRnderData.list = [...this.storeRnderData.list, ...data.list];
              globalPlayDataStore.addPlayDatas(this.formatData())
              this.storeQueryLastId = data?.last_id ? data?.last_id : 0;
              done(this.storeRnderData, false);
            }
          } else {
            this.storeRnderData.loadError = true;
          }
        })
        .finally(() => { });
    },

    // 重新加载
    doRetry(query, callback, finallyCallback) {
      this.storeRnderData.isLoading = true;
      this.storeQueryLastId = 0
      getShopSongs({
        ...query,
        last_id: this.storeQueryLastId
      }).then((res) => {
        if (res.code == "200") {
          callback && callback()
          this.storeRnderData.isLoading = false;
          this.storeRnderData.loadError = false;
          const { data } = res;
          if (!data.list) {
            this.storeRnderData.finished = true;
            this.storeRnderData.list = []
          } else {
            this.storeRnderData.list = data.list;
            this.storeQueryLastId = data?.last_id ? data?.last_id : 0;
            this.storeRnderData.finished = false;
          }
          this.storeTotal = data?.total || 0

        } else {
          this.storeTotal = 0
          this.storeRnderData.isLoading = false;
          this.storeRnderData.loadError = true;
        }
      }).finally(() => {
        finallyCallback && finallyCallback()
      });
    },

    // 删除列表数据
    deleteShopList(id) {
      let index = this.storeRnderData.list.findIndex(
        (itemF) => itemF.id == id
      );
      if (index > -1) {
        this.storeRnderData.list.splice(index, 1)
      }
    },

    // 获取购物车总数
    getCartTotalCount() {
      getCartCount({}).then(res => {
        if (res.code == 200) {
          this.shopCartNum = res?.data?.count || 0
        }
      })
    },

    // 更新滚动高度
    updateScrollTop(height) {
      this.scrollHeight = height
    },

    // 给全局播放器提供的点赞更新状态
    updateShopLike(song_id, favorite_state) {
      let index = this.storeRnderData.list.findIndex(
        (itemF) => itemF.song_id === song_id
      );
      if (index > -1) {
        const item = this.storeRnderData.list[index]
        Object.assign(this.storeRnderData.list[index], {
          ...item,
          favorite_state
        });
        this.storeRnderData.list = [].concat(this.storeRnderData.list);
      }
    },

    // 给全局播放器提供的购物车更新状态
    updateShopCart(song_id, cart_state) {
      console.log(song_id, "updateShopCartupdateShopCart")
      let index = this.storeRnderData.list.findIndex(
        (itemF) => itemF.song_id === song_id
      );
      console.log(index, "indexindexindex")
      if (index > -1) {
        const item = this.storeRnderData.list[index]
        Object.assign(this.storeRnderData.list[index], {
          ...item,
          cart_state
        });
        this.storeRnderData.list = [].concat(this.storeRnderData.list);
      }
    },

    // 给全局播放器提供的删除方法
    deleteShopItem(song_id) {
      let index = this.storeRnderData.list.findIndex(
        (itemF) => itemF.song_id == song_id
      );
      if (index > -1) {
        this.storeRnderData.list.splice(index, 1)
      }
    },

    // 分享获取shareKey
    getShareKey(song_id, song_link) {
      return new Promise((resolve, reject) => {
        reportShare({
          song_id,
          song_link
        }).then(res => {
          resolve(res)
        })
      })
    },

    /*
     * 选中的购买项
     * 1.需要去更改商城首页购物车状态cart_state为不在购物车
     * 2.更改全局播放器的状态不在购物车
     * 3.如果买的是独家需要在商城首页删掉并且改变全局播放器售卖状态
     * 4.买的是非独家的话需要更改order_state提示不能再次购买非独家
     */

    changeShopList(list) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      list.forEach((item) => {
        if (item.cart_state != 2) {
          this.updateShopCart(item.song.song_id, 3);
          globalPlayDataStore.updatePlayDataItem({
            ...item.song,
            cart_state: 3,
          });
          if (item.buy_type != 1) {
            this.deleteShopList(item.song.id);
            globalPlayDataStore.updatePlayDataItem({
              ...item.song,
              sold_state: 4,
              cart_state: 3,
            });
          } else {
            this.updateShopOrderState(item.song.song_id, 1);
            globalPlayDataStore.updatePlayDataItem({
              ...item.song,
              order_state: 1,
              cart_state: 3,
            });
          }
        }
      });
    },



    // 购物车预览结算
    checkout(list) {
      return new Promise(resolve => {
        const val = list.map((item) => {
          if (item.cart_state != 2) {
            return item.song.id
          }
        });
        checkoutCart({
          lib_song_ids: val,
        }).then((res) => {
          resolve(res)
          if (res.code == 200) {
            this.changeShopList(list);
            // 需要更新播放列表的数据
            this.getCartTotalCount();
            const { order_id, invalid_lib_song_ids } = res.data;
            if (invalid_lib_song_ids) {
              invalid_lib_song_ids.forEach(id => {
                this.deleteShopList(id);
              })
              message.warning({
                maskClosable: true,
                content: $$t('store.songRemoveTip'),
                position: "top",
              });
              // 需要删除掉这些被卖出的
            }
            if (order_id) {
              router.push({
                name: "storePurchase",
                query: {
                  orderId: order_id,
                },
              });
            }
          }
        });
      })
    },

    // 确认添加
    confirmAdd(buyType, data, resolve) {
      const createLayoutStore = useCreateLayoutStore();
      if (data.order_state == 1 && buyType == 1) {
        message.warning({
          maskClosable: true,
          content: $$t('store.bugNonExclusiveTip'),
          position: "top",
        });
        resolve('')
        return;
      }
      const cartListStore = useCartListStore()
      const { id } = data;
      addCart({
        lib_song_id: id,
        buy_type: buyType,
      }).then((res) => {
        if (res.code == 200) {
          resolve({
            ...res,
            buyType
          })
          cartListStore.addIntoCartList(res.data.item) // 这里需要添加一条进购物车
          this.getCartTotalCount();
          if (createLayoutStore.createRenderType == 'pc') {
            ShowCartPreviewDialog({
              checkout: this.checkout,
            });
          } else {
            message.success({
              maskClosable: true,
              content: $$t('store.addedToCart'),
              position: "top",
            });
          }

        } else if (res.code == 6304) {
          message.warning({
            maskClosable: true,
            content: $$t('store.cartMaxtip'),
            position: "top",
          });
          resolve(res)
        } else if (res.code == 6305) {
          message.warning({
            maskClosable: true,
            content: $$t('store.soldOutTip'),
            position: "top",
          });
          resolve(res)
        } else if (res.code == 6306) {
          message.warning({
            maskClosable: true,
            content: $$t('store.suspendedTip'),
            position: "top",
          });
          resolve(res)
        }
      });
    },

    // 添加购物车
    doAddCart(data) {
      return new Promise((resolve, reject) => {
        ShowAuthorizeDialog({
          type: "cart",
          confirmCancel: () => { resolve('') },
          confirmAdd: (buyType) => {
            this.confirmAdd(buyType, data, resolve, reject)
          }
        });
      })
    },

    // 确认购买
    confirmCheckout(buyType, data, resolve) {
      if (data.order_state == 1 && buyType == 1) {
        message.warning({
          maskClosable: true,
          content: $$t('store.bugNonExclusiveTip'),
          position: "top",
        });
        resolve('')
        return;
      }
      const { id } = data;
      shopCheckout({
        lib_song_id: id,
        buy_type: buyType,
        channel: $$language() == 'en' ? 'shareit' : isMobileDevice() ? 'wechat_h5' : 'wechat_native'
      }).then((res) => {
        resolve({
          ...res,
          buyType
        })
        if (res.code == 200) {
          this.getCartTotalCount();
          const { order_id, invalid_lib_song_ids } = res.data;
          if (invalid_lib_song_ids) {
            message.warning({
              maskClosable: true,
              content: $$t('store.songRemoveTip'),
              position: "top",
            });
          }
          if (order_id) {
            router.push({
              name: "storePurchase",
              query: {
                orderId: order_id,
              },
            });
          }
        } else if (res.code == 6305) {
          message.warning({
            maskClosable: true,
            content: $$t('store.soldOutTip'),
            position: "top",
          });
        } else if (res.code == 6306) {
          message.warning({
            maskClosable: true,
            content: $$t('store.suspendedTip'),
            position: "top",
          });
        } else if (res.code == 9203) {
          message.error({
            content: "Payment is not available in your country/region",
          });
        }
      });
    },

    // 单个购买
    doCheckout(data) {
      return new Promise((resolve, reject) => {
        ShowAuthorizeDialog({
          type: "checkout",
          confirmCancel: () => { resolve('') },
          confirmCheckout: (buyType) => {
            this.confirmCheckout(buyType, data, resolve, reject)
          }
        });
      })
    },

    // 更新列表中的order_state状态
    updateShopOrderState(song_id, order_state) {
      let index = this.storeRnderData.list.findIndex(
        (itemF) => itemF.song_id === song_id
      );
      if (index > -1) {
        const item = this.storeRnderData.list[index]
        Object.assign(this.storeRnderData.list[index], {
          ...item,
          order_state
        });
        this.storeRnderData.list = [].concat(this.storeRnderData.list);
      }
    }
  }
})


export default useStoreData;