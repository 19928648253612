import { defineStore } from "pinia";
import { getSongsDetail } from "@/api/api/apiStore.js";
import { $$t, $$language } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";

const useStoreDetailData = defineStore({
  id: "storeDetailStoreData",
  state: () => {
    return {
      info: {},
    };
  },
  actions: {
    getInfo(query) {
      return new Promise((resolve) => {
        getSongsDetail(query).then((res) => {
          if (res.code == 200) {
            if (res?.data?.song) {
              res.data.song.mp3_url =
                res.data.song.mp3_url + "?type=song_detail";
            }
            if (res?.data?.song?.song_url) {
              res.data.song.song_url =
                res.data.song.song_url + "?type=song_detail";
            }
            // this.info = res?.data?.song;
          }else if (res.code == 6320){
            message.error({
              maskClosable: true,
              position: "top",
              content: $$t("common.deleted"),
            });
          }
          resolve(res);
        });
      });
    },
    updateDetail(data) {
      this.info = data;
    },
  },
});

export default useStoreDetailData;
