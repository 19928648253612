import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";

import useDraftDataStore from "./draftDataStore.js";

const useLyricDataStore = defineStore({
  id: "lyricDataStore",
  state: () => {
    return {
      title: "",
      lyric: "",
      lyricData: [],
      isLoading: false,
      curGenerateType: "",
      initTimescamp: 0,
    };
  },
  actions: {
    initLyricData(data, isReset = false) {
      this.title = data?.title ?? "";
      this.lyric = data?.lyrics ?? "";
      if (isReset) {
        nextTick(() => {
          this.initTimescamp = new Date().getTime();
        });
      }
    },
    updateTitle(data) {
      this.title = data;
    },
    updateLyric(data) {
      this.lyric = data;
    },
    saveLyric() {
      const draftDataStore = useDraftDataStore();
      feedListApi
        .saveLyrics({
          title: this.title,
          lyrics: this.lyric,
          feed_id: draftDataStore.feed_id,
        })
        .then((res) => {
          const { code, data, msg } = res;
          if (code == 200) {
          }
        });
    },
    updateLyricCount(count) {
      this.lyricCharCount = count;
    },
    async getLyricData(data) {
      return {
        title: !this.title?.replace(/s+/g, "") ? null : this.title,
        lyrics: this.lyric,
      };
    },
  },
});
export default useLyricDataStore;
// export const LyricDataStore = useLyricDataStore()
