import { fetchEventSource } from "fetch-event-source-sse"
import { getPublicHeader } from "./config.js"

const sseRequest = async (args) => {
  const {method, url, params, onMessage, onError, onOpen, onClose } = args;
  const controller = new AbortController()
  try{
    fetchEventSource(url, {
      method: method ?? "POST",
      timeout: 60*1000*60,
      openWhenHidden: true,
      headers: {
        ...(await getPublicHeader()),
      },
      body: method=="GET" ? null : JSON.stringify({
        ...params,
      }),
      signal: controller.signal,
      withCredentials: true,
      async onopen(response) {
        console.log('sseRequest_onopen', response);
        onOpen && onOpen(response)
      },
      onmessage(msg) {
        console.log('sseRequest_onmessage', msg);
        onMessage(msg)
      },
      onclose(e) {
        console.log('sseRequest_onclose',e);
        onClose && onClose()
      },
      onerror(err) {
        console.log('sseRequest_onerror', err);
        onError && onError(err)
      },
    })
  }catch(e){
    console.log('sseRequest_call_error', e);
  }
  
  return {
    abort(){
      controller.abort()
    },
    retry(){
      return sseRequest(args)
    }
  };
}

export default sseRequest