<template>
  <div class="report-container flex-center pointer" ref="reportRef">
    <img
      src="@/assets/img/more-ico.png"
      class="report-image"
      @mouseover="showPopup"
      @mouseout="hidePopup"
    />
    <div
      class="report-popup"
      :class="`report-popup-${props.position || position}`"
      @mouseover="cancelHide"
      @mouseout="hidePopup"
    >
      <div class="popup-box">
        <div class="item pointer" @click="handleReport">举报</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import useUserDataStore from "@/store/userDataStore"
import { nextTick } from "process";

const props = defineProps({
  position: {
    type: String,
  },
})

const userDataStore = useUserDataStore()
const emits = defineEmits(['handleReport'])
const reportRef = ref(null);
const isPopupVisible = ref(false);
const position = ref("bottom");

let hideTimer = null;
const showPopup = () => {
  if (reportRef.value) {
    const { top, height } = reportRef.value.getBoundingClientRect();
    if (document.body.clientHeight - top > 160) {
      position.value = "bottom";
    } else {
      position.value = "top";
    }
  }
  clearTimeout(hideTimer);
  console.log('showPopup', position.value);
  isPopupVisible.value = true;
};

const hidePopup = () => {
  isPopupVisible.value = false;
  clearTimeout(hideTimer);
};

const cancelHide = () => {
  clearTimeout(hideTimer);
};


const handleReport = async () => {
  if (!(await userDataStore.checkLoginStatus())) {
    return;
  }
  hidePopup();
  emits('handleReport')
};

</script>

<style lang="scss" scoped>
.report-container {
  position: relative;
  width: 36px;
  height: 36px;

  .report-image {
    width: 20px;
    height: 20px;
  }
  .report-popup {
    z-index: 100;
    position: absolute;
    display: none;
    border-radius: 8px;
    width: 120px;
    &-top {
      bottom: 20px;
      right: 0px;
      padding: 0px 0 22px;
    }
    &-bottom {
      right: 0px;
      top: 20px;
      padding: 22px 0 0;
    }
    .popup-box {
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 8px;
      width: 120px;
      height: 36px;
      .item {
        padding-left: 20px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        padding-left: 20px;
        border-radius: 8px;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .report-image:hover + .report-popup,
  .report-popup:hover {
    display: block;
  }
}

</style>