<template>
  <div class="custom-record">
    <div class="custom-record-wave-box">
      <div
        class="ctrlProcessWave"
        :style="{ display: renderData.isRecording ? 'block' : 'none' }"
      >
        <!-- <canvas id="waveformCanvas" style="width: 100%; height: 44px"></canvas> -->
      </div>
      <div
        v-if="!renderData.isRecording"
        class="player-wave-box"
        ref="waveformRef"
      ></div>
    </div>
    <div class="custom-record-time tc">
      {{
        secondsToFormattedTime(renderData.isRecording ? duration : currentTime)
      }}
    </div>
    <div class="custom-record-op tc">
      <div
        class="fn-inline custom-record-op-btn"
        v-if="!renderData.isRecording"
      >
        <IconButton
          size="40px"
          icoSize="20px"
          backgroundColor="rgba(255,255,255,0.2)"
          :disable="renderData.isRecording"
          :style="{ opacity: 0 }"
        >
          <img src="../../../assets/img/refresh-ico.png" />
        </IconButton>
      </div>
      <div class="fn-inline custom-record-op-toogle-record-box">
        <div
          class="custom-record-op-toogle-record flex-center"
          @click="onToggleRecordClick"
        >
          <i
            class="block"
            :class="{
              gray: mathGray(),
              'record-off': renderData.isRecording,
              'record-on': !renderData.isRecording,
            }"
          ></i>
        </div>
      </div>
      <div
        class="fn-inline custom-record-op-btn"
        v-if="!renderData.isRecording"
      >
        <IconButton
          :style="{ opacity: audioURL ? 1 : 0 }"
          size="40px"
          icoSize="20px"
          backgroundColor="rgba(255,255,255,0.1)"
          hoverBackgroundColor="rgba(255,255,255,0.2)"
          :disable="renderData.isRecording"
          @onClick="onTogglePlayClick"
        >
          <img
            v-if="!renderData.isPlaying"
            src="../../../assets/img/play-light-ico.png"
          />
          <img
            v-if="renderData.isPlaying"
            src="../../../assets/img/pause-light-ico.png"
          />
        </IconButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  onMounted,
} from "vue";
import { showActionDialog } from "@/components/functionCallComponent/action/action.js";
import { useRouter, useRoute } from "vue-router";
import IconButton from "@/components/basic/button/IconButton.vue";
import WaveSurfer from "wavesurfer.js";
import { getMediaFileDuration } from "@/utils/tools";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import message from "@/components/functionCallComponent/message/message.js";

import { $$t } from "@/i18n/i18n.js";

import Recorder from "recorder-core";

import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
import "recorder-core/src/extensions/waveview";
let rec = Recorder;
let wave;

const Router = useRouter();
const route = useRoute();

const props = defineProps({
  type: {
    type: String,
    default: "motif",
  },
});
const emits = defineEmits(["tooggleRecord"]);

let wavesurfer = null;

const renderData = reactive({
  isRecording: false,
  isPlaying: false,
  isTempoOn: false, // 节拍器
});
const waveformRef = ref(null);

const duration = ref(0);
const currentTime = ref(0);
const gray = ref(false);

const chunks = ref([]);
const audioURL = ref("");
const isEnding = ref(false);
let file = null;
let stream = null;
let timer = null;

const clearElement = () => {
  // 确保元素存在
  if (waveformRef.value) {
    waveformRef.value.innerHTML = ""; // 清空所有子元素
  }
};

const secondsToFormattedTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};

const initPlayer = (url) => {
  clearElement();
  wavesurfer = WaveSurfer.create({
    height: 42,
    container: waveformRef.value,
    waveColor: "#FFFFFF",
    progressColor: "#94ADFF",
    url: url,
    barWidth: 2,
    barGap: 1,
    barRadius: 2,
  });
  wavesurfer.on("play", () => {
    // 更新播放时间的函数
    renderData.isPlaying = true;
    const updateCurrentTime = () => {
      currentTime.value = wavesurfer.getCurrentTime();
      // console.log(currentTime.value)
      // 如果wavesurfer还在播放，则继续更新
      if (wavesurfer.isPlaying()) {
        requestAnimationFrame(updateCurrentTime);
      }
    };

    // 开始更新播放时间
    requestAnimationFrame(updateCurrentTime);
  });

  wavesurfer.on("click", (e) => {
    currentTime.value = wavesurfer.getCurrentTime();
  });

  wavesurfer.on("pause", () => {
    renderData.isPlaying = false;
  });

  wavesurfer.on("finish", () => {
    renderData.isPlaying = false;
  });
};

// 回调音频文件
const getRecord = async () => {
  const timing = await getMediaFileDuration(file);
  console.log("timing", timing);
  return {
    url: audioURL.value,
    file: file,
    duration: timing,
  };
};
// 第二步：暴露方法

const recOpen = async (success) => {
  console.log("开启录音---->");

  //一般在显示出录音按钮或相关的录音界面时进行此方法调用，后面用户点击开始录音时就能畅通无阻了
  rec = Recorder({
    //本配置参数请参考下面的文档，有详细介绍
    type: "mp3",
    sampleRate: 16000,
    bitRate: 16, //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
    onProcess: function (
      buffers,
      powerLevel,
      bufferDuration,
      bufferSampleRate,
      newBufferIdx,
      asyncEnd
    ) {
      //可实时上传（发送）数据，配合Recorder.SampleData方法，将buffers中的新数据连续的转换成pcm上传，或使用mock方法将新数据连续的转码成其他格式上传，可以参考文档里面的：Demo片段列表 -> 实时转码并上传-通用版；基于本功能可以做到：实时转发数据、实时保存数据、实时语音识别（ASR）等
      duration.value = bufferDuration / 1000;

      if (duration.value > 50.2) {
        message.success({
          maskClosable: true,
          content: $$t("mine.timeis50seconds"),
          position: "top",
        });
        stopRecord();
      }

      //可实时绘制波形（extensions目录内的waveview.js、wavesurfer.view.js、frequency.histogram.view.js插件功能）
      wave &&
        wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
    },
  });

  rec.open(
    function () {
      //打开麦克风授权获得相关资源
      //rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程
      //创建可视化，指定一个要显示的div
      if (Recorder && Recorder.WaveView) {
        setWave();
      }
      success && success();
    },
    function (msg, isUserNotAllow) {
      //用户拒绝未授权或不支持
      renderData.isRecording = false;
      console.log("msg==>", msg, isUserNotAllow);
      message.error({
        maskClosable: true,
        content: isUserNotAllow
          ? $$t("mine.Tocontinue")
          : $$t("mine.Yourdevicedoes"),
        position: "top",
      });
    }
  );
};

const setWave = () => {
  wave = Recorder.WaveView({
    elem: ".ctrlProcessWave",
    width: "100%",
    height: 42,
    linear1: [0, "#FF5745", 1, "#FF5745"],
    linear2: [0, "#FF5745", 1, "#FF5745"],
    lineWidth: 2,
    linearBg: [0, "rgba(255,255,255,0.1)", 1, "rgba(54,197,252,0.1)"], //背景渐变色，从上到下
  });
};

/**开始录音**/
const recStart = () => {
  //打开了录音后才能进行start、stop调用
  rec.start();
};

/**结束录音**/
const recStop = () => {
  rec.stop(
    function (blob, duration) {
      //简单利用URL生成本地文件地址，注意不用了时需要revokeObjectURL，否则霸占内存
      //此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
      audioURL.value = (window.URL || webkitURL).createObjectURL(blob);
      file = new File([blob], "audio.mp3", { type: blob.type });
      console.log(
        "rec.stop =>",
        file,
        audioURL.value,
        "时长:" + duration + "ms"
      );
      rec.close(); //释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
      rec = null;
      if (!isEnding.value) {
        initPlayer(audioURL.value);
      }
    },
    function (msg) {
      console.log("录音失败:" + msg);
      rec.close(); //可以通过stop方法的第3个参数来自动调用close
      rec = null;
    }
  );
};

onMounted(async () => {});

const onToggleRecordClick = () => {
  if (renderData.isRecording) {
    if (mathGray()) return;
    stopRecord();
  } else {
    if (!file) {
      startRecord();
    } else {
      showActionDialog({
        content: $$t("mine.Thecurrentrecording"),
        cancelLabel: $$t("common.cancel"),
        confirmLabel: $$t("create.remove"),
        confirm: (closeAction) => {
          closeAction();
          startRecord();
        },
        cancel(closeAction) {
          closeAction();
        },
      });
    }
  }
};

const stopRecord = () => {
  if (renderData.isRecording) {
    recStop();
    renderData.isRecording = false;
    clearTimeout(timer);
    duration.value = 0;
    currentTime.value = 0;
  }
};

const startRecord = () => {
  if (renderData.isPlaying) {
    renderData.isPlaying = false;
    wavesurfer && wavesurfer.stop();
  }
  renderData.isRecording = true;
  recOpen(() => {
    recStart();
  });
};

const recordCanvas = () => {
  // 创建音频分析器节点
  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();
  const source = audioContext.createMediaStreamSource(stream);
  source.connect(analyser);

  // Canvas 绘图设置
  const canvas = document.getElementById("waveformCanvas");
  const canvasCtx = canvas.getContext("2d");

  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  function draw() {
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    requestAnimationFrame(draw);

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = "#182F47";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "#FF5745";

    canvasCtx.beginPath();

    const sliceWidth = (WIDTH * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * HEIGHT) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }

  draw(); // 开始绘制波形图
};

const onTogglePlayClick = () => {
  // renderData.isPlaying = !renderData.isPlaying
  wavesurfer.playPause();
};

defineExpose({ getRecord });
watch(
  () => {
    return renderData.isRecording;
  },
  (newValue) => {
    emits("tooggleRecord", renderData.isRecording);
  }
);

const mathGray = () => {
  if (renderData.isRecording) {
    if (props.type === "motif" && duration.value < 5) {
      return true;
    } else if (props.type === "vocal" && duration.value < 30) {
      return true;
    }
  }
  return false;
};

onMounted(() => {
  GlobalAudioPlayer.tempToggle(true);
});
onUnmounted(() => {
  isEnding.value = true;
  stopRecord();
  GlobalAudioPlayer.tempToggle(false);
});
</script>
<style lang="scss">
.custom-record {
  .ctrlProcessWave {
    padding-top: 2px;
    height: 46px;
  }

  .custom-record-wave-box {
    height: 48px;
    background: rgba(255, 255, 255, 0.1);
  }
  .custom-record-tempo {
    margin: 20px 0 0 0;
    .custom-record-tempo-toggle {
      width: 49px;
      height: 40px;
      background: rgba(216, 216, 216, 0.2);
      padding: 8px 10px 8px 15px;
      border-radius: 20px 0 0 20px;

      i {
        height: 24px;
        width: 24px;
      }
      i.toogle-on {
        background: url("@/assets/img/tempo-high-ico.png");
        background-size: 100% 100%;
      }
      i.toogle-off {
        background: url("@/assets/img/tempo-ico.png");
        background-size: 100% 100%;
      }
    }
    .custom-record-tempo-value {
      min-width: 84px;
      height: 40px;
      background: rgba(216, 216, 216, 0.2);
      margin-left: 1px;
      border-radius: 0 20px 20px 0;
      padding: 10px 15px 10px 10px;
      line-height: 20px;
      .custom-record-tempo-value-input {
        height: 20px;
        width: 35px;
      }
      input {
        display: block;
        height: 100%;
        width: 100%;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        color: #ffffff;
        margin: 0;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
      span {
        padding: 2px 0 0 0;
        font-family: HarmonyOS Sans SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }
  .custom-record-time {
    margin: 20px 0 20px 0;
    font-family: HarmonyOS Sans SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
  }
  .player-wave-box {
    padding: 3px 0;
  }
  .custom-record-op {
    .custom-record-op-toogle-record-box {
      margin: 0 48px;
    }
    .custom-record-op-toogle-record {
      width: 76px;
      height: 76px;
      border-radius: 40px;
      border: 2px solid #ffffff;
      box-sizing: content-box;
      i {
        transition: all 0.3s;
      }
      i.record-off {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: #ff5f74;
      }
      i.record-on {
        width: 64px;
        height: 64px;
        border-radius: 80px;
        background: #ff5f74;
      }
      i.gray {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
