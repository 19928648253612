import useLayoutStore from "@/store/create/createLayoutStore"
import mineStroe from "@/store/mineDataStore"

const layoutConfig = {
  renderType: window.innerWidth > 768 ? 'pc' : 'mobile',
}

export const initLayout = () => {
  layoutConfig.renderType = window.innerWidth > 768 ? 'pc' : 'mobile'
  useLayoutStore().setRenderType(layoutConfig.renderType)
  mineStroe().isMobile = layoutConfig.renderType === 'mobile'
}


export default layoutConfig