<template>
  <div class="login-regist-module height100-scroll">
    <div class="form-module">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="demo-ruleForm" status-icon>
        <el-form-item label="" prop="username">
          <Input
            :value="ruleForm.username"
            placeholder="username"
            :prefix="LoginUser"
            @change="
              (val) => {
                update('username', val)
              }
            "
          />
        </el-form-item>
        <el-form-item label="" prop="email">
          <Input
            :value="ruleForm.email"
            placeholder="email"
            :prefix="LoginEmail"
            @change="
              (val) => {
                update('email', val)
              }
            "
          />
        </el-form-item>
        <el-form-item label="" prop="password">
          <Input
            :value="ruleForm.password"
            placeholder="password"
            :prefix="LoginLock"
            :showPassword="true"
            @change="
              (val) => {
                update('password', val)
              }
            "
            :max="40"
          />
        </el-form-item>
      </el-form>
      <div class="login-regist-module-btn">
        <Button
          :renderStyle="{
            // '--padding': '0 10px',
            '--width': '100%',
            '--height': '48px',
            '--borderRadius': '50px',
            '--background': 'linear-gradient(114deg, #4fdeff 21%, #e675ff 90%)',
            '--backgroundHover': 'linear-gradient(114deg, rgba(79,222,255,0.8) 21%, rgba(230,117,255,0.8) 90%)',
            '--fontColor': '#000',
            '--fontColorHover': '#000',
            '--fontSize': '16px',
            '--fontWeight': 'bold',
            '--border': 'none',
            ...props.cancelRenderStyle,
          }"
          type="primary"
          @click="onSubmit"
          >Continue</Button
        >
      </div>
      <ContinueWithThirdParty type="sign up" @continueWith="continueWith" />
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef } from "vue"
import Input from "@/components/basic/input/LoginInput2.vue"
import Button from "@/components/basic/button/Button.vue"
import ContinueWithThirdParty from "./ContinueWithThirdParty.vue"
import LoginEmail from "@/assets/img/login_mail.png"
import LoginLock from "@/assets/img/login_lock.png"
import LoginUser from "@/assets/img/login_user.png"
const props = defineProps({})
const emits = defineEmits(["submit", "continueWith"])
const ruleForm = reactive({
  username: "", // 'test123',
  email: "", //'test@123.com',
  password: "", //'Qwe123456',
})
const ruleFormRef = ref(null)
const rules = {
  username: [
    { required: true, message: "Username is required", trigger: "blur" },
    // {
    //   validator: (rule, value) => {
    //     const regExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
    //     if (!regExp.test(value)) {
    //       return new Error("Only letters(a-z)/numbers(0-9) can be entered")
    //     } else {
    //       return true
    //     }
    //   },
    //   trigger: "blur",
    // },
  ],
  email: [
    {
      required: true,
      message: "Email address is required",
      trigger: "blur",
      pattern:
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ],
  password: [
    {
      validator: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Password is required"))
        } else if (value.length < 6) {
          callback(new Error("Enter at least 6 characters"))
        } else if (value.length > 40) {
          callback(new Error("Enter no more than 40 characters"))
        } else {
          callback()
        }
      },
      trigger: "blur",
    },
  ],
}
const update = (key, val) => {
  ruleForm[key] = val
}
const onSubmit = async (formEl) => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      emits("submit", ruleForm)
    } else {
      console.log("error submit!", fields)
    }
  })
}
const continueWith = (...args) => {
  emits("continueWith", ...args)
}
onMounted(() => {})
</script>
<style lang="scss">
.login-regist-module {
  .login-regist-module-btn {
    // width: 400px;
    // height: 52px;
  }
}
</style>
