import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import apiReferMusic from "@/api/api/apiReferMusic.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";

import useDraftDataStore from "./draftDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import userReferMusicStore from "./referMusicStore.js";
// import useDraftDataStore from './draftDataStore.js';
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();

const useMotifDataStore = defineStore({
  id: "motifDataStore",
  state: () => {
    return {
      motifData: null,
    };
  },
  actions: {
    initMotifData(data) {
      this.updateMotifData(data?.motif ?? null);
    },
    getMotifData() {
      return this.motifData;
    },

    updateMotifData(data) {
      this.motifData = data;
      useMuseDataStore().setMotifData(data);
    },
    setMotifData(data) {
      this.updateMotifData(data);
    },
    deleteMotifDataByAudit(refer) {
      if (refer != null && refer.id == this.motifData.id) {
        this.updateMotifData(null);
      }
    },
    deleteMotifData(refer) {
      // let lastUseMusic = this.motifData;
      this.updateMotifData(null);
      // const draftDataStore = useDraftDataStore();
      // if (refer == null && lastUseMusic != null && lastUseMusic.id) {
      //   // const closeMsg=  message.loading({content: '取消中'})
      //   apiReferMusic
      //     .deleteReferenceMusic({
      //       feed_id: draftDataStore.feed_id,
      //       control_type: 2,
      //       refer_id: lastUseMusic.id,
      //     })
      //     .then((res) => {
      //       if (res.code == 200) {
      //       } else {
      //       }
      //     })
      //     .catch((err) => {
      //       //  closeMsg.clear();
      //     });
      // } else {
      // }
    },
  },
});
export default useMotifDataStore;
// export const motifDataStore = useMotifDataStore()
