/*
 * @author: lihaifa
 * @LastEditTime: 2024-03-15 18:00:48
 * @Description:
 * @FilePath: src/components/media/utils/clipUtil.js
 */
import mediaUtil from "./utils.js";
import tools from "@/utils/tools.js";
import Crunker from "./crunker.js";
export const sampleRate = 44100;
export const fileFormat = "audio/wav";
import ffmpegUtil from "@/components/media/utils/ffmpegUtil.js";

// const config={
//   sampleRate,
//   spacingSize: 2,
//   wavebarWidth: 4,
//   clipDuration: 10,
// }
export const clipdDecodeData = async (originalBuffer, from, to) => {
  // from to 秒
  const sampleRate = originalBuffer.sampleRate;
  const fromc = from >= 0 ? mediaUtil.trimTo(from, 3) : 0;
  const duration =
    to > 0 ? mediaUtil.trimTo(to - fromc, 3) : originalBuffer.duration;
  const newLen = ((duration / 1) * sampleRate) >> 0;
  const newOffset = ((fromc / 1) * sampleRate) >> 0;
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: sampleRate,
  });
  const centerSegment = audioCtx.createBuffer(
    originalBuffer.numberOfChannels,
    newLen,
    sampleRate
  );
  const activeChannels = originalBuffer.numberOfChannels;
  for (let i = 0; i < activeChannels; ++i) {
    centerSegment
      .getChannelData(i)
      .set(
        originalBuffer.getChannelData(i).slice(newOffset, newLen + newOffset)
      );
  }
  return centerSegment;
};

export const getDecodeDataByUrl = async (url) => {
  const response = await fetch(url);
  const resblob = await response.blob();
  const arrayBuffer = await resblob.arrayBuffer();
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: sampleRate,
  });
  const decode = audioCtx.decodeAudioData(arrayBuffer);
  decode.finally(() => audioCtx.close());
  return await decode;
};

export const getCrunkerExportData = async (
  url,
  from,
  to,
  type = fileFormat
) => {
  const centerSegment = await clipdDecodeData(
    await getDecodedata(url),
    from,
    to
  );
  const crunker = new Crunker({ sampleRate: sampleRate });
  const crunkerExport = await crunker.export(centerSegment, type);
  crunkerExport.duration = centerSegment.duration;
  return crunkerExport;
};

export const decodeDataToUrl = async (decodeData, type = fileFormat) => {
  const crunker = new Crunker({ sampleRate: sampleRate });
  const crunkerExport = await crunker.export(decodeData, type);
  crunkerExport.duration = decodeData.duration;
  return crunkerExport;
};

export const blobUrlToFile = async (blobUrl, fileName) => {
  // 使用 Fetch API 下载 Blob 数据
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // 创建 File 对象
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

export const blobToFile = async (blob, fileName) => {
  return new File([blob], fileName, { type: blob.type });
};

export const downloadAndClipUrl = async (url, from, to) => {
  // from to 秒
  const crunkerExport = await getCrunkerExportData(url, from, to);
  tools.downLoadAtag({
    blob: crunkerExport.blob,
    fileName: "download" + url.match(/.[^.]+$/)[0],
  });
};

export const getDecodeDataByFile = (file) => {
  // 获取文件音频时长
  return new Promise((resolve, reject) => {
    try {
      if (file) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        let reader = new FileReader();
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          try {
            audioContext.decodeAudioData(
              arrayBuffer,
              (decodeData) => {
                resolve(decodeData);
              },
              (error) => {
                reject(error);
              }
            );
          } catch (e) {
            reject(e);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        resolve(0);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const getWavebarData = (
  decodedata,
  points = 200,
  averageCount = 100,
  endClipTime = 0
) => {
  const calcNumberOfChannels = 1;
  const numberOfChannels = decodedata.numberOfChannels;
  let channelDatas = new Array(numberOfChannels).fill(0).map((_, index) => {
    let channelData = decodedata.getChannelData(index);
    return channelData.slice(
      0,
      Math.floor(
        (channelData.length * Math.max(0, decodedata.duration - endClipTime)) /
          decodedata.duration
      )
    );
  });
  let channelDatasChunk = channelDatas
    .slice(0, calcNumberOfChannels)
    .map((item) => splitArray(item, points));
  return channelDatasChunk[0].map((chuck, index) => {
    const step = Math.floor((chuck.length ?? 0) / averageCount);
    let sum = 0;
    for (let i = 0; i < chuck.length; i += step) {
      for (let j = 0; j < calcNumberOfChannels; j++) {
        sum += Math.abs(channelDatasChunk[j][index][i] ?? 0);
      }
    }
    const average = sum / (chuck.length / step);
    return average;
  });
};

export const getWaveDataByWaveFormData = (waveFormData, count) => {
  const waveSplitData = splitArray(waveFormData, count);
  return waveSplitData.map((item, index) => {
    const sum = item.reduce((pre, cur) => pre + cur, 0);
    return sum / item.length;
  });
};

export const fixedPaintHarfHeightWaveBarData = (data, HalfHeight) => {
  const max = Math.max(...data);
  if (max == 0) {
    return data.map((item) => {
      return 1;
    });
  }
  const gainRatio = HalfHeight / max;
  return data.map((item) => {
    let gainHeight = Math.floor(item * gainRatio);
    return gainHeight <= 0 ? 1 : gainHeight;
  });
};

export const splitArray = (arr, count) => {
  if (arr.length === 0 || count <= 0) return [];
  const size = Math.floor(arr.length / count); // 每组的基础大小
  const sizeMod = arr.length % count; // 多出来的元素数量
  const result = new Array(count)
    .fill()
    .map((_, i) =>
      arr.slice(
        i * size + Math.min(i, sizeMod),
        (i + 1) * size + Math.min(i + 1, sizeMod)
      )
    );
  return result;
};

export const getFileDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (event) {
        const dataURL = event.target.result;
        resolve(dataURL);
        console.log(dataURL);
      };
      reader.onerror = function (event) {
        console.log("getFileDataUrl_event.target.error", event.target.error);
        reject(event.target.error);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      console.error("getFileDataUrl", e);
      reject(e);
    }
  });
};
export const downloadFile = async (url, filename) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status === 200) {
        var blob = new Blob([this.response], { type: "text/plain" });
        const resfile = new File([blob], filename, { type: blob.type });
        resolve(resfile);
      } else {
        reject(this.statusText);
      }
    };
    xhr.send();
  });
};
export const getFileDecodeData = async (file) => {
  return new Promise(async (resolve, reject) => {
    let decodeData = null;
    let blobUrl = null;
    try {
      decodeData = await getDecodeDataByFile(file);
      blobUrl = (window.URL || window.webkitURL).createObjectURL(file);
      resolve({
        blobUrl,
        decodeData,
      });
    } catch (e) {
      try {
        file = await ffmpegUtil.transFileToMp3(file);
        blobUrl = outputfile.blobUrl;
        decodeData = await getDecodeDataByFile(file);
        resolve({
          blobUrl,
          decodeData,
        });
      } catch (e) {
        reject(e);
      }
    }
  });
};
export const getWaveDataByUrl = (url, count, endClipTime) => {
  return new Promise((resolve, reject) => {
    downloadFile(url)
      .then((file) => {
        getFileDecodeData(file)
          .then(({ decodeData, blobUrl }) => {
            let wavebarData = getWavebarData(
              decodeData,
              count,
              100,
              endClipTime
            );
            resolve(wavebarData);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default {
  sampleRate,
  // config,
  clipdDecodeData,
  getDecodeDataByUrl,
  decodeDataToUrl,
  blobUrlToFile,
  blobToFile,
  getDecodeDataByFile,
  getCrunkerExportData,
  downloadAndClipUrl,
  getWavebarData,
  getWaveDataByWaveFormData,
  fixedPaintHarfHeightWaveBarData,
  getFileDataUrl,
  downloadFile,
  getFileDecodeData,
  getWaveDataByUrl,
};
// 调用例子
// downloadAndClipUrl('https://musicaigc-na-1256122840.cos.na-ashburn.myqcloud.com/naprod/svcs/sms/ai-sm-1-Frozen.mp3',0,20)
