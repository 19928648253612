const dialogMap = {}

export const addDialog = (tag,close)=>{
  dialogMap[tag]=close;
}

export const removeDialog = (tag)=>{
  delete dialogMap[tag];
}

export const closeDialog =(tag)=>{
  if(dialogMap[tag]){
    dialogMap[tag]();
    delete dialogMap[tag];
  }
}
export const closeAllDialog =()=>{
  Object.keys(dialogMap).forEach(tag => {
    if(dialogMap[tag]){
      dialogMap[tag]();
      delete dialogMap[tag];
    }
  });
}