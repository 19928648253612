import { defineStore } from "pinia";
import apiHome from "@/api/api/apiHome.js";
import { urlIncludeDomain } from "@/utils/tools.js";
import message from "@/components/functionCallComponent/message/message.js";

import config from "@/config/config.js";
export const getObjectStorageFullUrl = (url) => {
  if (urlIncludeDomain(url)) {
    return url;
  }
  return config.baseObjectStorageUrl + url;
};
const useHomeDataStore = defineStore({
  id: "useHomeDataStore",
  state: () => {
    return {
      prompt: "",
      m3u8Url: "",
      stream_key: "",
      isLoading: false,
      sseGenerate: null,
      lyrics: [],
    };
  },
  actions: {
    
    doCreateMusic(prompt) {
      this.isDoCreateMusic = true;
      this.isLoading = true;
      if(this.sseGenerateCrash){
        this.m3u8Url = '';
      }
      return new Promise((resolve, reject) => {
        apiHome
          .createMusic({
            type: 2,
            prompt,
            ...(this.firstResData && this.sseGenerate != null
              ? {
                  feed_id: this.firstResData.feed_id,
                }
              : {}),
          })
          .then((res) => {
            this.isDoCreateMusic = false;
            if (res.code == 200) {
              if (!this.sseGenerate) {
                this.doGenerateConnectedSSE(res.data.conn_id, res.data.feed_id);
                this.firstResData = res.data;
              }
              this.stream_key = res.data.stream_key;
              this.feed_id=res.data.feed_id;
              resolve(res);
            } else {
              this.isLoading = false;
              if (res.code == -200) {
                message.info({
                  maskClosable: true,
                  content:
                    "No Internet Connection. Please check your connection and try again.",
                  position: "top",
                });
              } else {
                message.info({
                  maskClosable: true,
                  content:
                    "Failed to generate audio. Aliens are causing trouble. Try again after we deal with them.",
                  position: "top",
                });
              }
              reject(res);
            }
          })
          .catch((err) => {
            this.isDoCreateMusic = false;
            this.isLoading = false;
            message.info({
              maskClosable: true,
              content:
                "Failed to generate audio. Aliens are causing trouble. Try again after we deal with them.",
              position: "top",
            });
            reject(err);
          });
      });
    },
    async doGenerateConnectedSSE(conn_id, feed_id) {
      this.generating = true;
      this.curSseConnectId = conn_id;
      this.sseGenerate = await apiHome.doSseGetGenerate({
        params: `conn_id=${conn_id}`,
        onOpen(e) {},
        onMessage: (res) => {
          if (["inf_feed"].indexOf(res.event) >= 0) {
            let data = JSON.parse(res.data ?? "{}");
            console.log("data", data);
            if (data.song?.m3u8_url && this.m3u8Url != data.song.m3u8_url) {
              this.m3u8Url = getObjectStorageFullUrl(data.song.m3u8_url);
            }
            if (data.song?.lyrics_segments?.length && !this.lyrics.length) {
              const lyrics = data.song.lyrics_segments.reduce((res, itemp) => {
                if (itemp.user_input_tag) {
                  res.push({
                    seg_type: itemp.seg_type,
                    text: itemp.user_input_tag,
                  });
                }
                return (itemp.rows ?? []).reduce((res, item) => {
                  res.push({
                    seg_type: itemp.seg_type,
                    text: item.text,
                  });
                  return res;
                }, res);
              }, []);
              this.lyrics = [].concat(this.lyrics).concat(lyrics);
            }
          } else {
            if (res.event == "done") {
              this.sseGenerate?.abort();
              this.sseGenerateCrash = true;
              this.sseGenerate = null;
              this.generating = false;
              if(this.isDoCreateMusic == false){
                this.isLoading = false;
              }
              message.info({
                maskClosable: true,
                content:
                  "Generation failed ! Aliens are invading our server! We will repel them ASAP(ﾟ皿ﾟ)ｒ┏┳－－－＊",
                position: "top",
              });
            }
          }
        },
        onError: (e) => {},
        onClose: (e) => {
          this.curSseConnectId = null;
          this.generating = false;
        },
      });
    },
    abortSse() {
      this.sseGenerate?.abort();
      this.sseGenerate = null;
      this.generating = false;
      this.isLoading = false;
    },
    doPlayReport(url, duration){
      return new Promise((resolve, reject) => {
        apiHome
          .doPlayReport({
            play_url: url,
            feed_id: this.feed_id,
            duration
          })
          .then((res) => {
            if (res.code == 200) {
              resolve()
            }else{
              reject({})
            }
          })
          .catch((err) => {
            
            reject(err);
          });
      });
    }
  },
});

export default useHomeDataStore;
