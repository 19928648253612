import { ElUpload } from 'element-plus';
import './elementUpload.scss';

const defaultConfig = {
  action: '',
  headers: {},
  method: '',
  multiple: false,
  data: {},
  name: '',
  withCredentials: true,
  showFileFist: true,
  drag: true,
  accept: '.mp3,.wav,.m4a,.mp4',
  onPreview(uploadFile) {
    // console.log("onPreview", uploadFile);
  },
  onRemove(uploadFile, uploadFiles) {
    // console.log("onRemove", uploadFile, uploadFiles);
  },
  onSuccess(uploadFile, uploadFiles) {
    // console.log("onSuccess", uploadFile, uploadFiles);
  },
  onError(error, uploadFile, uploadFiles) {
    // console.log("onSuccess", error, uploadFile, uploadFiles);
  },
  onProgress(evt, uploadFile, uploadFiles) {
    // console.log("onProgress", error, uploadFile, uploadFiles);
  },
  onChange(uploadFile, uploadFiles) {
    // console.log('onChange', uploadFile, uploadFiles);
  },
  onExcee(files, uploadFiles) {
    // console.log('onChange', files, uploadFiles);
  },
  beforeUpload(rawFile) {
    // console.log('rawFile', rawFile);
  },
  beforeRemove(uploadFile, uploadFiles) {
    // console.log('beforeRemove', rawFile);
  },
  listType: 'text',
  disabled: false,
  autoUpload: false,
};
export default {
  name: "ElementUplaod",
  components: {

  },
  props: {
    config: {
      type: Object,
      default: {
      },
    },
    disabled:  Boolean
  },
  setup(props) {
    return {
      fileList: [],
    };
  },
  methods: {
    clearInput() {
      this.$refs.refElupload.clearFiles();
      this.$refs.refElupload.$refs.uploadRef.$refs.inputRef.value = null;
    },
    handleUploadClick() {
      // console.log("this.$refs.refElupload", this.$refs.refElupload);
      // this.$refs.refElupload.$el.querySelector('.el-upload__input').dispatchEvent(new MouseEvent('click'));
      this.$refs.refElupload.$refs.uploadRef.$refs.inputRef.dispatchEvent(new MouseEvent('click'));
    }
  },
  render() {
    const renderProps = {
      ...defaultConfig,
      ...this.config,
    };
    // <div class="height-all draging-dashed" >
    //       {this.$slots.dragover&&this.$slots.dragover()}
    //     </div>
    return (
      <ElUpload
        ref="refElupload"
        class={`upload-component height-all`}
        {...renderProps}
        disabled={this.disabled??renderProps.disabled}
        fileList={this.fileList}
      >
          {this.$slots.default()}        
      </ElUpload>
    );
  }
};
