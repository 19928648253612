// https://github.com/ffmpegwasm/ffmpeg.wasm
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
// const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';
// const baseURL = './static/js/ffmpeg';
import GlobalConfig from '@/config/config.js'
import { replaceFileExtension} from '@/utils/tools.js'
const ffmpegURL = `${GlobalConfig.baseAssetsUrl}/static/js/ffmpegMini20240511`;
const loadFfmpegTimeout = 1000 * 60;
const transFileToWav = (file, fileName = "output.wav") => {
  // console.log("transMovFileToWav 开始加载")
  return new Promise(async (resolve, reject) => {
    let ffmpeg;
    const timer = setTimeout(() => {
      reject(new Error('FFmpeg loading timed out'));
      ffmpeg.off();
      ffmpeg=null;
    }, loadFfmpegTimeout);
    try {
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        console.log(message);
      });
      
  
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
        workerURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.worker.js`,
          "text/javascript"
        ),
      })
      clearTimeout(timer);
      // console.log("transMovFileToWav 加载OK")
      // console.log("transMovFileToWav 开始写文件")
      await ffmpeg.writeFile(replaceFileExtension(file.name), await fetchFile(file));
      // console.log("transMovFileToWav 开始转换")
      await ffmpeg.exec(["-i", replaceFileExtension(file.name), "-vn", fileName]);
      // console.log("transMovFileToWav 读取转换结果")
      const data = await ffmpeg.readFile(fileName);
      console.log(data);
      // console.log("transMovFileToWav 转换后的数据长度", data.length)
      const blobUrl = (window.URL || window.webkitURL).createObjectURL(
        new Blob([data.buffer], { type: "audio/wav" })
      );
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      // 创建 File 对象
      const resfile = new File([blob], fileName, { type: blob.type });
      // console.log("transMovFileToWav 生成文件")
      // const resfile = new File([data], fileName, { type: 'audio/wav' });
      const res = {
        file: resfile,
        blobUrl: blobUrl,
        blob: blob
      };
      // console.log("transMovFileToWav 转换完成", res)
      resolve(res);
      ffmpeg.off();
      ffmpeg = null;
    } catch (e) {
      clearTimeout(timer);
      ffmpeg.off();
      ffmpeg=null;
      reject(e);
    }
  });
};

const transFileToMp3 = (file, fileName = "output.mp3") => {
  // console.log("transMovFileToWav 开始加载")
  return new Promise(async (resolve, reject) => {
    let ffmpeg;
    const timer = setTimeout(() => {
    
      reject(new Error('FFmpeg loading timed out'));
      ffmpeg.off();
      ffmpeg=null;
    }, loadFfmpegTimeout);
    try {
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        console.log(message);
      });
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
        workerURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.worker.js`,
          "text/javascript"
        ),
      });
      clearTimeout(timer);
      // console.log("transMovFileToWav 加载OK")
      // console.log("transMovFileToWav 开始写文件")
      // await ffmpeg.exec(["-codecs"]);
      await ffmpeg.writeFile(replaceFileExtension(file.name), await fetchFile(file));
      // console.log("transMovFileToWav 开始转换")

      await ffmpeg.exec([
        "-i",
        replaceFileExtension(file.name),
        "-c:a",
        "libmp3lame",
        "-q:a",
        2,
        fileName,
      ]);
      // console.log("transMovFileToWav 读取转换结果")

      const data = await ffmpeg.readFile(fileName);
      console.log(data);
      // console.log("transMovFileToWav 转换后的数据长度", data?.length)
      const blobUrl = (window.URL || window.webkitURL).createObjectURL(
        new Blob([data.buffer], { type: "audio/mp3" })
      );
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      // 创建 File 对象
      const resfile = new File([blob], fileName, { type: blob.type });
      // console.log("transMovFileToWav 生成文件")
      // const resfile = new File([data], fileName, { type: 'audio/wav' });
      const res = {
        file: resfile,
        blobUrl: blobUrl,
        blob: blob
      };
      // console.log("transMovFileToWav 转换完成", res)
      resolve(res);
      ffmpeg.off();
      ffmpeg = null;
    } catch (e) {
      clearTimeout(timer);
      reject(e);
      ffmpeg.off();
      ffmpeg=null;
    }
  });
};

const transFileByFfmpeg = async (
  file,
  fileName = "output.wav",
  extendOrder = []
) => {
  // console.log("transMovFileToWav 开始加载")
  return new Promise(async (resolve, reject) => {
    let ffmpeg;
    const timer = setTimeout(() => {
      reject(new Error('FFmpeg loading timed out'));
      ffmpeg.off();
      ffmpeg=null;
    }, loadFfmpegTimeout);
    try {
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        console.log(message);
      });
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
        workerURL: await toBlobURL(
          `${ffmpegURL}/ffmpeg-core.worker.js`,
          "text/javascript"
        ),
      });
      // console.log("transMovFileToWav 加载OK")
      // console.log("transMovFileToWav 开始写文件")
      await ffmpeg.writeFile(replaceFileExtension(file.name), await fetchFile(file));
      // console.log("transMovFileToWav 开始转换")
      await ffmpeg.exec(["-i", replaceFileExtension(file.name), ...extendOrder.concat([fileName])]);
      // console.log("transMovFileToWav 读取转换结果")
      const data = await ffmpeg.readFile(fileName);
      console.log(data);
      // console.log("transMovFileToWav 转换后的数据长度", data.length)
      const blobUrl = (window.URL || window.webkitURL).createObjectURL(
        new Blob([data.buffer], { type: "audio/wav" })
      );
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      // 创建 File 对象
      const resfile = new File([blob], fileName, { type: blob.type });
      // console.log("transMovFileToWav 生成文件")
      // const resfile = new File([data], fileName, { type: 'audio/wav' });
      const res = {
        file: resfile,
        blobUrl: blobUrl,
        blob: blob
      };
      // console.log("transMovFileToWav 转换完成", res)
      resolve(res);
      ffmpeg.off();
      ffmpeg = null;
    } catch (e) {
      ffmpeg.off();
      ffmpeg=null;
      reject(e);
    }
  });
};

export default {
  transFileToWav,
  transFileToMp3,
  transFileByFfmpeg,
};
