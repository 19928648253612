<template>
  <div class="custom-search" :style="renderStyle" ref="refInput">
    <el-input
      ref="refSearch"
      @change="change"
      @input="input"
      @keyup.enter="onEnterPressed"
      v-model="inputValue"
      :placeholder="props.placeholder"
      :clearable="props.clearable"
      @clear="clear"
    >
      <template #prefix>
        <el-icon class="el-input__icon">
          <Search />
        </el-icon>
      </template>
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </el-input>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onMounted,
  watch,
  onBeforeUnmount,
} from "vue";
import { Search } from "@element-plus/icons-vue";
const inputValue = ref("");
const props = defineProps({
  clearable: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    require: true,
    default: "please input",
  },
  renderStyle: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emit = defineEmits([
  "input",
  "change",
  "keyupEnter",
  "update",
  "blur",
  "clear",
]);
const renderDefaultStyle = {
  "--height": "100%",
  "--padding": "0 0 0 0",
  "--borderRadius": "60px",
  "--border": "1px solid #dcdfe6",
  "--focusBorder": "1px solid #94ADFF",
  "--background": "#111",
  "--backgroundHover": "rgba(255,255,255,0.125)",
  "--backgroundFocus": "rgba(118, 255, 232, 0.1)",
  "--fontSize": "14px",
  "--fontColor": "#fff",
  "--fontFamily": "inherit",
  "--fontWeight": "500",
  "--lineHeight": "20px",
};
const refInput = ref(null);
const refSearch = ref(null);
const renderStyle = Object.assign(
  {},
  renderDefaultStyle,
  props.renderStyle ?? {}
);
const input = (val) => {
  inputValue.value = val;
  emit("input", val);
};
const change = (val) => {
  inputValue.value = val;
  emit("change", inputValue.value);
};
const blur = () => {
  emit("blur");
};
const clear = () => {
  inputValue.value = "";
  emit("clear");
};

const onEnterPressed = () => {
  emit("keyupEnter", inputValue.value);
};
watch(
  () => {
    return props.modelValue;
  },
  () => {
    console.log("props.value;", props.modelValue);
    inputValue.value = props.modelValue;
  }
);
watch(
  () => {
    return inputValue.value;
  },
  () => {
    emit("update", inputValue.value);
  }
);
onMounted(() => {
  inputValue.value = props.value;
  refInput.value.querySelector("input").addEventListener("blur", blur);
});
onBeforeUnmount(() => {
  refInput.value.querySelector("input").removeEventListener("blur", blur);
});
defineExpose({
  focus: () => {
    refInput.value?.querySelector("input")?.focus();
  },
  reset: () => {
    inputValue.value = "";
    refInput.value.querySelector("input").value = "";
  },
});
</script>
<style lang="scss">
.custom-search {
  width: 100%;
  & {
    height: var(--height);
  }

  .el-input {
    height: var(--height);
    width: 100%;
    border: none;
    background: transparent;
  }
  .el-input__wrapper {
    padding: var(--padding);
    background: var(--background);
    border-radius: var(--borderRadius);
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    border: 1px solid var(--background);
    border: var(--border);
    box-shadow: none;
    &.is-focus {
      border: var(--focusBorder);
    }
    input {
      color: var(--fontColor);
    }
    input::placeholder {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  .el-input__prefix {
    .el-input__icon {
      height: 24px;
      width: 24px;
      background: url(../../../assets/img/search-ico.png);
      background-size: 100% 100%;
      svg {
        display: none;
      }
    }
  }
  .el-input__suffix {
    .el-input__icon {
      height: 24px;
      width: 24px;
      &:before {
        content: "";
        display: block;
        height: 16px;
        width: 16px;
        background: url(../../../assets/img/close-white-ico.png);
        background-size: 100% 100%;
      }
    }
    svg {
      display: none;
    }
  }

  .el-input__suffix-inner {
    flex-direction: row-reverse;
  }
  .el-input__wrapper:hover {
    background: var(--backgroundHover);
  }
  .el-input__wrapper.is-focus {
    background: var(--backgroundFocus);
  }
}
</style>
