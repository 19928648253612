import { defineStore, setActivePinia } from "pinia";
import message from "@/components/functionCallComponent/message/message.js";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";

const useDriverStore = defineStore({
  id: "driverStore",
  state: () => {
    return {
      diverDataKey: ["click_to_ai_generate_more_row", "click_to_generate_song"],
      curDriverKey: $$language() != "cn" ? "click_to_ai_generate_more_row" : "",
    };
  },
  actions: {
    init(isLogin) {
      const userDataStore = useUserDataStore();
      if (!(isLogin ?? userDataStore.isLogin)) {
        this.curDriverKey = "click_to_ai_generate_more_row";
      } else {
        this.curDriverKey = "";
      }
    },
    setcurDriverKey(step) {
      const userDataStore = useUserDataStore();
      if (!userDataStore.isLogin) {
        this.curDriverKey = step;
      }
    },
  },
});

export default useDriverStore;
