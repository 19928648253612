<template>
  <div class="c-modal" v-if="pageVisible">
    <div class="mask">
      <div class="content forget-password">
        <div class="mobile-title">
          <div class="left">Forgot Password?</div>
          <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
        </div>
        <div class="back-btn" @click="backPage">
          <img src="@/assets/img/ic_leftarrow.png" alt="" />
        </div>
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="login-module-main forget-password-main">
          <div class="forget-password-text">Forgot Password?</div>
          <p class="forget-password-sm">Select a part of the area to keep the lyrics and regenerate.</p>
          <div class="forget-password-input">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="demo-ruleForm" status-icon>
              <el-form-item label="" prop="email">
                <Input
                  :value="ruleForm.email"
                  :prefix="LoginEmail"
                  @change="
                    (val) => {
                      update('email', val)
                    }
                  "
                  placeholder="email"
                />
              </el-form-item>
            </el-form>
          </div>
          <div class="forget-password-submit">
            <Button
              :renderStyle="{
                '--width': '100%',
                '--height': '48px',
                '--borderRadius': '50px',
                '--background': 'linear-gradient(114deg, #4fdeff 21%, #e675ff 90%)',
                '--backgroundHover': 'linear-gradient(114deg, rgba(79,222,255,0.8) 21%, rgba(230,117,255,0.8) 90%)',
                '--fontColor': '#000',
                '--fontColorHover': '#000',
                '--fontSize': '16px',
                '--fontWeight': 'bold',
                '--border': 'none',
              }"
              type="primary"
              @click="onSubmit"
              >Send reset email</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef, getCurrentInstance } from "vue"
import message from "@/components/functionCallComponent/message/message.js"
import ShowActionDialog from "@/components/functionCallComponent/action/action.js"
import Input from "@/components/basic/input/LoginInput2.vue"
import apiUser from "@/api/api/apiUser.js"
import useUserDataStore from "@/store/userDataStore"
import { funComponentList } from "@/components/functionComp/index"
import LoginEmail from "@/assets/img/login_mail.png"
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: "确定",
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  rightType: {
    type: String, // 'generate' | 'login' | undefined, // 显示权益的类型
  },
})

const pageVisible = ref(false)
pageVisible.value = props.visible

const ruleForm = reactive({
  email: "",
})

const rules = {
  email: [
    {
      required: true,
      message: "Email address is required",
      trigger: "blur",
      pattern:
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ],
}

// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const closePage = () => {
  pageVisible.value = false
}

const backPage = () => {
  closePage()
  funComponentList.LogIn({
    // content: "在request.ts触发的函数式组件",
    rightType: props.rightType,
    handleOk: (str) => {
      console.log("点击成功" + str)
    },
  })
}

const userDataStore = useUserDataStore()

const emits = defineEmits([])

const ruleFormRef = ref(null)
const update = (key, val) => {
  ruleForm[key] = val
}

const onSubmit = async (formEl) => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      submit(ruleForm)
    }
  })
}

const submit = async ({ email }) => {
  const { data, code, msg } = await apiUser.userSendEmailToRetrievePassword({
    email: email,
  })
  if (code === 200) {
    ShowActionDialog({
      width: "500px",
      // content: "重置密码邮件已发送，请查收",
      content: "The email for resetting password has been sent, please check it",
      hideCancel: true,
      confirm(close) {
        close()
        userDataStore.upDataAuthData({})
        userDataStore.updateLoginStatus(false)
      },
      cancel() {},
    })
  } else if (code == 9008) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "Please wait 1 minute and try again",
    })
  } else {
    message.error({
      maskClosable: true,
      position: "top",
      content: "Failed to send verification email, please try again later",
    })
  }
}
</script>

<style lang="scss" scoped>
.c-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding: 0 60px;
    .forget-password-text {
      margin: 55px 0 8px 0;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
      text-align: center;
    }
    .forget-password-sm {
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 25px;
    }
    .forget-password-input {
      margin-bottom: 28px;
    }
    .a-link {
      text-decoration: underline;
    }
    .forget-password-submit {
      padding-bottom: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      margin: 0 16px;
      padding: 20px;
      background: linear-gradient(180deg, #5d6b84 4%, #4a4161 100%);
      .mobile-title {
        margin-bottom: 6px;
      }
      .back-btn {
        display: none;
      }
      .forget-password-text {
        display: none;
      }
      .forget-password-submit {
        padding-bottom: 0px;
      }
      .forget-password-input {
        margin-bottom: 20px;
      }
      .forget-password-sm {
        text-align: left;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 20px;
      }
    }
  }
}
</style>
