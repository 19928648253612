import axiosIS from "@/api/net/axios.js"
import ApiConfig, { baseUrl } from "@/config/apiConfig.js"
export default {
  // 订单列表
  // https://rg975ojk5z.feishu.cn/wiki/VowYwm3wKinLR8kZrLZcMXFMnBd
  mineOrderList(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/order/list`, data, { method: "get" })
  },
  // 订单详情
  // https://rg975ojk5z.feishu.cn/wiki/EB8nwoor9i7owdkRcxxciTbynJc
  mineOrderDetail(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/order/detail`, data, { method: "get" })
  },
  // 我的收藏
  mineFavoriteList(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/user/favorite/songs`, data, { method: "get" })
  },
  // 在售歌曲
  mineSaleList(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/user/sold/songs`, data, { method: "get" })
  },
  // 收益列表
  mineRevenueList(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/order/revenue/list`, data, { method: "get" })
  },
  // 上架下架
  changeSaleState(data= {}){
    return axiosIS(`${baseUrl}/api/pgc/song/sale-manage/apply-or-stop`, data, { method: "post" })
  },
  // 下载证书
  download(data= {}){
    return axiosIS(`${baseUrl}/api/pgc/song/download`, data, { method: "post" })
  },
  // Liked 和 sale 总数
  likeSale(data= {}){
    return axiosIS(`${baseUrl}/api/pgc/song/library/count`, data, { method: "post" })
  },
}
