<template>
  <div
    class="icon-button-box fn-inline"
    :class="{
      default: props.hoverBackgroundColor == '',
    }"
    :style="{
      '--hoverBackgroundColor': props.hoverBackgroundColor,
      '--background': props.backgroundColor,
    }"
  >
    <div
      class="icon-button pointer flex-center"
      :class="{
        disabled: props.disabled,
        normal: !props.disabled,
        loading: props.loading,
      }"
      @click="onClick"
      :style="{
        height: props.size,
        width: props.size,
        borderRadius: props.size,
      }"
    >
      <i
        v-show="props.icoUrl != '' || props.loading"
        class="icon-button-ico block"
        :style="{
          height: props.icoSize,
          width: props.icoSize,
        }"
      >
        <slot> </slot>
      </i>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "36px",
  },
  icoSize: {
    type: String,
    default: "20px",
  },
  backgroundColor: {
    type: String,
    default: "",
  },
  hoverBackgroundColor: {
    type: String,
    default: "",
  },
  disabled: Boolean,
});
const emits = defineEmits(["onClick"]);

const onClick = () => {
  if (props.disabled || props.loading) return;
  emits("onClick");
};
</script>
<style lang="scss">
.icon-button-box {
  .icon-button {
    background-color: var(--background);
  }
  .icon-button.normal:hover {
    background-color: var(--hoverBackgroundColor);
  }
  .icon-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .icon-button.loading {
    cursor: not-allowed;
    opacity: 1;
    &:hover {
      background-color: var(--background);
    }
    i {
      height: 20px;
      width: 20px;
      min-width: 20px;
      min-height: 20px;
      background-image: url(@/assets/img/loading-mini-3x.png) !important;
      background-size: 100% 100% !important;
      animation: animation-rotate-infinite 0.75s infinite normal linear;
      * {
        display: none;
      }
    }
  }
  img {
    border: none;
    height: 100%;
    width: 100%;
    display: block;
  }
}
.icon-button-box.default {
  .icon-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.75;
  }
}
</style>
