import { createI18n } from 'vue-i18n';
import GlobalConfig from '@/config/config.js';
import { getDataByFields } from "@/utils/tools.js";
import commonEn from './en/common.json';
import commonCn from './cn/common.json';
import storeEn from "./en/store.json"
import storeCn from "./cn/store.json"
import createEn from "./en/create.json";
import createCn from "./cn/create.json";
import creditEn from "./en/credit.json"
import creditCn from "./cn/credit.json"

import libraryCn from "./cn/library.json";
import libraryEn from "./en/library.json";

import mineCn from "./cn/mine.json";
import mineEn from "./en/mine.json";

import landCn from "./cn/land.json";
import landEn from "./en/land.json";

const messages = {
  en: {
    common: commonEn,
    store: storeEn,
    create: createEn,
    credit: creditEn,
    mine: mineEn,
    library: libraryEn,
    land: landEn
  },
  cn: {
    common: commonCn,
    store: storeCn,
    create: createCn,
    credit: creditCn,
    mine: mineCn,
    library: libraryCn,
    land: landCn
  },
};

const localData = localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}"
const { appLanguage } = JSON.parse(localData)

const language = import.meta.env.VITE_LANGUAGE
console.log("VITE_LANGUAGE", language)
const i18n = createI18n({
  locale: language ?? 'en',
  //appLanguage ?? "en", // 默认语言
  fallbackLocale: "en", // 如果找不到当前语言的翻译，默认回退到的语言
  messages,
})

export const $$t = (key, language) => {
  if (language && Object.keys(messages).includes(language)) {
    return getDataByFields(messages[language], key);
  } else {
    return i18n.global.t(key);
  }
};

export const $$language = (language) => {
  if (language && Object.keys(messages).includes(language)) {
    if (i18n.global.locale != language) {
      let old = i18n.global.locale;
      i18n.global.locale = language;
      setTimeout(() => {
        triggleBrodcastEvent("languageChange", language, old);
      }, 1);
    }
  }
  return i18n.global.locale;
};

const languageChangeListeners = [];
const triggleBrodcastEvent = (type, language) => {
  languageChangeListeners.forEach((listener) => {
    listener(type, language);
  });
};

export const $$registLanguageChangeListener = (listener) => {
  languageChangeListeners.push(listener);
};

export const $$removeLanguageChangeListener = (listener) => {
  const index = languageChangeListeners.indexOf(listener);
  if (index > -1) {
    languageChangeListeners.splice(index, 1);
  }
};

export default i18n;
