<template>
  <div class="c-modal" v-if="pageVisible">
    <div class="mask">
      <Rights v-if="rightType" :rightType="rightType" :create_type="props.create_type" :source="props.source" />
      <div class="content">
        <div class="mobile-title">
          <div class="left">Sign Up</div>
          <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
        </div>
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="bg-img">
          <div class="login-module-main login-in-main">
            <div class="login-img-box">Sign Up</div>
            <div class="login-in-main-content">
              <GoogleThirdLogin @continueWith="continueWith" />
              <div @click="goSignUp" class="sign-up-block">Sign up with email</div>
            </div>
            <p class="forget-password-footer tc">
              Already have an account?
              <span @click="goLogin" class="a-link btn pointer">Log in</span>
            </p>
            <div class="terms">
              By continuing, you agree to<a href="/terms" class="a-link btn pointer"> Terms of Use</a> and<a
                href="/privacy"
                class="a-link btn pointer"
              >
                Privacy Policy</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue"
import message from "@/components/functionCallComponent/message/message.js"
import apiUser from "@/api/api/apiUser.js"
import useUserDataStore from "@/store/userDataStore"
import { funComponentList } from "@/components/functionComp/index"
import useShopCartStore from "@/store/shopCartStore.js"
import { getParameterByName } from "@/utils/tools.js"
import GoogleThirdLogin from "@/components/login/GoogleThirdLogin.vue"
import Rights from "./rights.vue"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import { getUseSource } from "@/api/net/config.js"
//自定义函数组件无法使用全局组件，需要单独引入
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: "确定",
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  handleCancel: {
    type: Function, //失败回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  content: {
    type: String,
    default: "自定义全局函数组件......",
  },
  rightType: {
    type: String, // 'generate' | 'login' | undefined, // 显示权益的类型
    default: "login",
  },
  create_type: {
    type: String,
    default: "2",
  },
  source: {
    type: String,
    default: "other",
  },
})

const pageVisible = ref(false)
pageVisible.value = props.visible
// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const userDataStore = useUserDataStore()
const shopCartStore = useShopCartStore()

const closePage = () => {
  pageVisible.value = false
  firebaseUtils.logClickEvent(props.rightType === "generate" ? "close_new_user_login" : "close_login", {
    create_type: props.create_type,
    page_route: window.location.pathname,
    source: props.source,
  })
}

const goLogin = () => {
  pageVisible.value = false
  funComponentList.LogIn({
    // content: "在request.ts触发的函数式组件",
    handleOk: props.handleOk,
    handleCancel: props.handleCancel,
    rightType: props.rightType,
    create_type: props.create_type,
    source: props.source,
  })
}

const goSignUp = () => {
  pageVisible.value = false
  funComponentList.SignUp({
    // content: "在request.ts触发的函数式组件",
    handleOk: props.handleOk,
    handleCancel: props.handleCancel,
    rightType: props.rightType,
  })
}

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}

const continueWith = async (state) => {
  firebaseUtils.logClickEvent(props.rightType === "generate" ? "newuser_login" : "login", {
    create_type: props.create_type,
    source: props.source,
    page_route: window.location.pathname,
  })
  const { data, code, msg } = await apiUser.userLogin({
    mode: "google",
    token: state.x_auth_token,
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    shopCartStore.getCartTotalCount()
    typeof props.handleOk === "function" && props.handleOk(true)
    pageVisible.value = false
  } else {
    typeof props.handleCancel === "function" && props.handleCancel()
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

onMounted(() => {
  console.log("orios.", props.create_type)
})
</script>

<style lang="scss" scoped>
.c-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    .content {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      background-image: url("@/assets/img/dialog-bg-1.webp");
      background-color: rgba(148, 173, 255, 0.3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      border-radius: 20px;
      .login-in-main {
        width: 100%;
      }
      .forget-password-footer {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0em;
        color: #ffffff;
        margin-bottom: 40px;
        span {
          color: #94adff;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mask {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 24px;
      .close-btn {
        display: none;
      }
    }
    .content {
      box-sizing: content-box;
      margin: 0;
      padding: 20px;
      width: 300px;
    }
    .content {
      .terms {
        margin-bottom: 0;
      }
    }
  }
}

.sign-up-block {
  padding: 14px 16px;
  text-align: center;
  height: 52px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 8px;
  cursor: pointer;

  font-size: 16px;
  font-weight: normal;
  line-height: 24px;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>
