<template>
  <div class="information-box" :class="`${createRenderType}-information-box`">
    <div class="title">{{ props.title || $t("store.infoLicense") }}</div>
    <el-form ref="ruleFormRef" :rules="rules" :model="ruleForm">
      <el-form-item>
        <div class="disabled olh">
          {{ $t("store.userid") }}: {{ userDataStore?.authData?.user_id }}
        </div>
      </el-form-item>
      <el-form-item>
        <div class="disabled olh">
          {{ $t("store.nickname") }}:
          {{ userDataStore?.userInfo?.user?.stage_name }}
        </div>
      </el-form-item>
      <el-form-item prop="full_name">
        <el-input
          v-model="ruleForm.full_name"
          :style="{
            '--el-fill-color-blank': 'transparent',
            '--el-border-width': '0',
            '--el-input-hover-border-color': 'red',
          }"
          :placeholder="$t('store.contactName') + '*'"
        />
      </el-form-item>
      <el-form-item v-if="$$language() == 'cn'" prop="address">
        <el-input
          v-model="ruleForm.address"
          :style="{ '--el-fill-color-blank': 'transparent' }"
          placeholder="身份证号码*"
        />
      </el-form-item>
      <el-form-item prop="email">
        <el-input
          v-model="ruleForm.email"
          :style="{ '--el-fill-color-blank': 'transparent' }"
          :placeholder="$t('store.email') + '*'"
        />
      </el-form-item>
      <el-form-item v-if="$$language() == 'en'" prop="address">
        <el-input
          v-model="ruleForm.address"
          :style="{ '--el-fill-color-blank': 'transparent' }"
          :placeholder="$t('store.address') + '*'"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineExpose, onMounted } from "vue";
import { getRealityUser, updateRealityUser } from "@/api/api/apiCommon.js";
import useUserDataStore from "@/store/userDataStore";
import { $$t, $$language } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});
const userDataStore = useUserDataStore();

const ruleFormRef = ref(null);
const ruleForm = reactive({
  full_name: "",
  email: "",
  address: "",
});

const validateName = (rule, value, callback) => {
  if (value === "") {
    callback(new Error($$t("store.requiredField")));
  } else {
    callback();
  }
};

const validateEmail = (rule, value, callback) => {
  if (value === "") {
    callback(new Error($$t("store.requiredField")));
  } else {
    callback();
  }
};

const validateAddress = (rule, value, callback) => {
  function validateID(id) {
  const pattern = /^[0-9Xx]+$/;
  return pattern.test(id);
  }
  if (value === "") {
    callback(new Error($$t("store.requiredField")));
  } else if ($$language() == "cn" && (value.length != 18 || !validateID(value) )) {
    callback(new Error("身份证号码输入有误，请重试"));
  } else {
    callback();
  }
};

const rules = reactive({
  full_name: [{ validator: validateName, trigger: "blur" }],
  email: [{ validator: validateEmail, trigger: "blur" }],
  address: [{ validator: validateAddress, trigger: "blur" }],
});

defineExpose({
  submitForm() {
    return new Promise((resolve, reject) => {
      const { full_name, email, address } = userDataStore.userRelData;
      if (
        !!ruleForm.full_name &&
        ruleForm.full_name == full_name &&
        ruleForm.email == email &&
        ruleForm.address == address
      ) {
        resolve({
          data: ruleForm,
          code: 200,
        });
      } else {
        ruleFormRef.value.validate((valid) => {
          if (valid) {
            updateRealityUser({ data: ruleForm })
              .then((res) => {
                if (res.code == 200) {
                  userDataStore.setUserRelData(ruleForm);
                  resolve(res);
                } else if (res.code == 6319) {
                  message.error({
                    content: $$t('library.textinvalid'),
                  })
                  reject('"some error descripetion"');
                } 
              })
              .catch(() => {
                reject('"some error descripetion"');
              });
          } else {
            reject('"some error descripetion"');
          }
        });
      }
    });
  },
});

const getDetail = () => {
  getRealityUser({}).then((res) => {
    if (res.code == 200) {
      const { full_name, email, address } = res.data;
      ruleForm.full_name = full_name || "";
      ruleForm.email = email || "";
      ruleForm.address = address || "";
      userDataStore.setUserRelData(res.data);
    }
  });
};

onMounted(() => {
  if (userDataStore.userRelData && userDataStore.userRelData.full_name) {
    const { full_name, email, address } = userDataStore.userRelData;
    ruleForm.full_name = full_name;
    ruleForm.email = email;
    ruleForm.address = address;
  } else {
    getDetail();
  }
  console.log(userDataStore.authData, "77777");
});
</script>

<style lang="scss">
.information-box {
  color: #fff;
  input {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
  }
  input::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .el-form {
    padding-bottom: 40px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__error {
    padding-top: 4px;
  }
  .el-form-item.is-error .el-input__wrapper {
    box-shadow: none;
    padding: 0;
  }

  .el-input__wrapper {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
  .el-input__wrapper:hover {
    box-shadow: none;
    border-radius: 0;
  }
  .el-input__wrapper.is-focus {
    box-shadow: none;
    border-radius: 0;
  }
}

.pc-information-box {
  .el-form-item__content {
    // padding: 12px 0;
    border-bottom: 1px solid rgba(216, 216, 216, 0.2);
  }
  .el-input__inner {
    height: 48px;
  }
  .disabled {
    height: 48px;
  }
}

.mobile-information-box {
  .el-form-item {
    margin-bottom: 16px;
  }
  .el-input__inner {
    height: 44px;
    border: 1px solid rgba(216, 216, 216, 0.4);
    background: rgba(3, 19, 41, 0.2);
    border-radius: 8px;
    padding: 0 12px;
  }
  .el-input__inner:focus {
    border: 1px solid #94adff;
  }
  .disabled {
    padding-left: 12px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    border: 1px solid rgba(216, 216, 216, 0.1);
    border-radius: 8px;
  }
}
</style>

<style lang="scss" scoped>
.information-box {
  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
  }
}
.mobile-information-box {
  .title {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
</style>
