<template>
  <el-tooltip
    ref="refTooltip"
    :disabled="props.disabled"
    effect="dark"
    :hideAfter="props.successContent ? 500 : 0"
    :placement="props.position"
    v-model:visible="renderData.visible"
    :manual="!!props.successContent"
    :popperClass="{
      'high-light-tooltip': !!props.successContent,
      [props.customClass ?? 'tooltip-default']: true,
    }"
  >
    <template v-slot:content>
      <slot name="content">{{
        !!props.successContent ? props.successContent : props.content
      }}</slot>
    </template>
    <slot></slot>
  </el-tooltip>
</template>
<script setup>
import { reactive, defineProps, defineEmits, computed, watch } from "vue";

import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
import { isMobileDevice } from "@/utils/tools.js";
const emits = defineEmits(["update:successContent"]);
const props = defineProps({
  customClass: {
    type: String,
    default: "",
  },
  content: {
    type: String,
    default: "",
  },
  successContent: {
    type: String,
    default: "",
  },
  position: {
    type: String,
    default: "top",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hideAfter: {
    type: Number,
    default: 3000,
  },
});
const refTooltip = ref(null);
const renderData = reactive({
  disabled: false,
  visible: false,
  state: 0,
});

const handleInitState = () => {
  renderData.visible = false;
  renderData.state = 0;
  clearTimeout(renderData.time);
  emits("update:successContent", "");
};

const initSuccessContent = () => {
  if (!!props.successContent) {
    renderData.visible = true;
    renderData.state = 1;
    nextTick(() => {
      clearTimeout(renderData.time);
      renderData.time = setTimeout(() => {
        handleInitState();
      }, 1000);
    });
  }
};

watch(
  () => {
    return props.successContent;
  },
  () => {
    initSuccessContent();
  }
);

watch(
  () => {
    return renderData.visible;
  },
  () => {
    if (isMobileDevice() && renderData.visible) {
      clearTimeout(renderData.timer);
      renderData.timer = setTimeout(() => {
        renderData.visible = false;
      }, 3000);
    }
    if (renderData.state == 1 && !renderData.visible) {
      handleInitState();
    }
  }
);
onMounted(() => {
  const refOpen = refTooltip.value?.onOpen;
  handleInitState();
});
</script>
<style lang="scss">
.el-popper {
  border-radius: 8px !important;
  padding: 4px 6px;
  box-sizing: border-box;
}
.el-popper.is-dark {
  background: rgba(246, 230, 255, 0.3) !important;
  backdrop-filter: blur(100px);

  border: 1px solid rgba(246, 230, 255, 0) !important;
  font-family: HarmonyOS Sans SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #ffffff;
  &.high-light-tooltip {
    background: #94adff !important;
    color: #161b1a;
  }
  .el-popper__arrow {
    display: none !important;
  }
}
</style>
