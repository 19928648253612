<template>
  <Dialog
    width="440px"
    height="580px"
    background="rgba(255, 255, 255, 0.2)"
    :customClass="`${createRenderType}-report-dialog`"
    :mobileRenderType="createRenderType == 'mobile' ? 'drawerDialog' : ''"
    :backgroundImage="false"
    :hideCloseBtn="createRenderType == 'mobile' ? false : true"
    :title="createRenderType == 'mobile' ? '举报' : ''"
    :visible="reasonVisible"
    @close="onCloseClick"
  >
    <div
      class="reason-box flex-column"
      :class="`${createRenderType}-reason-box`"
    >
      <div
        v-for="(item, index) in reasonList"
        :key="index"
        class="reason flex-space-between flex1"
      >
        <div class="select-img" @click="handleSelect(index)">
          <img
            v-if="selectedIdx == index"
            src="@/assets/img/report/selected.png"
            alt=""
          />
          <img v-else src="@/assets/img/report/no-select.png" alt="" />
        </div>
        <div class="flex1">
          <div class="info">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
      <div class="flex-space-between btn-box">
        <div class="btn cancel-btn flex-center pointer" @click="cancelReport">
          取消
        </div>
        <div class="btn report-btn flex-center pointer" @click="confirmReport">
          举报
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import apiCommon from "@/api/api/apiCommon.js";
import message from "@/components/functionCallComponent/message/message.js";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
});

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const emits = defineEmits(["update:visible"]);
const reasonVisible = ref(props.visible);
const selectedIdx = ref(0);
const reasonList = ref([
  {
    title: "政治敏感",
    desc: "歌曲中含有不当言论，危害社会秩序等",
    selected: false,
  },
  {
    title: "色情低俗",
    desc: "歌曲中含有淫秽色情、色情交友等低俗内容",
    selected: false,
  },
  {
    title: "广告营销",
    desc: "歌曲中含有营销宣传、广告导流内容",
    selected: false,
  },
  {
    title: "抄袭侵权",
    desc: "歌曲中含有未经授权搬运、抄袭等行为",
    selected: false,
  },
  {
    title: "诽谤造谣",
    desc: "歌曲中含有诽谤、歧视、造谣等不友善内容",
    selected: false,
  },
  {
    title: "其他违规",
    desc: "歌曲中含有其他违规内容",
    selected: false,
  },
]);

const onCloseClick = () => {
  reasonVisible.value = false;
  emits("update:visible", false);
};

const cancelReport = () => {
  onCloseClick();
  selectedIdx.value = 0;
};

const handleSelect = (index) => {
  selectedIdx.value = index == selectedIdx.value ? -1 : index;
};

const confirmReport = () => {
  if (selectedIdx.value == -1) return;
  apiCommon
    .reportMusic({
      t: 4,
      third_id: props.data.song_id,
      kind: selectedIdx.value + 1,
    })
    .then((res) => {
      if (res.code == 200) {
        onCloseClick();
        selectedIdx.value = 0;
        message.success({
          maskClosable: true,
          position: "top",
          content: "感谢您的举报，我们会尽快核实处理",
        });
      }
    });
};
</script>

<style lang="scss">
.mobile-report-dialog {
  .dialog-header {
    font-weight: 700;
  }
  .content-top-margin {
    height: 28px;
  }
  
}
</style>

<style lang="scss" scoped>
.reason-box {
  height: 100%;
  padding: 0 50px 40px;
  .reason {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .select-img {
      margin-right: 18px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .info {
      font-size: 16px;
      line-height: 20px;
    }
    .desc {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      opacity: 0.6;
    }
  }
  .btn-box {
    margin-top: 32px;
    .btn {
      width: calc((100% - 12px) / 2);
      height: 60px;
      border-radius: 78px;
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
    }
    .cancel-btn {
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .report-btn {
      background: #94adff;
      &:hover {
        opacity: 0.8;
      }
      color: #000;
    }
  }
}
.mobile-reason-box {
  padding: 0 16px 20px;
  .btn-box {
    .btn {
      height: 42px;
    }
  }
}
</style>