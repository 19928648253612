import axiosIS from "@/api/net/axios.js";
import { baseUrl } from "@/config/apiConfig.js";
const ApiConfig = {
  // 对象存储模块
  getCosCred: "/api/cos-cred",
  getCosPathKey: "/api/upload-path",
  getOssCred: "/api/misc/cos-token",
  getOssPathKey: "/api/oss-upload-path",
};
export default {
  getCosCred(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.getCosCred}`, data, {
      method: "get",
      preParams: data,
    });
  },
  getCosPathKey(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.getCosPathKey}`, data, {
      method: "post",
      preParams: data,
    });
  },
  getOssCred(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.getOssCred}`, data, {
      method: "get",
      preParams: data,
    });
  },
  getOssPathKey(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.getOssPathKey}`, data, {
      method: "post",
      preParams: data,
    });
  },
};
