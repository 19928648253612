/*
 * @author: lihaifa
 * @LastEditTime: 2024-03-15 18:00:48
 * @Description: 时间格式化函数
 * @FilePath: src/components/media/utils/clipUtil.js
 */
//浏览器先点击一下,为了保持当前浏览器为活动状态，否则部分由弹窗(附件上传)打不开对话框
// Browser.GetBrowserHost().SendMouseClickEvent(1, 1, MouseButtonType.Left, false, 1, CefEventFlags.None);
// import tools from '@/utils/js';

export const formatDate = (date, format) => {
  /* 通用化格式时间 */
  // example  formatDate(new Date('2023-6-10 00:00:000'), "当前日期为：YYYY-MM-DD，星期w，为第qq季度，时间为：hh:mm:ss:c")
  date = new Date(date);
  let o = {
    "M+": date.getMonth() + 1, // month  MM
    "D+": date.getDate(), // day  DD
    "h+": date.getHours(), // hour  hh
    "m+": date.getMinutes(), // minute mm
    "s+": date.getSeconds(), // second ss
    "q+": Math.floor((date.getMonth() + 3) / 3), // quarter 季度 q
    c: date.getMilliseconds(), // millisecond 毫秒 c
    w: ["一", "二", "三", "四", "五", "六", "日"][date.getDay() - 1], // week 星期 w
  };
  if (/(Y+)/.test(format)) {
    // year  YYYY
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};

export const isYesterday = (time) => {
  //昨天
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const today = `${year}/${month}/${day}`;
  const todayTime = new Date(today).getTime(); // 当天凌晨的时间
  const yesterdayTime = new Date(todayTime - 24 * 60 * 60 * 1000).getTime(); // 昨天凌晨的时间
  return time < todayTime && yesterdayTime <= time;
};

export const isCurrentWeek = (past) => {
  //当前日期 返回是否本周;
  const pastTime = new Date(past).getTime();
  const today = new Date(new Date().toLocaleDateString());
  let day = today.getDay();
  day = day == 0 ? 7 : day;
  const oneDayTime = 60 * 60 * 24 * 1000;
  const monday = new Date(today.getTime() - oneDayTime * (day - 1));
  const nextMonday = new Date(today.getTime() + oneDayTime * (8 - day));
  if (monday.getTime() <= pastTime && nextMonday.getTime() > pastTime) {
    return true;
  } else {
    return false;
  }
};

export const isToday = (date) => {
  //今天
  var d = new Date(date.toString().replace(/-/g, "/"));
  var todaysDate = new Date();
  if (d.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  } else {
    return false;
  }
};

// // 今天，昨天，本周返回星期几，之前返回年月日
// export const formatTime = (datetime, showTime = true) => {
//   const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
//   const date = new Date(datetime);
//   const today = new Date();
//   if (date.getDate() === today.getDate()) {
//     return showTime ? formatDate(date, 'Today hh:mm') : 'Today';
//   } else if (isYesterday(date)) {
//     return 'Yesterday' + (showTime ? formatDate(date, ' hh:mm') : '');
//   } else if (isCurrentWeek(date)) {
//     return showTime ? `${week[date.getDay() - 1]} ${formatDate(date, 'hh:mm')}` : `${week[date.getDay() - 1]}`;
//   } else {
//     if (date.getFullYear() === today.getFullYear()) {
//       return formatDate(date, showTime ? 'MM-DD hh:mm' : 'MM-DD');
//     } else {
//       return formatDate(date, showTime ? 'YYYY' : 'YYYY');
//     }
//   }
// };

export const formatTimeEn = (datetime, showTime = true) => {
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const date = new Date(datetime);
  const today = new Date();
  if (date.getDate() === today.getDate()) {
    return showTime ? formatDate(date, "Today hh:mm") : "Today";
  } else if (isYesterday(date)) {
    return "Yesterday" + (showTime ? formatDate(date, " hh:mm") : "");
  } else if (isCurrentWeek(date)) {
    return showTime
      ? `${week[date.getDay() - 1]} ${formatDate(date, "hh:mm")}`
      : `${week[date.getDay() - 1]}`;
  } else {
    if (date.getFullYear() === today.getFullYear()) {
      return formatDate(date, showTime ? "MM-DD hh:mm" : "MM-DD");
    } else {
      return formatDate(date, showTime ? "YYYY-MM-DD hh:mm" : "YYYY-MM-DD");
    }
  }
};

export const formatTimeCn = (datetime, showTime = true) => {
  const week = [
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
    "星期日",
  ];
  const date = new Date(datetime);
  const today = new Date();
  if (date.getDate() === today.getDate()) {
    return showTime ? formatDate(date, "今天 hh:mm") : "今天";
  } else if (isYesterday(date)) {
    return "昨天" + (showTime ? formatDate(date, " hh:mm") : "");
  } else if (isCurrentWeek(date)) {
    return showTime
      ? `${week[date.getDay() - 1]} ${formatDate(date, "hh:mm")}`
      : `${week[date.getDay() - 1]}`;
  } else {
    if (date.getFullYear() === today.getFullYear()) {
      return formatDate(date, showTime ? "MM-DD hh:mm" : "MM-DD");
    } else {
      return formatDate(date, showTime ? "YYYY-MM-DD hh:mm" : "YYYY-MM-DD");
    }
  }
};
export const formatDateTime = (timestamp, isCn) => {
  if (isCn) {
    if (
      formatDate(new Date(timestamp), "YYYY") == formatDate(new Date(), "YYYY")
    ) {
      return formatDate(new Date(timestamp), `MM-DD hh:mm`);
    }
    return formatDate(
      new Date(timestamp),
      isCn ? `YYYY-MM-DD hh:mm` : `YYYY MM DD hh:mm`
    );
  } else {
    const monthMap = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const month = formatDate(new Date(timestamp), "MM");
    const monthStr = monthMap[month];
    if (
      formatDate(new Date(timestamp), "YYYY") == formatDate(new Date(), "YYYY")
    ) {
      return formatDate(new Date(timestamp), `${monthStr} DD hh:mm`);
    }
    return formatDate(new Date(timestamp), `YYYY ${monthStr} DD hh:mm`);
  }
};
export default {
  formatDate,
  isYesterday,
  isToday,
  formatTimeCn,
  formatTimeEn,
  formatTime: (datetime, showTime, isCn) => {
    return isCn
      ? formatTimeCn(datetime, showTime)
      : formatTimeEn(datetime, showTime);
  },
  formatDateTime,
};
