import ApiConfig, { baseUrl } from "@/config/apiConfig.js";
import sseRequest from "@/api/net/sse.js";
export default {
  async lyricPolish({ params, onOpen, onMessage, onError, onClose }) {
    return await sseRequest({
      url: `${baseUrl}${ApiConfig.lyricPolish}`,
      params: params,
      onOpen,
      onMessage,
      onError,
      onClose,
    });
  },
  async lyricExtend({ params, onOpen, onMessage, onError, onClose }) {
    return await sseRequest({
      url: `${baseUrl}/api/sse/ugc/lyrics/extend`,
      params: params,
      onOpen,
      onMessage,
      onError,
      onClose,
    })
  },
  async generateWholeLyric({ params, onOpen, onMessage, onError, onClose }) {
    return await sseRequest({
      url: `${baseUrl}/api/sse/ugc/lyrics/generate`,
      params: params,
      onOpen,
      onMessage,
      onError,
      onClose,
    })
  },
  async doSseGetGenerate({ params, onOpen, onMessage, onError, onClose }) {
    return await sseRequest({
      method: "GET",
      url: `${baseUrl}${ApiConfig.sseGetGenerate}?${params}`,
      params: null,
      onOpen,
      onMessage,
      onError,
      onClose,
    });
  },
};
