import { createApp } from "vue"
import AuthorizeDialog from "./authorizeDialog.vue"

export default function ShowAuthorizeDialog(options) {
  // 创建一个节点，并将组件挂载上去
  const mountNode = document.createElement("div")
  document.body.appendChild(mountNode)
  const app = createApp(AuthorizeDialog, {
    ...options,
    visible: true,
    remove() {
      app.unmount(mountNode) //创建完后要进行销毁
      document.body.removeChild(mountNode)
    },
  })
  return app.mount(mountNode)
}
