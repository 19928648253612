export const baseUrl = import.meta.env.VITE_API_URL;
const ApiConfig = {
  // 登陆模块
  loginIn: "/api/user/login",
  loginOut: "/api/user/logout",
  forgetPassword: "/api/user/password/lost",
  resetPasswordAuth: "/api/user/password",
  signUp: "/api/user/register",
  sendEmail: "/api/user/login/otp/email",
  getUserInfo: "/api/user/profile",


  // 对象存储模块
  getCosCred: "/api/cos-cred",
  getCosPathKey: "/api/upload-path",
  getOssCred: "/api/misc/cos-token",
  getOssPathKey: "/api/oss-upload-path",
  
  // 生成模块
  createMusic: "/api/inf/feed/generate",
  /// sse 获取生成音乐状态
  sseGetGenerate: "/api/sse/subscribe",
  sendPoint: "/api/inf/report",

  /// sse 获取生成音乐状态
  sseGetGenerate: "/api/sse/subscribe",
  /// 获取feed列表
  getBanerConfig: '/api/pgc/banner/list'
};

export default ApiConfig;
