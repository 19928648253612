import {  getGlobalCosClient, initCos, uploadFileToCos, abortUploadFileByCos } from './cos/cos-web.js'
import {  getGlobalOssClient, initOss, uploadFileToOss, abortUploadFileByOss} from './oss/oss-web.js'
import config from '@/config/config.js'
import { getUuid, getFileExtension, urlIncludeDomain } from '@/utils/tools.js';
import objectStorageApi from './objectStorageApi.js';

const isCos = config.curObjectStorageType == 'COS';
const curObjectStorageExpirTimeForwardTo = config.curObjectStorageExpirTimeForwardTo;

const  CredentialMap={
  cos: null,
  oss: null
} 

const  CredentiaExpirTime={
  getCosCred:()=>{
    return new Promise((resolve,reject)=>{
      if(CredentialMap.cos==null || (new Date().getTime() + curObjectStorageExpirTimeForwardTo) > (CredentialMap.cos.expired_timestamp*1000)){
        objectStorageApi.getCosCred().then((resCosCred)=>{
          if(resCosCred.code == 200){
            CredentialMap.cos = resCosCred.data;
            resolve(CredentialMap.cos)
          }else{
            reject(resCosCred.msg)
          }
        }).catch(e=>{
          reject(e)
        })
        
      }else{
        resolve(CredentialMap.cos)
      }
    })
  },
  getOssCred: ()=>{
    return new Promise((resolve,reject)=>{
      if(CredentialMap.oss==null || (new Date().getTime() + curObjectStorageExpirTimeForwardTo) > (CredentialMap.oss.expired_timestamp*1000)){
        objectStorageApi.getOssCred().then((resOssCred)=>{
          if(resOssCred.code == 200){
            CredentialMap.oss = resOssCred.data;
            resolve(CredentialMap.oss)
          }else{
            reject(resOssCred.msg)
          }
        }).catch(e=>{
          reject(e)
        })
        
      }else{
        resolve(CredentialMap.oss)
      }
    })
    
  },
}

export const initObjectStorageClient = isCos ? initCos : initOss;
export const uploadFileToObjectStorage = (file, _callback =()=>{})=>{
  return new Promise(async (resolve,reject)=>{
    if(isCos){
      try{
        const resCosCred = await CredentiaExpirTime.getCosCred();
        const filename = getUuid()+'.'+ file.name.split('.').pop();
        const pathKey= resCosCred.allow_prefix+filename;
        const fullUrl = config.baseObjectStorageUrl+pathKey;
        uploadFileToCos(file, pathKey, _callback, resCosCred).then(res=>{
          resolve({
            pathKey: pathKey,
            fullUrl: fullUrl,
          });
        }).catch(e=>{
          reject(e);
        })
      } catch(e){
        reject(e)
      }
    }else{
      try{
        const resOssCred = await CredentiaExpirTime.getOssCred();
        const filename = getUuid()+'.'+ file.name.split('.').pop();
        const pathKey= resOssCred.allow_prefix+filename;
        const fullUrl = config.baseObjectStorageUrl+pathKey;
        uploadFileToOss(file, pathKey , _callback, resOssCred).then(res=>{
          resolve({
            pathKey: pathKey,
            fullUrl: fullUrl,
          });
        }).catch(e=>{
          reject(e);
        })
      } catch(e){
        reject(e)
      }
    }
  });
  
}

export const getGlobalObjectStorageClient = isCos ? getGlobalCosClient : getGlobalOssClient

export const abortUploadFileToObjectStorage = isCos ? abortUploadFileByCos : abortUploadFileByOss

export const  getObjectStorageFullUrl = (url)=>{
  if(urlIncludeDomain(url)){
    return url;
  }
  return config.baseObjectStorageUrl+url;
} 

export default {
  initObjectStorageClient,
  uploadFileToObjectStorage,
  getGlobalObjectStorageClient,
  abortUploadFileToObjectStorage,
  getObjectStorageFullUrl
}



// let myModule;
// if (condition) {
//   myModule = await import('./moduleA.js');
// } else {
//   myModule = await import('./moduleB.js');
// }

// // 使用 myModule
// console.log(myModule);
// let moduleToExport;

// if (condition) {
//   moduleToExport = { /* export something */ };
// } else {
//   moduleToExport = { /* export something else */ };
// }
// export default moduleToExport;