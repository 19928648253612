<template>
  <div class="withdraw-modal" v-if="pageVisible">
    <div class="mask">
      <div class="content">
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="withdraw">
          <div class="img-box"><img src="@/assets/img/ic_withdraw.png" alt="" /></div>
          <div class="text-box">{{ $$t("library.TheWithdrawalFeature") }}</div>
          <div class="money">
            <span>{{ $$t("library.$")}}</span> {{ props.money }}
          </div>
          <div class="text">{{ $$t("library.Myearnings") }}</div>
          <div class="btn-box">
            <div class="btn pointer" @click="closePage">{{ $$t("library.IKnew") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from "vue"
import useUserDataStore from "@/store/userDataStore"
import apiUser from "@/api/api/apiUser.js"
import { $$language, $$t } from "@/i18n/i18n.js"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  money: {
    type: Number,
    default: 0,
  },
})
const userDataStore = useUserDataStore()
const emits = defineEmits([])
const ruleFormRef = ref(null)

const pageVisible = ref(false)
pageVisible.value = props.visible
// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const ruleForm = reactive({
  username: userDataStore?.userInfo?.user?.stage_name ?? "", // 'test123',
})

const closePage = () => {
  pageVisible.value = false
}

const onSubmit = async () => {
  const { username } = ruleForm
  console.log("username", username)
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const { data, code, msg } = await apiUser.updateUserInfo({
        user: {
          stage_name: username,
          profile_image: "",
        },
        // profile_image: '',
      })
      if (code === 200) {
        closePage()
      }
    } else {
      console.log("error submit!", fields)
    }
  })
}

const update = (key, val) => {
  ruleForm[key] = val
}

onMounted(() => {
  console.log("userInfo onMounted")
})
</script>

<style lang="scss" scoped>
.withdraw-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    max-width: 360px;
    padding: 0 30px;
    background: rgba(30, 32, 33, 0.6);
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .withdraw {
      .img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .text-box {
        padding: 16px 0 25px;
        text-align: center;
      }
      .money {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 15px;
        span {
          font-weight: 500;
          font-size: 20px;
        }
      }
      .text {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 24px;
      }
      .btn-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        .btn {
          width: 120px;
          height: 44px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 60px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mask{
      align-items: end;
      .content{
        border: none;
        max-width: 100%;
        width: 100%;
        border-radius: 32px 32px 0 0;
        background: rgba(71, 74, 74, 0.5);
        backdrop-filter: blur(100px);
      }
    }
  }
}
</style>
