

import OSS from "ali-oss";
import {  getType,getUuid } from "@/utils/tools.js";

let globalOssClient = null;
let globalOssSecretInfor = null;

export const getGlobalOssClient = (ossSecretInfor) => {
  if(getType(ossSecretInfor)=='Object'){
    initOss(ossSecretInfor)
  }
  return globalOssClient;
}

// oss调用
const newOss = (ossSecretInfor) => {
  const {tmp_secret_id, tmp_secret_key, session_token,  region, bucket}  = ossSecretInfor;
  return new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: region,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: tmp_secret_id,
    accessKeySecret: tmp_secret_key,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: session_token,
    // 填写Bucket名称，例如examplebucket。
    bucket: bucket,
  });
};
// Todo优化错误返回数据和错误类型，方便业务判断

const checkErrorType = (error) => {
  return error;
}

export const initOss = (ossSecretInfor) => {
  console.log("initOss", ossSecretInfor)
  globalOssSecretInfor = ossSecretInfor
  globalOssClient = newOss(ossSecretInfor)
}

/**
 * 上传上次选的文件, 文件地址路径，进度等回掉， 初始化oss密钥等信息
 *
 * _callback({code,progress,abortCallback})
 * progress 进度
 * code 0,开始上传, 1, 回掉进度
 * abortCallback 中断上传回掉
 *
 * 返回 promise  resolve(url), reject(error)
 */

const ossAbortCheckpointMap={
  
}

export const abortUploadFileByOss =(abortId) => {
  if(ossAbortCheckpointMap[abortId]){
    console.log("abortUploadFileByOss", abortId);
    if(ossAbortCheckpointMap[abortId].abort!=null){
      ossAbortCheckpointMap[abortId].abort();
    }else{
      ossAbortCheckpointMap[abortId].abortState=1;
    }
    delete ossAbortCheckpointMap[abortId];
  }else{
    ossAbortCheckpointMap[abortId]={
      abortState: 1
    }
  }
}

export const uploadFileToOss = (file, pathkey, _callback, ossSecretInfor) => { // 上传文件
  const callbackIsFn = typeof _callback == "function";
  // 设置中断点。
  let abortCheckpoint;
  // 中断分片上传。
  let abortCallback=null;
  return new Promise((resolve, reject) => {
    try {
      if(getType(ossSecretInfor)=='Object'){
        initOss(ossSecretInfor);
      }
      if(globalOssClient==null){
        reject(checkErrorType("oss is not initialized"))
      }
      let progress = 0;
      const { bucket, region, appId } = globalOssSecretInfor;
      const abortId = getUuid();
      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        "Cache-Control": "no-cache",
        // // 指定该Object被下载时的名称。
        // "Content-Disposition": "example.txt",
        // 指定该Object被下载时的内容编码格式。
        // "Content-Encoding": "utf-8",
        // 指定过期时间，单位为毫秒。
        "Expires": 60 * 1000 * 10,
        // 指定Object的存储类型。
        // "x-oss-storage-class": "Standard",
        // // 指定Object标签，可同时设置多个标签。
        // "x-oss-tagging": "Tag1=1&Tag2=2",
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        // "x-oss-forbid-overwrite": "true",
        "Content-Disposition": "inline",
        "x-oss-force-download":false,
        "Content-Type": file.type
      };
      const callbackObj={
        abortId:abortId,
        abort:()=>{
          abortUploadFileByOss(abortId)
        }
      };
      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          progress = p;
          abortCheckpoint= cpt;
          ossAbortCheckpointMap[abortId] = Object.assign(
            ossAbortCheckpointMap[abortId]??{},
            cpt
              ? {
                  abort(){
                    globalOssClient?.abortMultipartUpload(
                      cpt.name,
                      cpt.uploadId
                    );
                  },
                  uploadId: cpt.uploadId,
                } 
              : {}
            )
          if(ossAbortCheckpointMap[abortId].abortState==1){
            ossAbortCheckpointMap[abortId].abort();
          }
          callbackIsFn && _callback(
            Object.assign(
              callbackObj,
              {
                progress,
                code: 1,
              }
            )
          );
        },
        // 设置并发上传的分片数量。
        parallel: 6,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024 * 2,
        headers,
        // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
        meta: { },
        // mime: "text/plain",
        endpoint: globalOssSecretInfor.endpoint
      };
      callbackIsFn && _callback(
        Object.assign(
          callbackObj,
          {
            progress, 
            code: 0,
          }
        )
      );
      globalOssClient.multipartUpload(pathkey, file, options).then((data) => {
        let url = data.res.requestUrls[0].split('?').shift();
        console.log(data,url );
        resolve(url)
      }).catch((err) => {
        reject(checkErrorType(err));
      })
    }catch(e){
      reject(checkErrorType(e));
    }
  })
};
export default {
  getGlobalOssClient,
  initOss,
  uploadFileToOss,
  abortUploadFileByOss
}