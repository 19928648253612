// 审核弹框js调用   Author:Titans

import { getUuid } from "../../../utils/tools";
import ActionDialog from "./Action.vue";

const dialogBox = document.createElement("div");
dialogBox.classList.add("dialog-body-box");
document.body.appendChild(dialogBox);

const actionDialogMap = {};
function _showActionDialog(options) {
  // 传入组件，组件属性、事件
  const tag = options.tag ?? getUuid();
  let dialogEl = null;
  actionDialogMap[tag] = () => {
    document.body
      .querySelector(`.dialog[data-dialog-tag="${tag}"]`)
      ?.classList.remove(`render-visible--true`);
    setTimeout(() => {
      app.unmount();
      actionDialogMap[tag] = null;
    }, 0.15);
  };

  const { confirm, cancel, close, ...others } = options;

  const app = createApp(ActionDialog, {
    ...others,
    visible: true,
    tag,
    onClose() {
      if (close) {
        close(() => {
          actionDialogMap[tag]();
        });
      } else {
        actionDialogMap[tag]();
      }
    },
    onConfirm() {
      options.confirm(() => {
        if (actionDialogMap[tag]) {
          actionDialogMap[tag]();
        }
      });
    },
    onCancel() {
      if (cancel) {
        cancel(() => {
          actionDialogMap[tag]();
        });
      } else {
        actionDialogMap[tag]();
      }
      // 卸载元素
    },
  });
  app.mount(dialogBox);
  return actionDialogMap[tag];
}
export default _showActionDialog;

export const showActionDialog = _showActionDialog;
export const closeActionDialog = (tag) => {
  if (actionDialogMap[tag]) {
    actionDialogMap[tag]();
    delete actionDialogMap[tag];
  }
};
export const closeAllActionDialog = () => {
  Object.keys(actionDialogMap).forEach((tag) => {
    if (actionDialogMap[tag]) {
      actionDialogMap[tag]();
      delete actionDialogMap[tag];
    }
  });
};
// 调用示例
// showActionDialog({
//   content: '',
//   confirm(msg) {
//     console.log('auditMsg', msg)
//   },
//   cancel() {

//   }
// })
