import { getUuid, isProd, isMobileDevice, getUrlAllParams } from "../tools.js"
import { formatDate } from "../dateTime.js"
import { firebaseInit, firebaseLogEvent, firebaseSetUserId } from "@/utils/analytic/firebaseWrapper.js"
import useUserDataStore from "@/store/userDataStore.js"
import sa from "sa-sdk-javascript"
import { getUseSource } from "@/api/net/config.js"

export const firebaseUtils = {}

export default {
  install: (app, options) => {
    let origin = "mureka"
    let page
    let referrer = document.referrer
    var userDataStore = useUserDataStore()
    const env = import.meta.env.VITE_LANGUAGE ?? "en"
    const firebaseConfig =
      env == "en"
        ? {
            apiKey: "AIzaSyBcGnuedT7WsyKn05iZphkjIjsAJt-xkz0",
            authDomain: "mureka-35a18.firebaseapp.com",
            projectId: "mureka-35a18",
            storageBucket: "mureka-35a18.appspot.com",
            messagingSenderId: "247728744599",
            appId: "1:247728744599:web:18ebbaca5995b7ae8ba1d2",
            measurementId: "G-FY4GZWS3Q4",
          }
        : {
            apiKey: "AIzaSyDI8gyZooU0wyjzYHxWniOv5IKGIgs-E1A",
            authDomain: "yinhe-4c15a.firebaseapp.com",
            projectId: "yinhe-4c15a",
            storageBucket: "yinhe-4c15a.appspot.com",
            messagingSenderId: "347971865148",
            appId: "1:347971865148:web:6ce2d14e69796d93175139",
          }
    firebaseInit(firebaseConfig)
    const pointInitFunc = async function (_page, _origin = "mureka") {
      const userId = userDataStore?.authData?.user_id ?? ""
      firebaseSetUserId(userId ?? "")
      page = _page
      origin = _origin ?? "mureka"
      referrer = document.referrer
    }
    const mureka_url = `https://sa.yinhe.top/sa?project=${isProd() ? "mureka" : "mureka-test"}&token=Ehp5cUw99q6lSxOm4`
    const yinhe_url = ` https://sa.yinhe.top/sa?project=${isProd() ? "yinhe" : "yinhe-test"}&token=5EhKAvujgjKFxOm4`
    sa.init({
      server_url: env == "en" ? mureka_url : yinhe_url, // 替换成你的神策数据接收地址
      is_track_single_page: true,
      heatmap: {
        clickmap: "not_collect",
        scroll_notice_map: "default",
      },
    })
    sa.quick("autoTrack")

    const getAnonymousID = async function () {
      return await sa.quick("getAnonymousID")
    }

    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(name)
    }

    const assignParams = (params, eventType) => {
      const useSource = getUseSource()
      return Object.assign(params, {
        origin,
        referrer,
        // userId: userId.toString(),
        traceid: getUuid(),
        // source: page,
        date: formatDate(new Date(), "YYYY-MM-DD hh:mm:ss:c"),
        event: eventType ?? "click",
        source_type: useSource ? useSource.split("_")[0] : "",
        ad_source: useSource ?? "",
        isMobileDevice: isMobileDevice(),
        ad_bd_vid: getBdVid() ?? "",
      })
    }

    const getBdVid = () => {
      const adSourceStorage = window.sessionStorage.getItem("ad_source")
      if (!adSourceStorage) return null

      const { bd_vid } = JSON.parse(adSourceStorage)
      return bd_vid || null
    }

    // 页面或组件展示打点
    const logViewEvent = async function (eventName, params = {}) {
      const log_type = "show"
      firebaseLogEvent(`${origin}_${eventName}_${log_type}`, assignParams(params, log_type))
      sa.track(`${origin}_${eventName}_${log_type}`, assignParams(params, log_type))
    }

    // 点击事件打点
    const logClickEvent = async function (eventName, params = {}) {
      const log_type = "click"
      if (params?.content_id != undefined) {
        params.content_id = params?.content_id.toString()
      }
      firebaseLogEvent(`${origin}_${eventName}_${log_type}`, assignParams(params, log_type))
      sa.track(`${origin}_${eventName}_${log_type}`, assignParams(params, log_type))
    }

    const logLoginEvent = async function (userId) {
      sa.login(userId.toString())
    }
    // 页面曝光埋点  page: 页面名称，origin: 页面入口：董老师的传mureka 慕阳 的传create
    app.config.globalProperties.logPageMounted = async function (page, origin, params = {}) {
      if (params?.content_id != undefined) {
        params.content_id = params?.content_id.toString()
      }
      pointInitFunc(page, origin, params)
      logViewEvent(page, params)
    }
    // 组件曝光 入参 埋点名
    app.config.globalProperties.logViewEvent = logViewEvent
    // 点击事件 入参 埋点名
    app.config.globalProperties.logClickEvent = logClickEvent

    Object.assign(firebaseUtils, {
      logViewEvent,
      logClickEvent,
      logLoginEvent,
      getAnonymousID,
    })
  },
}
