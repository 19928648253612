// 相关 response data状态码  https://dev.skymusic.ai/defs/errors.go
import axios from "axios";
import { getPublicHeader, paramFormat, interfaceSpecification } from "./config";
import Router, { isNoAuthenticatedToLogin } from "@/router/index";
import useUserDataStore from "@/store/userDataStore";
import { $$t } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message.jsx";
import tools from "@/utils/tools";
import globalConfig from "@/config/config.js";
// import stringify from 'qs-stringify';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  async (config) => {
    var publicHeader = await getPublicHeader();
    config.headers = {
      ...config.headers,
      ...publicHeader,
      "X-Platform": globalConfig.platform,
      // 'X-Timestamp': new Date().getTime(),
    };
    return config;
  },
  (error) => {
    console.log("请求超时");
    return Promise.reject(error);
  }
);

const notMacthUrlList = [
  "/api/user/profile",
  "api/pgc/prompt/ez-mode/list",
  "api/pgc/feed/list",
  "api/pgc/feed/list/search",
];

axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const res = response?.data ?? {};
    if (res.code == 0) {
      res.code = 200
    } else if(res.code == 500){
      message.error({
        maskClosable: true,
        position: "top",
        content: $$t("common.serverResponding"),
      })
    }
    return res;
  },
  (err) => {
    // 对响应错误做点什么
    console.error("接口错误=>", err);
    if (err.response && err.response.status) {
      const userDataStore = useUserDataStore();
      switch (err.response.status) {
        case 401: // 用户身份过期, 清空用户信息, 变更为未登录状态
          // const err_url = err.config.url.match(/https?:\/\/[^/]+(\/[^?#]*)?/)?.[1] || ""
          const containsNotMatch = notMacthUrlList.some((item) =>
            err.config.url.includes(item)
          );
          if (containsNotMatch) {
            userDataStore.clearAll();
            return {
              code: 401,
              msg: "User identity has expired, please log in again",
              data: err.response.data?.data,
            };
          }
          // message.error({
          //   maskClosable: true,
          //   position: "top",
          //   content: $$t("common.loginOut"),
          // })
          userDataStore.clearAll();
          userDataStore.checkLoginStatus();
          return {
            code: 401,
            msg: "User identity has expired, please log in again",
            data: err.response.data?.data,
          };
        case 429: // 用户身份过期, 清空用户信息, 变更为未登录状态
          if (err.response.data?.code == 9008) {
            message.error({
              maskClosable: true,
              position: "top",
              content: $$t("common.tooFrequently"),
            });
            return {
              code: 9008,
              msg: err.response.data?.msg,
              data: err.response.data?.data,
            };
          }
        default:
          break;
      }
    }
    if (err.code == "ERR_NETWORK") {
      let config = err.config;
      // config.retry = 3;
      config.__retryCount = config.__retryCount || 0;
      config.retryDelay = config.retryDelay ?? 5000;
      if (!config?.retry || config.__retryCount >= config.retry) {
        return {
          code: -200,
          msg: "无法连接，请检查你的网络状态",
        };
      }
      config.__retryCount += 1;
      config.__backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, config.retryDelay);
      });
      return config.__backoff.then(function () {
        return axios(config);
      });
    } else {
      if (err?.response?.data?.code == 500) {
        message.error({
          maskClosable: true,
          position: "top",
          content: $$t("common.serverResponding"),
        });
      }
      return err.response?.data;
    }
  }
);

const axiosDef = (
  url,
  data = {},
  {
    method = "get",
    headers = {},
    timeout = 300000,
    cancelToken = null,
    ...others
  } = {}
) => {
  data = paramFormat(data ?? {});
  const params = method === "get" ? { params: data } : { data: data };
  return axios({
    method,
    url,
    cancelToken,
    headers,
    timeout,
    ...others,
    ...params,
  });
};

// axiosInterfaceSpecification
export default (...arg) => {
  return new Promise(async (resolve, reject) => {
    // if (arg[2] && arg[2].method == "post") {

    //   resolve({
    //     code: 401,
    //     msg: "User identity has expired, please log in again",
    //     data: err.response.data?.data,
    //   });
    // }
    axiosDef(...arg)
      .then((res) => {
        resolve(interfaceSpecification(res));
      })
      .catch((err) => {
        reject(err);
      });
  });
};
