/*
 * @author: lihaifa
 * @LastEditTime: 2024-03-15 18:00:48
 * @Description: 数据转换函数
 * @FilePath: src/components/media/utils/clipUtil.js
 */
export const treeToListMap= (data, options = { // 树形转平行
  mapKey: 'code',
  childrenField: 'children',
  // extendLeveNoField: '__levelno',
  // extendIsleafField: '__isleaf',
  // extendParentField: '__parent',
}, result = { map: {}, list: [] }, extendData= {extendLevelNo: 1, extendParent:null}) => {
  const { extendLevelNo= 1, extendParent= null, } = extendData;
  const { mapKey="code", childrenField="children", extendLeveNoField,extendIsleafField, extendParentField, } = options
  data.forEach(function (item) {
    result.list.push(item)
    result.map[item[mapKey]] = item;
    if (!!extendLeveNoField) {
      item[extendLeveNoField] = extendLevelNo;
    }
    if(!!extendParentField){
      item[extendParentField] = extendParent;
    }
    if (item[childrenField] && Array.isArray(item[childrenField]) && item[childrenField].length) {
      treeToListMap(item[childrenField], options, result, { extendLevelNo: item[extendLeveNoField] + 1, extendParent: item})
      if (!!extendIsleafField) {
        item[extendIsleafField] = false
      }
    } else {
      if (!!extendIsleafField) {
        item[extendIsleafField] = true
      }
    }
  })
  return result
}
export const listToTreeByNestCodeLength = (data, nestCodeKey) => { // 平行数据 根据nestCodeKey 长度 生成嵌套数据
  let map = {} // 构建map
  data.forEach(function (item) {
    map[item[nestCodeKey]] = item
    item.children = []
  })
  let treeData = []
  data.forEach(item => {
    let pnestCodeKey = item[nestCodeKey]
    pnestCodeKey = pnestCodeKey.slice(0, pnestCodeKey.length - 1)
    let mapitem = map[item[pnestCodeKey]]
    while (!mapitem && pnestCodeKey !== '') {
      pnestCodeKey = pnestCodeKey.slice(0, pnestCodeKey.length - 1)
      mapitem = map[pnestCodeKey]
    }
    if (mapitem) {
      (mapitem.children || (mapitem.children = [])).push(item)
    } else {
      treeData.push(item)
    }
  })
  return treeData
}

export const  treeAddNestCode= (data, options={
  addNestCodeField: "code", 
  levelCodeLength: 3,
  startLevelCode: '100',
  childrenField: 'children'
}, pcode) => { // 树形数据添加带层级code
  const { addNestCodeField="code", levelCodeLength="3", startLevelCode="100", childrenField="children" } = options
  data.forEach((item, index)=> {
    item[addNestCodeField] =pcode ? `${pcode??''}${String(index).padStart(levelCodeLength,0)}` : `${+startLevelCode+index}`
    if(item[childrenField] && Array.isArray(item[childrenField]) && item[childrenField].length) {
      treeAddNestCode(item[childrenField], options, item[addNestCodeField])
    }
  })
  return data
}


export default {
  treeToListMap,
  listToTreeByNestCodeLength,
  treeAddNestCode,
}