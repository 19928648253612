import { defineStore } from "pinia";
import { getCartList, checkoutCart, deleteCart } from "@/api/api/apiStore.js";
import useShopCartStore from "@/store/shopCartStore.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js";

const useCartListData = defineStore({
  id: "cartListStoreData",
  state: () => {
    return {
      list: [],
      selectList: [],
      total: 0,
      totalPrice: 0,
      isAll: true,
      scrollHeight: 0
    };
  },
  actions: {
    // 选择或取消选择单个
    selectOne(id) {
      this.list.forEach((item) => {
        if (item.song.id == id) {
          item.song.isChecked = !item.song.isChecked;
        }
      });
      this.showAll();
      this.calculatePrice();
    },
    // 全选或者取消全选
    selectAllOrNot() {
      this.list.forEach((item) => {
        if (item.song.cart_state != 2) {
          item.song.isChecked = this.isAll;
        }
      });
      this.calculatePrice();
    },

    // 修改全选按钮
    changeIsAll() {
      console.log("gaibianle")
      this.isAll = !this.isAll
    },

    // 删除掉移项并且重新计算价格
    updateList(invalIds) {
      this.list = this.list.filter((item) => !invalIds.includes(item.song.id));
      this.calculatePrice();
    },

    // 计算价格和总数
    calculatePrice() {
      function getPrecision(num) {
        const str = String(num);
        const decimalIndex = str.indexOf('.');
        return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1;
      }
      function add(a, b) {
        const precision = Math.max(getPrecision(a), getPrecision(b));
        const multiplier = Math.pow(10, precision);
        return (Math.round(a * multiplier) + Math.round(b * multiplier)) / multiplier;
      }
      let count = 0;
      let price = 0;
      this.selectList = [];
      this.list.forEach((item) => {
        if (item.song.isChecked) {
          count++;
          price = add(price, item.price);
          this.selectList.push(item.song.id);
        }
      });
      this.total = count;
      this.totalPrice = price;
    },

    showAll() {
      let count = 0;
      let total = 0;
      this.list.forEach((item) => {
        if (item.song.cart_state != 2) total++;
        if (item.song.isChecked) {
          count++;
        }
      });
      console.log(count, total, "yyyyy")
      if (count == total && total != 0) {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },

    // 获取列表
    getList() {
      return new Promise((resolve, reject) => {
        getCartList({}).then((res) => {
          if (res.code == 200) {
            resolve('')
            const { data } = res;
            if (!data.list) {
              this.list = []
              return
            }
            // 购物车里有效数据勾选上
            data?.list.forEach((item) => {
              if (item.song.cart_state != 2) {
                item.song.isChecked = true;
              }
            });
            this.list = data.list
            this.showAll()
            this.calculatePrice();
          } else {
            reject('')
          }
        });
      })

    },

    // 从购物车移除
    doDelete(item) {
      const shopCartStore = useShopCartStore()
      const globalPlayDataStore = useGlobalPlayDataStore();
      deleteCart({
        lib_song_ids: [item.song.id],
      }).then((res) => {
        if (res.code == 200) {
          /**
           *  1.同步商城购物车状态
           *  2.同步全局播放器购物车状态
           *  3.重新计算价格
           *  4.获取购物车数量
           */
          shopCartStore.getCartTotalCount();
          this.updateList([item.song.id]);
          shopCartStore.updateShopCart(item.song.song_id, 3);
          globalPlayDataStore.updatePlayDataItem({
            ...item.song,
            cart_state: 3,
          })
        }
      });
    },

    // 为全局播放器提供的添加操作
    addIntoCartList(data) {
      const shopCartStore = useShopCartStore()
      let index = this.list.findIndex(
        (itemF) => itemF.song?.id == data.song?.id
      );
      if (index > -1) {
        Object.assign(this.list[index], {
          ...this.list[index],
          ...data,
        });
        this.list = [].concat(this.list);
      } else {
        data.song.isChecked = true
        this.list = [{
          ...data,
        }].concat(this.list)
      }
      this.calculatePrice();
      shopCartStore.getCartTotalCount();
      // shopCartStore.updateShopCart(item.song.song_id, 3);
    },
    // 
    updateScrollTop(height) {
      this.scrollHeight = height
    }
   
  }
})

export default useCartListData;