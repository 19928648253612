<template>
  <div class="waveform-canvas-container" ref="refWaveformCanvasBox">
    <canvas
      ref="refWaveformCanvas"
      :width="props.renderBoxSize.width"
      :height="props.renderBoxSize.height"
    ></canvas>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
} from "vue";

const defaultConfig = {
  spacingSize: 2,
  wavebarWidth: 1,
  defaultColor: "rgba(255, 255, 255, 1)",
  progressColor: "#D282FF",
  selectAreaColor: "#94ADFF",
};
const refWaveformCanvas = ref(null);
const refWaveformCanvasBox = ref(null);
const props = defineProps({
  waveBarData: {
    type: Array,
    default: () => [],
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  activeMinIndex: {
    type: Number,
    default: 0,
  },
  renderBoxSize: {
    type: Object,
    default: () => ({
      width: 0,
      height: 0,
    }),
  },
  slecteAreaIndex: {
    type: Object,
    default: () => ({
      startIndex: 0,
      endIndex: 0,
    }),
  },
});
const emits = defineEmits([]);
const renderData = reactive({
  config: {},
});
const initConfig = () => {
  renderData.config = Object.assign({}, defaultConfig, props.config);
};
const drawRoundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};
const drawWaveform = () => {
  const canvas = refWaveformCanvas.value;
  const ctx = canvas.getContext("2d");
  const { waveBarData, activeIndex, activeMinIndex, slecteAreaIndex } = props;
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const { config } = renderData;
  const {
    spacingSize,
    wavebarWidth,
    defaultColor,
    progressColor,
    selectAreaColor,
  } = renderData.config;

  ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  waveBarData.forEach((item, index) => {
    const barHeight = item;
    let color =
      index > slecteAreaIndex.startIndex && index <= slecteAreaIndex.endIndex
        ? selectAreaColor
        : defaultColor;
    color =
      activeIndex > index
        ? index >= activeMinIndex
          ? progressColor
          : color
        : color;
    ctx.fillStyle = color;
    // console.log(
    //   "drawRoundedRect",
    //   waveBarData.length,
    //   index,
    //   index * (wavebarWidth + spacingSize),
    //   (canvasHeight - barHeight) / 2
    // );
    drawRoundedRect(
      ctx,
      index * (wavebarWidth + spacingSize),
      (canvasHeight - barHeight) / 2,
      wavebarWidth,
      barHeight,
      0
    );
  });
};
watch(
  () => {
    return props.waveBarData;
  },
  () => {
    drawWaveform();
  }
);
watch(
  () => {
    return props.activeIndex;
  },
  () => {
    drawWaveform();
  }
);
watch(
  () => {
    return props.renderBoxSize;
  },
  () => {
    drawWaveform();
  },
  {
    deep: true,
  }
);
onMounted(() => {
  initConfig();
  drawWaveform();
});
</script>
<style lang="scss">
.waveform-canvas-container {
  width: 100%;
  height: 100%;
}
</style>
