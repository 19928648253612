// 导入组件
import LogIn from "./components/logIn.js";
import SignUp from "./components/signUp.js";
import Forgot from "./components/forgot.js";
import EditUserInfo from "./components/userInfo.js";
import Withdraw from "./components/withdraw.js";
import LogInCn from "./components/logInCn.js";
import ThirdLogin from "./components/thirdLogin.js";


// 组件列表
const componentList = {
  LogIn,
  SignUp,
  Forgot,
  EditUserInfo,
  Withdraw,
  LogInCn,
  ThirdLogin,
};

// 导出函数，用于注册组件并且在 app 上绑定全局属性
export default function registerComponents(app) {
  Object.keys(componentList).forEach(key => {
    const componentName = `${key}`;
    const component = componentList[key];
    // 注册组件
    app.component(componentName, component);
    
    // 绑定全局属性
    app.config.globalProperties[componentName] = component;
  });
}

// 导出组件列表，如果需要在其他地方访问组件列表可以使用
export const funComponentList = componentList;