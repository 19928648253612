import { defineStore } from "pinia";
import useFeedListStore from "@/store/create/feedListDataStore.js";
import message from "@/components/functionCallComponent/message/message.js";
import useShopCartStore from "@/store/shopCartStore.js";
import useStoreDetailData from "@/store/storeDetail/storeDetailDataStore.js";
import useMineStore from "@/store/mineDataStore.js";
import globalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import globalConfig from "@/config/config.js";
import tools from "@/utils/tools.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import feedListApi from "@/api/api/feedListApi.js";
import router from "@/router/index.js";

const useGlobalPlayDataStore = defineStore({
  id: "globalPlayDataStore",
  state: () => {
    return {
      playlist: [],
      curPlayData: null,
      tempToggleStatus: false,
      curPlayGroup: "",
      isPlaying: false,

      dataKeyMap: {
        feedlist: {
          title: "title",
          create_time: "create_on",
          duration_milliseconds: "duration",
          like_stat: "like_status",
          song_url: "",
          song_id: "id",
        },
        store: {},
      },
    };
  },
  actions: {
    getDataByFields(data, dataExpression) {
      // get数据内部对应路径数据
      let resData = data;
      let find = false;
      if (String(dataExpression)) {
        const dataFieldArr = String(dataExpression).split(".");
        find = dataFieldArr.every((item) => {
          try {
            resData = resData[item];
            return true;
          } catch (error) {
            return false;
          }
        });
      } else {
        return resData;
      }
      if (find) {
        return resData;
      }
      return undefined;
    },
    setDataByFields(data, dataExpression, value) {
      // set数据内部对应路径数据
      let find = false;
      let setData = data;
      if (String(dataExpression)) {
        const dataFieldArr = String(dataExpression).split(".");
        find = dataFieldArr.every((item) => {
          setData = resData[item];
          if (item === dataFieldArr[dataFieldArr.length - 1]) {
            resData[item] = value;
          }
        });
      }
      return find;
    },
    setDataKeyMap(playGroup, dataKeyMap) {
      this.dataKeyMap[playGroup] = dataKeyMap;
    },
    setIsPlaying(isPlaying) {
      this.isPlaying = isPlaying;
    },
    setCurPlayGroup(playGroup) {
      this.curPlayGroup = playGroup;
    },

    updataLayoutData() {
      const createLayoutStore = useCreateLayoutStore();
      createLayoutStore.updataCreateEditOpenState(
        this.curPlayData ? true : false
      );
    },
    setCurPlayData(curPlayData = null) {
      this.curPlayData = curPlayData;
      this.updataLayoutData();
    },
    setPlayDatas(datas, curPlayItem) {
      this.playlist = datas;
      if (curPlayItem) {
        this.curPlayData = curPlayItem;
        this.updataLayoutData();
      }
    },

    addPlayDatas(datas) {
      this.playlist = [...this.playlist, ...datas];
    },

    updatePlayData(data) {
      if (this.curPlayData?.id === data.id) {
        this.curPlayData = data;
        this.updataLayoutData();
      } else {
        let index = this.playlist.findIndex((item) => item.id === data.id);
        if (index > -1) {
          this.playlist[index] = data;
        }
      }
    },
    getPlayerKey (url) {
      if (!url) return;
      const index = url.lastIndexOf("?");
      if (index > -1) {
        return getObjectStorageFullUrl(url.substring(0, index));
      }
      return getObjectStorageFullUrl(url);
    },
    switchSong(direction) {
      // globalAudioPlayer.pause(
      //   getObjectStorageFullUrl(this.curPlayData?.mp3_url)
      // );
      if (direction == "prev") {
        let index = this.playlist.findIndex(
          (item) => item.song_id == this.curPlayData?.song_id
        );
        if (index > 0) {
          this.curPlayData = this.playlist[index - 1];
        } else {
          this.curPlayData = this.playlist[this.playlist.length - 1];
        }
      } else if (direction === "next") {
        let index = this.playlist.findIndex(
          (item) => item.song_id === this.curPlayData?.song_id
        );
        if (index < this.playlist.length - 1) {
          this.curPlayData = this.playlist[index + 1];
        } else {
          this.curPlayData = this.playlist[0];
        }
        this.updataLayoutData();
      }
      setTimeout(() => {
        globalAudioPlayer.play(
          this.getPlayerKey(this.curPlayData?.mp3_url)
        );
        this.isPlaying = true;
      }, 20);
    },

    tempToggle(status) {
      // true 暂停 false 释放
      this.tempToggleStatus = status;
    },

    updatePlayDataItem(data) {
      if (!this.curPlayData) return;
      if (this.curPlayData?.song_id == data.song_id) {
        this.curPlayData = Object.assign({}, this.curPlayData, data);
      }
      console.log("this.playlist", this.playlist);
      this.playlist?.some((item) => {
        if (item?.song_id == data.song_id) {
          Object.assign(item, data);
        }
      });
      this.updataLayoutData();
    },
    updataOtherFromData(data) {
      switch (this.curPlayGroup) {
        case "feedlist":
          const feedlistStore = useFeedListStore();
          feedlistStore.updataSongDataItem(data);
          break;
        case "":
          // @TODO:choumingzhu
          break;
        default:
          break;
      }
    },
    doLike(smItem) {
      const shopCartStore = useShopCartStore();
      const storeDetailDataStore = useStoreDetailData();
      const mineStore = useMineStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .favoriteSong({
            song_id: smItem.song_id,
            state:
              smItem.is_liked ?? smItem.favorite_state
                ? (smItem.is_liked ?? smItem.favorite_state) == 1
                  ? 2
                  : 1
                : this.renderFavorite_state(smItem?.favorite_state),
          })
          .then((res) => {
            if (res.code == 200) {
              Object.assign(smItem, {
                favorite_state:
                  (smItem.is_liked ?? smItem.favorite_state) == 1 ? 2 : 1,
                is_liked:
                  (smItem.is_liked ?? smItem.favorite_state) == 1 ? 2 : 1,
              });
              this.updatePlayDataItem(smItem);
              this.updataOtherFromData(smItem);
              shopCartStore.updateShopLike(
                smItem.song_id,
                smItem.favorite_state
              );
              storeDetailDataStore.updateDetail(smItem);
              console.log("smItem", smItem);
              mineStore.deleteShopList(smItem);
              resolve();
            } else {
              // message.error({ content: "Save failed" });
              reject("Save failed");
            }
          })
          .catch((err) => {
            // message.error({ content: "Save failed" });
            reject("Save failed");
          });
      });
    },
    renderFavorite_state(favorite_state) {
      if (favorite_state == 1) {
        return 2;
      } else {
        return 1;
      }
    },
    doShare(smItem) {
      return new Promise((resolve, reject) => {
        tools
          .copyToClipboard(
            `${globalConfig.baseOrigin}/store-detail?share_key=${smItem.share_key}&is_from_share=1`
          )
          .then((res) => {
            // message.success({
            //   content: "Song link copied",
            //   position: "top",
            // });
            resolve();
          })
          .catch(() => {
            reject("Copy failed");
          });

        feedListApi.shareSongReport({
          song_id: smItem.song_id,
          channel: "link",
        });
        // .then((res) => {
        //   if (res.code == 200) {
        //       tools
        //         .copyToClipboard(
        //           `${globalConfig.baseOrigin}/store-detail?share_key=${res.data.share_key}&is_from_share=1`
        //         )
        //         .then((res) => {
        //           // message.success({
        //           //   // maskClosable: true,
        //           //   content: "Song link copied",
        //           //   // position: "top",
        //           // });
        //           resolve();
        //         })
        //         .catch((err) => {
        //           reject();
        //         });
        //     }
        // })
        // .catch((err) => {
        //   // message.error({ content: "Save failed" });
        //   reject();
        // });
      });
    },

    doAddToShoppingCart(smItem) {
      return new Promise((resolve) => {
        const shopCartStore = useShopCartStore();
        const storeDetailDataStore = useStoreDetailData();
        const mineStore = useMineStore();
        shopCartStore.doAddCart(smItem).then((res) => {
          if (res) {
            resolve(res);
            const { code } = res;
            if (code == 200) {
              // 改变全局播放器加购状态
              this.updatePlayDataItem({
                ...smItem,
                cart_state: 1,
              });
              // 改变商城列表的状态
              shopCartStore.updateShopCart(smItem.song_id, 1);

              // 改变详情状态
              storeDetailDataStore.updateDetail(smItem);
              // 改变喜欢列表的状态
              mineStore.updateShopCart(smItem.song_id, 1);
            } else if (res.code == 6305) {
              shopCartStore.deleteShopList(smItem.id);
              updatePlayDataItem({
                ...smItem,
                sold_state: 4,
              });
            } else if (res.code == 6306) {
              shopCartStore.deleteShopList(smItem.id);
              updatePlayDataItem({
                ...smItem,
                sold_state: 4,
              });
            }
          }
        });
      });
    },

    doRegenerate(smItem) {
      const feedListDataStore = useFeedListStore();

      feedListDataStore.reGenerateSmItem(smItem);
    },
  },
});

export default useGlobalPlayDataStore;

// const globalPlayDataStore = useGlobalPlayDataStore();
