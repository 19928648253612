/**
 * 将十六进制颜色转换为RGB
 * @param {string} hex - 十六进制颜色代码
 * @returns {Array} - RGB数组 [r, g, b]
 */
const hexToRgb = (hex) => {
  // 移除 '#' 符号
  hex = hex.replace(/^#/, "");

  // 处理短格式颜色代码 '#03F' to '#0033FF'
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((x) => x + x)
      .join("");
  }

  const bigint = parseInt(hex, 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

/**
 * 将RGB颜色转换为十六进制颜色
 * @param {Array} rgb - RGB数组 [r, g, b]
 * @returns {string} - 十六进制颜色代码
 */
const rgbToHex = (rgb) => {
  return (
    "#" +
    rgb
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

/**
 * 获取渐变开始到结束中间的n个过渡颜色
 * @param {string} startColor - 起始颜色（十六进制）
 * @param {string} endColor - 结束颜色（十六进制）
 * @param {number} steps - 过渡颜色的数量
 * @returns {Array} - 包含所有过渡颜色的数组
 */
export const getGradientColors = (startColor, endColor, steps, isRgb) => {
  const startRgb = isRgb ? startColor : hexToRgb(startColor);
  const endRgb = isRgb ? endColor : hexToRgb(endColor);

  const stepFactor = 1 / (steps + 1);
  const interpolate = (start, end, factor) => start + (end - start) * factor;

  const gradientColors = [];
  for (let i = 1; i <= steps; i++) {
    const factor = stepFactor * i;
    const rgb = startRgb.map((start, index) =>
      Math.round(interpolate(start, endRgb[index], factor))
    );
    if (isRgb) {
      gradientColors.push(rgb);
    } else {
      gradientColors.push(rgbToHex(rgb));
    }
  }

  return gradientColors;
};

export default {
  hexToRgb,
  rgbToHex,
  getGradientColors,
};
